import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';
import { webCampaignApi } from '../../../../api/webCampaign';
import {
  removeSelectedValue,
  setSelectedValue,
} from '../../../../store/reducers/campaign';
import { debounce } from '../../../../utils/debounce';
import { placeholderStyle } from '../../../AddSegment/Condition/Condition.constants';
import {
  FormatGroupLabel,
  MultiValueLabel,
  MultiValueRemove,
} from '../../../AddSegment/components/labels';
import ConditionWrapper from '../../Components/ConditionWrapper';
import ConditionRow from '../../Components/ConditionWrapper/ConditionRow';
import MultiSelect from '../../Components/Conditions/MultiSelect';
import Select from '../../Components/Conditions/Select';
import WalletNameLabel from '../../Components/WalletNameLabel';
import { conditionsConfig } from '../../options';
import { useConditionValues } from '../../useConditionValues';
import styles from './WalletDetected.module.scss';

const WalletDetected = () => {
  const id = 'wallet';
  const dispatch = useDispatch();
  const conditionData = conditionsConfig.find(
    (condition) => condition.id === id
  );

  const [displayTypeName, setDisplayTypeName] = useState(false);
  const [displayChains, setDisplayChains] = useState(false);
  const [displayNames, setDisplayNames] = useState(false);

  const {
    wallet: walletValue,
    ofType: ofTypeValue,
    chains: chainsValue,
    withName: withNameValue,
    names: namesValue,
  } = useConditionValues(id);

  useEffect(() => {
    const display = walletValue?.value === 'must_have';
    setDisplayTypeName(display);

    if (!display) {
      dispatch(
        setSelectedValue({
          conditionId: id,
          label: 'of_type_any_of_all_of',
          value: {
            value: 'of_any_type',
            label: 'of any type',
          },
        })
      );
      dispatch(removeSelectedValue({ conditionId: id, label: 'chains' }));
      dispatch(
        setSelectedValue({
          conditionId: id,
          label: 'with_name_any_of_all_of',
          value: {
            value: 'of_any_name',
            label: 'of any name',
          },
        })
      );
      dispatch(removeSelectedValue({ conditionId: id, label: 'names' }));
    }
  }, [walletValue]);

  useEffect(() => {
    const display = [
      'of_type_any_of',
      'of_type_all_of',
      'of_type_none_of',
    ].includes(ofTypeValue?.value);
    setDisplayChains(display);

    if (!display) {
      dispatch(removeSelectedValue({ conditionId: id, label: 'chains' }));
    }
  }, [ofTypeValue]);

  useEffect(() => {
    const display = [
      'with_name_any_of',
      'with_name_all_of',
      'with_name_none_of',
    ].includes(withNameValue?.value);
    setDisplayNames(display);

    if (!display) {
      dispatch(removeSelectedValue({ conditionId: id, label: 'names' }));
    }
  }, [withNameValue]);

  const getData = useCallback(
    async (val) => {
      const tempData = await dispatch(
        webCampaignApi.endpoints.getWalletNames.initiate(val)
      );

      if (tempData.status === 'fulfilled') {
        return tempData.data.map((item) => ({
          value: item.name,
          label: item.name,
          network: item.platform,
          wallet: item.name,
        }));
      }
      return null;
    },
    [dispatch]
  );

  const loadOptions = useCallback(
    debounce((val) => getData(val), 1000),
    [getData]
  );
  const getLabel = useCallback((val) => <WalletNameLabel val={val} />, []);

  const handleNamesChange = (newValue) => {
    dispatch(
      setSelectedValue({ conditionId: id, label: 'names', value: newValue })
    );
  };

  return (
    <ConditionWrapper id={id} label={conditionData.label}>
      <ConditionRow>
        <Select
          conditionId={id}
          label="wallet"
          value={getValue(id, 'wallet')}
          data={[
            {
              value: 'must_have',
              label: 'Visitor must have a wallet',
            },
            {
              value: 'must_not_have',
              label: 'Visitor must not have a wallet',
            },
          ]}
        />
      </ConditionRow>
      {displayTypeName && (
        <>
          <ConditionRow>
            <Select
              conditionId={id}
              label="of_type_any_of_all_of"
              value={ofTypeValue}
              data={[
                {
                  value: 'of_any_type',
                  label: 'of any type',
                },
                {
                  value: 'of_type_any_of',
                  label: 'of type any of',
                },
                {
                  value: 'of_type_all_of',
                  label: 'of type all of',
                },
                {
                  value: 'of_type_none_of',
                  label: 'of type none of',
                },
              ]}
            />
            {displayChains && (
              <MultiSelect
                conditionId={id}
                label="chains"
                value={chainsValue}
                components={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueRemove: (props) => (
                    <MultiValueRemove props={props} show="label" />
                  ),
                  // eslint-disable-next-line react/no-unstable-nested-components
                  MultiValueLabel: (props) => (
                    <MultiValueLabel props={props} show="label" />
                  ),
                }}
                getOptionLabel={(val) => getLabel(val)}
                data={[
                  {
                    value: 'ethereum',
                    label: 'Ethereum',
                    network: 'ethereum',
                  },
                  {
                    value: 'solana',
                    label: 'Solana',
                    network: 'solana',
                  },
                  {
                    value: 'bitcoin',
                    label: 'Bitcoin',
                    network: 'bitcoin',
                  },
                  {
                    value: 'cosmos',
                    label: 'Cosmos',
                    network: 'cosmos',
                  },
                  {
                    value: 'tezos',
                    label: 'Tezos',
                    network: 'tezos',
                  },
                  {
                    value: 'near',
                    label: 'Near',
                    network: 'near',
                  },
                ]}
              />
            )}
          </ConditionRow>
          <ConditionRow>
            <Select
              conditionId={id}
              label="with_name_any_of_all_of"
              value={withNameValue}
              data={[
                {
                  value: 'of_any_name',
                  label: 'of any name',
                },
                {
                  value: 'with_name_any_of',
                  label: 'with name any of',
                },
                {
                  value: 'with_name_all_of',
                  label: 'with name all of',
                },
                {
                  value: 'with_name_none_of',
                  label: 'with name none of',
                },
              ]}
            />
            {displayNames && (
              <div className={styles.names_wrapper}>
                <AsyncSelect
                  styles={placeholderStyle}
                  maxMenuHeight={194}
                  components={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    MultiValueRemove: (props) => (
                      <MultiValueRemove props={props} show="label" />
                    ),
                    // eslint-disable-next-line react/no-unstable-nested-components
                    MultiValueLabel: (props) => (
                      <MultiValueLabel
                        props={props}
                        show="label"
                        isWalletName
                      />
                    ),
                  }}
                  className="w-100"
                  formatGroupLabel={FormatGroupLabel}
                  value={namesValue}
                  loadOptions={loadOptions}
                  getOptionLabel={(val) => getLabel(val)}
                  isSearchable
                  cacheOptions={false}
                  onChange={handleNamesChange}
                  defaultOptions
                  isShowValue
                  isMulti
                />
              </div>
            )}
          </ConditionRow>
        </>
      )}
    </ConditionWrapper>
  );
};

export default WalletDetected;
