/* eslint-disable import/no-unresolved */
import { useAppKitNetwork } from '@reown/appkit/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { parseUnits } from 'viem';
import { useAccount, usePublicClient, useWalletClient } from 'wagmi';
import Equals from '../../../../../../../assets/icons/flows/equals_modal.svg';
import Plus from '../../../../../../../assets/icons/flows/plus_modal.svg';
import { blockChainOptionsWithTestnets } from '../../../../../../../components/base/ChainLogo/chains';
import Modal from '../../../../../../../components/base/Modal';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../../../../../components/base/Notifications';
import RoundSpinner from '../../../../../../../components/base/RoundSpinner';
import { getUser } from '../../../../../../../store/reducers/user';
import {
  clearPendingTxn,
  fetchPendingTxns,
  getContract as getAirDropContract,
  getBalance,
  getERC20Address,
  getERC20Contract,
  getPendingTransactions,
  isPendingTxn,
  setBalance,
  setContract,
  setERC20Contract,
} from '../../../../../../../store/reducers/web3';
import { onPaste, validateInput } from '../../../../../../../utils/segments';
import { getGasPrice } from '../../../../../../../utils/web3/getGasPrice';
import { loadERC20Contract } from '../../../../../../../utils/web3/loadContract';
import { metamaskErrorWrap } from '../../../../../../../utils/web3/metamaskErrorWrap';
import { getNetworkById } from '../../../../../../../utils/web3/networks';
import { truncateAddress } from '../../../../../../../utils/web3/truncateAddress';
import styles from './ProvideTokensModal.module.scss';

const ProvideTokensModal = ({
  setShowProvideTokenModel,
  setTeamBalanceOnSettings,
  selectedItem,
  openWeb3Modal,
  teamBalance,
  getTeamBalance,
  contractChainId,
}) => {
  const dispatch = useDispatch();
  const { address, chain } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient({ chainId: chain?.id });
  const { switchNetwork } = useAppKitNetwork();
  const balance = useSelector(getBalance);
  const airdropContract = useSelector(getAirDropContract);
  const ERC20Address = useSelector(getERC20Address);
  const ERC20Contract = useSelector(getERC20Contract);
  const user = useSelector(getUser);
  const pendingTxns = useSelector(getPendingTransactions);
  const [inputValue, setInputValue] = useState('');
  const [unsupportedNetwork, setUnsupportedNetwork] = useState(false);
  const [customTokenSymbol, setCustomTokenSymbol] = useState('');
  const [fundingAllowance, setFundingAllowance] = useState(0);
  const [isWrongNetwork, setIsWrongNetwork] = useState(null);

  const approveText = 'Approve Funding ERC20';
  const approveType = 'approve_funding_erc20';
  const fundText = 'Fund ERC20 Contract';
  const fundType = 'fund_erc20_contract';

  const onCancel = () => {
    setShowProvideTokenModel(false);
  };

  const getTokenContract = useCallback(async () => {
    if (walletClient && chain) {
      let erc20Contract;
      try {
        setUnsupportedNetwork(false);
        erc20Contract = await loadERC20Contract(walletClient, ERC20Address);
        dispatch(setERC20Contract(erc20Contract));

        if (!selectedItem.symbol) {
          const symbol = await publicClient.readContract({
            address: erc20Contract.address,
            abi: erc20Contract.abi,
            functionName: 'symbol',
          });
          setCustomTokenSymbol(symbol);
        }

        if (airdropContract) {
          const erc20Balance = await publicClient.readContract({
            address: erc20Contract.address,
            abi: erc20Contract.abi,
            functionName: 'balanceOf',
            args: [address],
          });

          const allowance = await publicClient.readContract({
            address: erc20Contract.address,
            abi: erc20Contract.abi,
            functionName: 'allowance',
            args: [address, airdropContract.address],
          });

          const decimals = await publicClient.readContract({
            address: erc20Contract.address,
            abi: erc20Contract.abi,
            functionName: 'decimals',
          });

          setFundingAllowance(Number(allowance) / 10 ** Number(decimals));
          dispatch(
            setBalance(
              (Number(erc20Balance) / 10 ** Number(decimals)).toFixed(4)
            )
          );
        }
      } catch (err) {
        if (!erc20Contract) {
          setUnsupportedNetwork(true);
        }
      }
    }
  }, [
    walletClient,
    chain,
    ERC20Address,
    dispatch,
    selectedItem.symbol,
    airdropContract,
    publicClient,
    address,
  ]);

  const initModal = useCallback(async () => {
    const _airdropContract = await loadERC20Contract(
      walletClient,
      null,
      chain?.id
    );
    dispatch(setContract(_airdropContract));
  }, [selectedItem.blockchain, chain?.id, walletClient, dispatch]);

  const changeApproval = async () => {
    let approveTx;
    const decimals = await publicClient.readContract({
      address: ERC20Contract.address,
      abi: ERC20Contract.abi,
      functionName: 'decimals',
    });
    try {
      const value = parseUnits(inputValue, decimals);
      const gasPrice = await getGasPrice(publicClient);

      approveTx = await walletClient.writeContract({
        address: ERC20Contract.address,
        abi: ERC20Contract.abi,
        functionName: 'approve',
        args: [airdropContract.address, value],
        gasPrice,
      });

      dispatch(
        fetchPendingTxns({
          txnHash: approveTx.hash,
          approveText,
          type: approveType,
        })
      );
      const receipt = await publicClient.waitForTransactionReceipt({
        hash: approveTx,
      });

      if (!receipt || receipt.status !== 'success') {
        throw new Error('Transaction failed');
      }
    } catch (err) {
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }

      const allowance = await publicClient.readContract({
        address: ERC20Contract.address,
        abi: ERC20Contract.abi,
        functionName: 'allowance',
        args: [address, airdropContract.address],
      });
      setFundingAllowance(Number(allowance) / 10 ** Number(decimals));
    }
  };

  const fundAirdropContract = async () => {
    let fundTx;
    const decimals = await publicClient.readContract({
      address: ERC20Contract.address,
      abi: ERC20Contract.abi,
      functionName: 'decimals',
    });

    try {
      const gasPrice = await getGasPrice(publicClient);
      const value = parseUnits(inputValue, decimals);

      fundTx = await walletClient.writeContract({
        address: airdropContract.address,
        abi: airdropContract.abi,
        functionName: 'fundERC20',
        args: [String(user.team), ERC20Contract.address, value],
        gasPrice,
      });

      dispatch(
        fetchPendingTxns({ txnHash: fundTx.hash, fundText, type: fundType })
      );

      const receipt = await publicClient.waitForTransactionReceipt({
        hash: fundTx,
      });

      if (!receipt || receipt.status !== 'success') {
        throw new Error('Transaction failed');
      }
      showSuccessMessage('Token balance transfer succeeded');
    } catch (err) {
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (fundTx) {
        dispatch(clearPendingTxn(fundTx.hash));
      }
      const erc20Balance = await publicClient.readContract({
        address: ERC20Contract.address,
        abi: ERC20Contract.abi,
        functionName: 'balanceOf',
        args: [address],
      });
      dispatch(
        setBalance((Number(erc20Balance) / 10 ** Number(decimals)).toFixed(4))
      );
      await getTeamBalance();
      setShowProvideTokenModel(false);
    }
  };

  useEffect(() => {
    if (walletClient) {
      initModal();
    }
  }, [initModal, walletClient, chain]);

  useEffect(() => {
    if (walletClient && airdropContract) {
      getTeamBalance();
      getTokenContract();
    }
  }, [airdropContract, getTokenContract, getTeamBalance, walletClient]);

  useEffect(() => {
    setTeamBalanceOnSettings(teamBalance);
  }, [teamBalance, setTeamBalanceOnSettings]);

  useEffect(() => {
    const _isWrongNetwork =
      chain && contractChainId ? chain.id !== contractChainId : null;
    if (_isWrongNetwork) {
      setInputValue('');
    }
    setIsWrongNetwork(_isWrongNetwork);
  }, [chain, contractChainId]);

  const switchToRightNetwork = () => {
    const targetNetwork = getNetworkById(contractChainId);
    if (targetNetwork) {
      switchNetwork(targetNetwork);
    }
  };

  return (
    <Modal onCancel={onCancel}>
      <div
        className={`${styles.content} modal-content border-0 position-relative`}
      >
        <div className={styles.title}>Provide Tokens for airdrop</div>
        <div className={styles.row}>
          <div className={styles.block_title}>
            Remaining tokens available for airdrop:
          </div>
          <div className="d-flex mt-1 align-items-center gap-2">
            {typeof teamBalance === 'number' && chain && (
              <span className={` ${styles.main_price}`}>
                {teamBalance}{' '}
                {selectedItem?.symbol
                  ? selectedItem?.symbol?.toUpperCase()
                  : customTokenSymbol}
              </span>
            )}
            {!chain && (
              <span className={`${styles.main_price} text-center`}>
                Connect your wallet
              </span>
            )}
          </div>
        </div>
        <div className={`${styles.row_button} d-flex justify-content-center`}>
          <Plus />
        </div>
        <div className={styles.row}>
          <div className={styles.block_title}>
            Enter amount to top up the airdrop balance
          </div>
          <div className="d-flex mt-2 mb-1 align-items-center gap-2">
            <input
              value={inputValue}
              type="number"
              min="0"
              step=".00000001"
              disabled={unsupportedNetwork || isWrongNetwork}
              onPaste={onPaste}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => {
                const ifValidValue = validateInput(e.target.value, false, true);
                if (!ifValidValue) {
                  return;
                }
                setInputValue(e.target.value);
              }}
              className={styles.balance_input}
            />
          </div>
          {!isWrongNetwork &&
            balance !== null &&
            address &&
            chain &&
            (Number(balance) > Number(inputValue) ? (
              <div className={styles.balance}>
                Your balance: {balance}{' '}
                {selectedItem?.symbol
                  ? selectedItem?.symbol?.toUpperCase()
                  : customTokenSymbol}
              </div>
            ) : (
              <div className={styles.insufficient_balance}>
                Insufficient{' '}
                {selectedItem?.symbol
                  ? selectedItem?.symbol?.toUpperCase()
                  : customTokenSymbol}{' '}
                balance
              </div>
            ))}
        </div>
        <div className={`${styles.row_button} d-flex justify-content-center`}>
          <Equals />
        </div>
        <div className={styles.row}>
          <div className={styles.block_title}>
            Total balance available to airdrop after transfer:
          </div>
          <div className="d-flex mt-1 align-items-center gap-2">
            {typeof teamBalance === 'number' ? (
              <span className={styles.main_price}>
                {Number(inputValue) + teamBalance}{' '}
                {selectedItem?.symbol
                  ? selectedItem?.symbol?.toUpperCase()
                  : customTokenSymbol}
              </span>
            ) : (
              <span className={`${styles.main_price} text-center`}>
                {unsupportedNetwork ? '' : 'Connect your wallet'}
              </span>
            )}
          </div>
        </div>

        <div
          className={`${styles.footer} mt-4 d-flex flex-column justify-content-center align-items-center gap-2`}
        >
          {isWrongNetwork ? (
            <button
              type="button"
              className="regular-button w-40 mx-auto"
              onClick={async () => {
                switchToRightNetwork();
              }}
            >
              Switch Network
            </button>
          ) : address ? (
            fundingAllowance > 0 && fundingAllowance >= inputValue ? (
              <button
                type="button"
                disabled={
                  address &&
                  (unsupportedNetwork ||
                    !Number(inputValue) ||
                    Number(balance) < Number(inputValue) ||
                    Number(balance) === 0 ||
                    isPendingTxn(pendingTxns, fundType))
                }
                className="regular-button w-40 mx-auto"
                onClick={async () => {
                  fundAirdropContract();
                }}
              >
                {isPendingTxn(pendingTxns, fundType) ? (
                  <div>
                    <RoundSpinner position="position-absolute" theme="light" />
                    Funding...
                  </div>
                ) : (
                  'Provide Token'
                )}
              </button>
            ) : (
              <button
                type="button"
                disabled={
                  address &&
                  (unsupportedNetwork ||
                    Number(balance) < Number(inputValue) ||
                    Number(inputValue) === 0 ||
                    isPendingTxn(pendingTxns, approveType))
                }
                className="regular-button w-40 mx-auto"
                onClick={async () => {
                  changeApproval();
                }}
              >
                {isPendingTxn(pendingTxns, approveType) ? (
                  <div>
                    <RoundSpinner position="position-absolute" theme="light" />
                    Approving...
                  </div>
                ) : (
                  'Approve'
                )}
              </button>
            )
          ) : (
            <button
              type="button"
              className="regular-button w-40 mx-auto"
              onClick={async () => {
                openWeb3Modal();
              }}
            >
              Connect Wallet
            </button>
          )}
          {address && (
            <div className={styles.wallet}>
              Connected wallet:{' '}
              <div
                role="presentation"
                className={styles.wallet_address}
                onClick={() => openWeb3Modal()}
              >
                {truncateAddress(address)}
              </div>{' '}
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ProvideTokensModal;
