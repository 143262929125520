import {
  intValues,
  setValues,
  filterValues,
  datePickerValues,
  nftCoinValues,
} from './constants';
import { cexSelectFieldChain, cexInputChain } from './fieldRendering/cexFields';
import {
  isUsesDappWithIsThanCombo,
  dappSelectFieldChain,
} from './fieldRendering/dappFields';

export const createDefaultSelectStatements = (data, index, option) => {
  let cexChain = true;
  let dappChain = true;

  const containsChainOption =
    data.options.some((opt) => {
      const isUsesDappOrCEX = ['Uses Dapp', 'Uses CEX'].includes(data.name);
      const shouldCheckCombo = isUsesDappOrCEX && opt.name === 'is-than-combo';
      const shouldCheckOthers =
        !isUsesDappOrCEX && ['is-than', 'is-than-combo'].includes(opt.name);

      return (
        (shouldCheckCombo || shouldCheckOthers) &&
        intValues.includes(
          Array.isArray(opt?.value) ? opt?.value[0]?.value : null
        )
      );
    }) || data.options[index]?.name !== 'ago';

  const containsFilterValues = !filterValues.includes(
    Array.isArray(data?.options[index - 2]?.value)
      ? data?.options[index - 2]?.value[0]?.value
      : null
  );

  if (data.name === 'Uses CEX') {
    cexChain = cexSelectFieldChain(data, option);
  }
  if (data.name === 'Uses Dapp') {
    dappChain = dappSelectFieldChain(data, option);
  }

  if (containsChainOption && containsFilterValues && cexChain && dappChain) {
    return true;
  }
  return false;
};

export const createNumberInputStatements = (data, index) => {
  let cexInput = true;
  let dappInputComboChain = false;

  const containsSetValues = data.options.some((opt) =>
    setValues.includes(Array.isArray(opt?.value) ? opt?.value[0]?.value : null)
  );

  const containsDateValues =
    !['date', 'timestamp'].includes(data.type) ||
    !data.options.some((opt) =>
      datePickerValues.includes(
        Array.isArray(opt?.value) ? opt?.value[0]?.value : false
      )
    );

  const containstCoinNftValues =
    !filterValues.includes(
      Array.isArray(data.options[index - 1]?.value)
        ? data.options[index - 1]?.value[0].value
        : false
    ) || !nftCoinValues.includes(data.name);

  if (data.name === 'Uses CEX') {
    cexInput = cexInputChain(data, index);
  }
  if (data.name === 'Uses Dapp') {
    dappInputComboChain = isUsesDappWithIsThanCombo(data);
  }

  if (
    !containsSetValues &&
    cexInput &&
    !dappInputComboChain &&
    containsDateValues &&
    containstCoinNftValues
  ) {
    return true;
  }
  return false;
};
