export const legendData = [
  {
    color: 'blue',
  },
  {
    color: 'orange',
  },
  {
    color: 'green',
  },
  {
    color: 'purple',
  },
  {
    color: 'red',
  },
  {
    color: 'grey',
    title: 'Others',
  },
];

export const walletOptions = [
  { value: 'any_wallet_status', label: 'Any wallet status' },
  { value: 'no_wallet_detected', label: 'No wallet detected' },
  { value: 'wallet_detected', label: 'Wallet detected' },
  { value: 'wallet_connected', label: 'Wallet connected' },
];
const regionNamesInEnglish = new Intl.DisplayNames(['en'], { type: 'region' });
const languageNamesInEnglish = new Intl.DisplayNames(['en'], {
  type: 'language',
});
const countryList = [
  'AE',
  'AM',
  'AR',
  'AT',
  'AU',
  'BA',
  'BD',
  'BE',
  'BG',
  'BR',
  'BY',
  'CA',
  'CH',
  'CI',
  'CM',
  'CN',
  'CO',
  'CR',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EG',
  'ES',
  'ET',
  'FI',
  'FR',
  'GA',
  'GB',
  'GE',
  'GR',
  'HK',
  'HR',
  'ID',
  'IE',
  'IL',
  'IN',
  'IS',
  'IT',
  'JP',
  'KR',
  'KZ',
  'LB',
  'LK',
  'LT',
  'LU',
  'MA',
  'MC',
  'MD',
  'MK',
  'MO',
  'MT',
  'MX',
  'MY',
  'NG',
  'NI',
  'NL',
  'NO',
  'NP',
  'PA',
  'PE',
  'PH',
  'PK',
  'PL',
  'PR',
  'PS',
  'PT',
  'PY',
  'RO',
  'RS',
  'RU',
  'SA',
  'SE',
  'SG',
  'SI',
  'TH',
  'TR',
  'TW',
  'UA',
  'US',
  'UZ',
  'VN',
  'ZA',
];

const countryToLanguageMap = {
  AE: 'ar',
  AM: 'hy',
  AR: 'es',
  AT: 'de',
  AU: 'en',
  BA: 'bs',
  BD: 'bn',
  BE: 'nl',
  BG: 'bg',
  BR: 'pt',
  BY: 'be',
  CA: 'en',
  CH: 'de',
  CI: 'fr',
  CM: 'fr',
  CN: 'zh',
  CO: 'es',
  CR: 'es',
  CY: 'el',
  CZ: 'cs',
  DE: 'de',
  DK: 'da',
  EG: 'ar',
  ES: 'es',
  ET: 'am',
  FI: 'fi',
  FR: 'fr',
  GA: 'fr',
  GB: 'en',
  GE: 'ka',
  GR: 'el',
  HK: 'zh',
  HR: 'hr',
  ID: 'id',
  IE: 'en',
  IL: 'he',
  IN: 'hi',
  IS: 'is',
  IT: 'it',
  JP: 'ja',
  KR: 'ko',
  KZ: 'kk',
  LB: 'ar',
  LK: 'si',
  LT: 'lt',
  LU: 'lb',
  MA: 'ar',
  MC: 'fr',
  MD: 'ro',
  MK: 'mk',
  MO: 'zh',
  MT: 'mt',
  MX: 'es',
  MY: 'ms',
  NG: 'en',
  NI: 'es',
  NL: 'nl',
  NO: 'no',
  NP: 'ne',
  PA: 'es',
  PE: 'es',
  PH: 'tl',
  PK: 'ur',
  PL: 'pl',
  PR: 'es',
  PS: 'ar',
  PT: 'pt',
  PY: 'es',
  RO: 'ro',
  RS: 'sr',
  RU: 'ru',
  SA: 'ar',
  SE: 'sv',
  SG: 'en',
  SI: 'sl',
  TH: 'th',
  TR: 'tr',
  TW: 'zh',
  UA: 'uk',
  US: 'en',
  UZ: 'uz',
  VN: 'vi',
  ZA: 'af',
};

const uniqueLanguageCodes = [...new Set(Object.values(countryToLanguageMap))];

export const countriesOptions = countryList
  .map((elem) => ({
    value: elem,
    label: regionNamesInEnglish.of(elem),
  }))
  .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

export const languagesOptions = uniqueLanguageCodes
  .map((elem) => ({
    value: elem,
    label: languageNamesInEnglish.of(elem),
  }))
  .sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));

export const loggedInOptions = [
  { value: '', label: 'Any state' },
  { value: 'logged_in', label: 'Logged in' },
  { value: 'not_logged_in', label: 'Not logged in' },
];

export const metricOptions = [
  { value: 'referrer-grouped', label: 'Referrer (Grouped)' },
  { value: 'referrer-all', label: 'Referrer (All)' },
  { value: 'campaign', label: 'Campaign' },
  { value: 'source', label: 'Source' },
  { value: 'medium', label: 'Medium' },
  { value: 'term', label: 'Term' },
  { value: 'content', label: 'Content' },
];

export const conversionEventOptions = [
  { value: 'purchase', label: 'Purchase' },
  { value: 'connect-wallet', label: 'Wallet Connection' },
];

export const conversionWindowOptions = [
  { value: 'anytime', label: 'Anytime' },
  { value: 'same-session', label: 'Same Session' },
];
