import { dropdownOptions, excludeOptionNames } from '../constants';

export const isDappValid = (data, option, typeKey) =>
  data?.name === 'Uses Dapp' &&
  Array.isArray(data?.options[2]?.value) &&
  dropdownOptions[typeKey]?.some(
    (type) => data?.options[2]?.value[0]?.value === type
  ) &&
  excludeOptionNames[typeKey]?.includes(option.name);

export const isUsesDappWithIsThanCombo = (data) => {
  if (data.name === 'Uses Dapp') {
    const isThanComboOption = data.options.find(
      (opt) => opt.name === 'is-than-combo'
    );
    const transactionTypeOption = data.options[2];

    if (isThanComboOption && transactionTypeOption) {
      const isThanComboValue = Array.isArray(isThanComboOption.value)
        ? isThanComboOption.value[0]?.value
        : null;
      const transactionTypeValue = Array.isArray(transactionTypeOption.value)
        ? transactionTypeOption.value[0]?.value
        : null;

      return (
        ['and-first-transaction', 'and-last-transaction'].includes(
          transactionTypeValue
        ) &&
        ['is-before', 'is-after'].includes(isThanComboValue) &&
        Array.isArray(data.options[5]?.value) &&
        (data.options[5].value[0].value === 'is-before' ||
          data.options[5].value[0].value === 'is-after')
      );
    }
  }
  return false;
};

export const dappSelectFieldChain = (data, option) => {
  const dappTotalTransactionChain = isDappValid(
    data,
    option,
    'dappTotalTransaction'
  );
  const dappTransactionValuesChain = isDappValid(
    data,
    option,
    'dappTransactionValues'
  );
  const dappFirstLastTransactionChain = isDappValid(
    data,
    option,
    'dappFirstLastTransaction'
  );

  if (
    !dappTotalTransactionChain &&
    !dappTransactionValuesChain &&
    !dappFirstLastTransactionChain
  ) {
    return true;
  }
  return false;
};
