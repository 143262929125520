import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/utils';

export const utilsApi = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData?.body?.id_token) {
        headers.set('Authorization', `Bearer ${authData.body.id_token}`);
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Utils'],
  endpoints: (build) => ({
    jobCancellationById: build.mutation({
      query: (requestIdObj) => ({
        url: `${BASE_API}/job-cancellation/`,
        method: 'POST',
        body: requestIdObj,
      }),
      providesTags: () => ['Utils'],
    }),
  }),
});
