/* eslint-disable import/no-unresolved */
import {
  base,
  baseSepolia,
  chiliz,
  linea,
  lineaSepolia,
  mainnet,
  polygon,
  polygonAmoy,
  polygonMumbai,
  sepolia,
  spicy,
} from '@reown/appkit/networks';

export const supportedNetworks = [
  {
    id: 8453,
    networkName: 'base',
    network: base,
  },
  {
    id: 84532,
    networkName: 'base-sepolia',
    network: baseSepolia,
  },
  {
    id: 88888,
    networkName: 'chiliz',
    network: chiliz,
  },
  {
    id: 59144,
    networkName: 'linea',
    network: linea,
  },
  {
    id: 59141,
    networkName: 'linea-sepolia',
    network: lineaSepolia,
  },
  {
    id: 1,
    networkName: 'homestead',
    network: mainnet,
  },
  {
    id: 137,
    networkName: 'polygon',
    network: polygon,
  },
  {
    id: 80002,
    networkName: 'amoy',
    network: polygonAmoy,
  },
  {
    id: 80001,
    networkName: 'mumbai',
    network: polygonMumbai,
  },
  {
    id: 11155111,
    networkName: 'eth-sepolia',
    network: sepolia,
  },
  {
    id: 88882,
    networkName: 'chiliz-spicy',
    network: spicy,
  },
];

export const getAllNetworks = () =>
  supportedNetworks.map((item) => item.network);

export const getNetworkById = (id) => {
  const found = supportedNetworks.find((item) => item.id === id);
  return found ? found.network : null;
};

export const getNetworkByName = (name) => {
  const found = supportedNetworks.find((item) => item.networkName === name);
  return found ? found.network : null;
};
