import Calendar from '../../assets/icons/calendar.svg?url';
import Language from '../../assets/icons/language.svg?url';
import Wallet from '../../assets/icons/wallet.svg?url';
import Scroll from '../../assets/icons/scroll.svg?url';
import Country from '../../assets/icons/country.svg?url';
import Time from '../../assets/icons/time.svg?url';
import URL from '../../assets/icons/url.svg?url';
import URLCrossed from '../../assets/icons/url_crossed.svg?url';
import Segments from '../../assets/icons/segment_black.svg?url';
import Hashtag from '../../assets/icons/hashtag.svg?url';
import { countriesOptions, languagesOptions } from '../../utils/webSDK';

export const conditionsConfig = [
  {
    id: 'dates',
    label: 'Dates',
    icon: Calendar,
    inputs: [
      { type: 'dateTime', label: 'startDate', displayedLabel: 'Start date' },
      { type: 'dateTime', label: 'endDate', displayedLabel: 'End date' },
    ],
  },
  {
    id: 'language',
    label: 'Language',
    icon: Language,
    inputs: [
      {
        type: 'dropdown',
        label: 'requirements',
        data: [
          {
            value: 'required',
            label: 'Required languages',
          },
          {
            value: 'excluded',
            label: 'Excluded languages',
          },
        ],
      },
      { type: 'multiSelect', label: 'languages', data: languagesOptions },
    ],
  },
  {
    id: 'wallet',
    label: 'Wallet detected',
    icon: Wallet,
    inputs: [
      {
        type: 'dropdown',
        label: 'wallet',
        data: [
          {
            value: 'must_have',
            label: 'Visitor must have a wallet',
          },
          {
            value: 'must_not_have',
            label: 'Visitor must not have a wallet',
          },
        ],
      },
      {
        type: 'dropdown',
        label: 'of_type_any_of_all_of',
        data: [
          {
            value: 'of_any_type',
            label: 'of any type',
          },
          {
            value: 'of_type_any_of',
            label: 'of type any of',
          },
          {
            value: 'of_type_all_of',
            label: 'of type all of',
          },
          {
            value: 'of_type_none_of',
            label: 'of type none of',
          },
        ],
        preLabel: 'wallet',
        preValue: 'must_have',
      },
      {
        type: 'multiSelect',
        label: 'chains',
        placeholder: 'Select type',
        data: [
          {
            value: 'ethereum',
            label: 'Ethereum',
          },
          {
            value: 'solana',
            label: 'Solana',
          },
          {
            value: 'bitcoin',
            label: 'Bitcoin',
          },
          {
            value: 'cosmos',
            label: 'Cosmos',
          },
          {
            value: 'tezos',
            label: 'Tezos',
          },
          {
            value: 'near',
            label: 'Near',
          },
        ],
        preLabel: 'of_type_any_of_all_of',
        preValue: ['of_type_any_of', 'of_type_all_of', 'of_type_none_of'],
      },
      {
        type: 'dropdown',
        label: 'with_name_any_of_all_of',
        data: [
          {
            value: 'of_any_name',
            label: 'of any name',
          },
          {
            value: 'with_name_any_of',
            label: 'with name any of',
          },
          {
            value: 'with_name_all_of',
            label: 'with name all of',
          },
          {
            value: 'with_name_none_of',
            label: 'with name none of',
          },
        ],
        preLabel: 'wallet',
        preValue: 'must_have',
      },
      {
        type: 'asyncMultiSelect',
        label: 'names',
        preLabel: 'with_name_any_of_all_of',
        preValue: ['with_name_any_of', 'with_name_all_of', 'with_name_none_of'],
      },
    ],
  },
  {
    id: 'wallet_connected',
    label: 'Wallet connected',
    icon: Wallet,
    inputs: [
      {
        type: 'dropdown',
        label: 'wallet',
        data: [
          {
            value: 'must_have',
            label: 'Visitor must have connected a wallet',
          },
          {
            value: 'must_not_have',
            label: 'Visitor must not have connected a wallet',
          },
        ],
      },
    ],
  },
  {
    id: 'scroll',
    label: 'Scroll level',
    icon: Scroll,
    inputs: [
      {
        type: 'textWithValue',
        label: 'level',
        textBefore: 'User must have scrolled at least',
        textAfter: '% of the page',
        max: 100,
      },
    ],
  },
  {
    id: 'country',
    label: 'Country',
    icon: Country,
    inputs: [
      {
        type: 'dropdown',
        label: 'requirements',
        data: [
          {
            value: 'required',
            label: 'Required countries',
          },
          {
            value: 'excluded',
            label: 'Excluded countries',
          },
        ],
      },
      { type: 'multiSelect', label: 'countries', data: countriesOptions },
    ],
  },
  {
    id: 'time',
    label: 'Time spent',
    icon: Time,
    inputs: [
      {
        type: 'textWithValue',
        label: 'timeSpent',
        textBefore: 'User must have spent at least',
        textAfter: 'seconds on the page',
      },
    ],
  },
  {
    id: 'urlReq',
    label: 'URL requirements',
    icon: URL,
    inputs: [
      {
        type: 'dropdownAndInputList',
        label: 'urlRequirements',
        fields: [
          {
            type: 'dropdown',
            label: 'requirements',
            data: [
              {
                value: 'contains',
                label: 'URL contains',
              },
              {
                value: 'starts',
                label: 'URL starts with',
              },
              {
                value: 'ends',
                label: 'URL ends with',
              },
            ],
          },
          {
            type: 'text',
            label: 'url',
            placeholder: 'Enter URL',
          },
        ],
      },
    ],
  },
  {
    id: 'urlExc',
    label: 'URL Exclusions',
    icon: URLCrossed,
    inputs: [
      {
        type: 'dropdownAndInputList',
        label: 'urlRequirements',
        fields: [
          {
            type: 'dropdown',
            label: 'requirements',
            data: [
              {
                value: 'contains',
                label: 'URL contains',
              },
              {
                value: 'starts',
                label: 'URL starts with',
              },
              {
                value: 'ends',
                label: 'URL ends with',
              },
            ],
          },
          {
            type: 'text',
            label: 'url',
            placeholder: 'Enter URL',
          },
        ],
      },
    ],
  },
  {
    id: 'segments',
    label: 'Segments',
    icon: Segments,
    inputs: [
      {
        type: 'labeledAsyncMultiSelect',
        dataType: 'segments',
        label: 'inSegments',
        exclusionLabel: 'notInSegments',
        displayedLabel: 'Visitor must be in segments',
      },
      {
        type: 'labeledAsyncMultiSelect',
        dataType: 'segments',
        label: 'notInSegments',
        exclusionLabel: 'inSegments',
        displayedLabel: 'Visitor must not be in segments',
      },
    ],
  },
  {
    id: 'utm',
    label: 'UTM Tags',
    icon: Hashtag,
    inputs: [
      {
        type: 'dropdown',
        label: 'currentTags',
        data: [
          {
            value: 'last',
            label: 'Last UTM tags',
          },
          {
            value: 'current',
            label: 'Current UTM tags',
          },
        ],
      },
      {
        type: 'tags',
        label: 'tags',
        fields: [
          {
            type: 'dropdown',
            label: 'utms',
            data: [
              {
                value: 'campaign',
                label: 'Campaign',
              },
              {
                value: 'source',
                label: 'Source',
              },
              {
                value: 'medium',
                label: 'Medium',
              },
              {
                value: 'term',
                label: 'Term',
              },
              {
                value: 'content',
                label: 'Content',
              },
            ],
          },
          {
            type: 'multiInput',
            label: 'tag',
            placeholder: 'Enter tag(s)',
          },
        ],
      },
    ],
  },
];

export const getDefaultForCondition = (id, label) => {
  if (id === 'dates' && label === 'startDate') {
    const date = new Date();
    date.setSeconds(0, 0);
    return date;
  }
  if (id === 'dates' && label === 'endDate') {
    const date = new Date();
    date.setSeconds(0, 0);
    date.setMonth(date.getMonth() + 1);
    return date;
  }
  if (id === 'language' && label === 'requirements') {
    return { value: 'required', label: 'Required languages' };
  }
  if (id === 'language' && label === 'languages') {
    return [];
  }
  if (id === 'country' && label === 'requirements') {
    return { value: 'required', label: 'Required countries' };
  }
  if (id === 'country' && label === 'countries') {
    return [];
  }
  if (id === 'wallet' && label === 'wallet') {
    return { value: 'must_have', label: 'Visitor must have a wallet' };
  }
  if (id === 'wallet' && label === 'of_type_any_of_all_of') {
    return { value: 'of_any_type', label: 'of any type' };
  }
  if (id === 'wallet' && label === 'chains') {
    return [];
  }
  if (id === 'wallet' && label === 'with_name_any_of_all_of') {
    return { value: 'of_any_name', label: 'of any name' };
  }
  if (id === 'wallet' && label === 'name') {
    return [];
  }
  if (id === 'wallet_connected' && label === 'wallet') {
    return {
      value: 'must_have',
      label: 'Visitor must have connected a wallet',
    };
  }
  if (id === 'scroll' && label === 'level') {
    return 30;
  }
  if (id === 'time' && label === 'timeSpent') {
    return 10;
  }
  if (id === 'urlReq' && label === 'urlRequirements') {
    return [
      {
        id: Date.now(),
        condition: {
          value: 'contains',
          label: 'URL contains',
        },
        text: '',
      },
    ];
  }
  if (id === 'urlExc' && label === 'urlRequirements') {
    return [
      {
        id: Date.now(),
        condition: {
          value: 'contains',
          label: 'URL contains',
        },
        text: '',
      },
    ];
  }
  if (id === 'segments' && label === 'inSegments') {
    return [];
  }
  if (id === 'segments' && label === 'notInSegments') {
    return [];
  }
  if (id === 'utm' && label === 'currentTags') {
    return { value: 'last', label: 'Last UTM tags' };
  }
  if (id === 'utm' && label === 'tags') {
    return [
      {
        id: Date.now(),
        utm: null,
        tags: [],
      },
    ];
  }
};
