import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/folders';

export const foldersApi = createApi({
  reducerPath: 'foldersAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData?.body?.id_token) {
        headers.set('Authorization', `Bearer ${authData.body.id_token}`);
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Folders'],
  endpoints: (build) => ({
    getFolders: build.query({
      query: ({ type }) => ({
        url: `${BASE_API}/?folder_type=${type}`,
        method: 'GET',
      }),
      transformResponse: (response) => {
        const sortedData = response.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        sortedData.forEach((data) => {
          if (data.sub_folders && data.sub_folders.length > 0) {
            const sortedSubFolders = data.sub_folders.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
            data.sub_folders = sortedSubFolders;
          }
        });
        return sortedData;
      },
      providesTags: () => ['Folders'],
    }),
    createFolder: build.mutation({
      query: ({ name, type }) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: { name, folder_type: type },
      }),
      providesTags: () => ['Folders'],
    }),
    changeFolder: build.mutation({
      query: ({ id, name, type }) => ({
        url: `${BASE_API}/${id}/`,
        method: 'PATCH',
        body: { name, folder_type: type },
      }),
      providesTags: () => ['Folders'],
    }),
    deleteFolder: build.mutation({
      query: ({ id, type }) => ({
        url: `${BASE_API}/${id}/?folder_type=${type}`,
        method: 'DELETE',
      }),
      providesTags: () => ['Folders'],
    }),
    createSubfolder: build.mutation({
      query: ({ name, parentId, type }) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: { name, parent_folder_id: parentId, folder_type: type },
      }),
      providesTags: () => ['Folders'],
    }),
    addItemsToFolders: build.mutation({
      query: ({ items, folders, type }) => ({
        url: `${BASE_API}/add-items/`,
        method: 'POST',
        body: { items, folders, folder_type: type },
      }),
      providesTags: () => ['Folders'],
    }),
    removeItemsFromFolder: build.mutation({
      query: ({ items, folders, type }) => ({
        url: `${BASE_API}/delete-items/`,
        method: 'DELETE',
        body: { items, folders, folder_type: type },
      }),
      providesTags: () => ['Folders'],
    }),
    manageFolders: build.mutation({
      query: ({ id, type, folders, folderType }) => ({
        url: `${BASE_API}/manage-folders/`,
        method: 'POST',
        body: {
          object_id: id,
          object_type: type,
          folders,
          folder_type: folderType,
        },
      }),
      providesTags: () => ['Folders'],
    }),
  }),
});
