export const convertToReadableFormat = (number) => {
  if (!number && number !== 0) return '-';
  const numberLength = String(number.toFixed(0)).length;
  if (numberLength > 12) return `${(number / 1000000000000).toFixed(1)}T`;
  if (numberLength > 9) return `${(number / 1000000000).toFixed(1)}B`;
  if (numberLength > 6) return `${(number / 1000000).toFixed(1)}M`;
  if (numberLength > 3) return `${(number / 1000).toFixed(1)}K`;
  if (numberLength > 1) return number.toFixed(0);
  return +number.toFixed(3);
};

export const convertNumber = (number) => {
  if (number === null || number === undefined) return '-';

  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  return parts.join('.');
};

export const convertSciTruncSmallCommaBig = (number) => {
  // 1e-8 -> 0.0000...01, 1000000 -> 1,000,000
  if (!number && number !== 0) return '0';
  const num = Number(number);
  const decimalString =
    num < 0.00001 ? num.toFixed(18).replace(/\.?0+$/, '') : String(num);
  const [int, decimal] = decimalString.split('.');
  const intWithCommas = int.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  const value =
    decimal && num < 0.00001
      ? `${intWithCommas}.${decimal.slice(0, 4)}...${decimal.slice(-2)}`
      : decimal
        ? `${intWithCommas}.${decimal}`
        : intWithCommas;
  return value;
};

export const convertSciToDec = (val) => {
  let result;
  if (val < 0.000001) {
    const num = Number(val);
    if (Number.isInteger(num)) return num.toString();
    const sigFigs = Math.max(0, Math.ceil(Math.log10(1 / Math.abs(num))));
    result = num.toFixed(sigFigs);
  } else result = val;
  return result;
};

export const removeExcessZeros = (number, toFixed = 11) => {
  if (!number) return '';
  return number.toFixed(toFixed).replace(/\.?0+$/, '');
};

export const converterData = (string) => {
  const data = Date.now() - Date.parse(string);
  if (data / 31557600000 > 0) {
    return `${Math.floor(data / 31557600000)} years${
      (data % 31557600000) / 2678400000 > 0
        ? `,
    ${Math.floor((data % 31557600000) / 2678400000)} months`
        : ''
    }`;
  }
  return `${Math.floor((data % 31557600000) / 2678400000)} months`;
};

export const dividerCommaFormat = (number) => {
  if (number === null || number === undefined) return '';
  if (number >= 1000000) {
    return `${Math.floor(number / 1000000)},${
      Math.floor((number % 1000000) / 1000) < 100
        ? `0${
            Math.floor((number % 1000000) / 1000) < 10
              ? `0${Math.floor((number % 1000000) / 1000)}`
              : Math.floor((number % 1000000) / 1000)
          }`
        : Math.floor((number % 1000000) / 1000)
    },${
      number % 1000 < 100
        ? `0${number % 1000 < 10 ? `0${number % 1000}` : number % 1000}`
        : number % 1000
    }`;
  }
  if (number >= 1000) {
    return `${Math.floor(number / 1000)},${
      number % 1000 < 100
        ? `0${number % 1000 < 10 ? `0${number % 1000}` : number % 1000}`
        : number % 1000
    }`;
  }
  return number;
};

export const convertToComma = (input) =>
  typeof input === 'string' ? input.replace('.', ',') : input;
