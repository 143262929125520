import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/contracts';

export const contractsApi = createApi({
  reducerPath: 'contractsAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData?.body?.id_token) {
        headers.set('Authorization', `Bearer ${authData.body.id_token}`);
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Contracts'],
  endpoints: (build) => ({
    getContracts: build.query({
      query: () => ({
        url: `${BASE_API}/`,
        method: 'GET',
      }),
    }),
    createNFT: build.mutation({
      query: (nftData) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: nftData,
      }),
    }),
    deleteNFT: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
    }),
    sendAirdrop: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/mint`,
        method: 'POST',
        body,
      }),
    }),
    postNFTMedia: build.mutation({
      query: (media) => ({
        url: `${BASE_API}/contract-media/`,
        method: 'POST',
        body: media,
      }),
    }),
  }),
});
