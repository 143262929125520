import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCustomList: {},
};

export const csvUploadSlice = createSlice({
  name: 'csvUpload',
  initialState,
  reducers: {
    setSelectedCustomList: (state, action) => {
      state.selectedCustomList = action.payload;
    },
    clearCSVUpload: () => initialState,
  },
});

export const { setSelectedCustomList, clearCSVUpload } = csvUploadSlice.actions;

export const selectSelectedCustomList = (state) =>
  state.csvUploadReducer.selectedCustomList;

export default csvUploadSlice.reducer;
