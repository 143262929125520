import { Button } from '@absolutelabs/react-component-library';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Edit from '../../../../../assets/icons/forms/edit_raw.svg';
import Tooltip from '../../../../../components/ui/Tooltip';
import {
  selectActiveSettings,
  selectButtonColor,
  selectButtonFont,
  selectButtonMinWidth,
  selectButtonRadius,
  selectButtonTextColor,
  selectButtonWeight,
  selectGlobalFont,
  selectSubmitAlign,
  selectSubmitFullWidth,
  selectSubmitLabel,
  selectSubmitStyle,
  setActiveSettings,
} from '../../../../../store/reducers/forms';
import styles from './SubmitButton.module.scss';

const SubmitButton = () => {
  const dispatch = useDispatch();
  const activeSettings = useSelector(selectActiveSettings);
  const submitLabel = useSelector(selectSubmitLabel);
  const submitStyle = useSelector(selectSubmitStyle);
  const submitAlign = useSelector(selectSubmitAlign);
  const submitFullWidth = useSelector(selectSubmitFullWidth);
  const globalFont = useSelector(selectGlobalFont);
  const buttonFont = useSelector(selectButtonFont);
  const buttonColor = useSelector(selectButtonColor);
  const buttonTextColor = useSelector(selectButtonTextColor);
  const buttonRadius = useSelector(selectButtonRadius);
  const buttonMinWidth = useSelector(selectButtonMinWidth);
  const buttonWeight = useSelector(selectButtonWeight);
  const [isHovered, setIsHovered] = useState(false);

  const openSubmitSettings = () => {
    dispatch(setActiveSettings({ type: 'submit' }));
  };

  return (
    <div
      className={styles.wrapper}
      onClick={openSubmitSettings}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`
          ${styles.indicator_hovered_field} 
          ${
            activeSettings?.type === 'submit'
              ? styles.visible_with_transition
              : styles.hidden_with_transition
          }
        `}
      />
      <div
        className={`
        ${styles.button} 
        ${isHovered ? styles.visible : styles.hidden}
        `}
      >
        <div data-for="edit" data-tip>
          <div
            role="button"
            tabIndex={0}
            className={`${styles.edit}`}
            onClick={openSubmitSettings}
          >
            <Edit />
          </div>
        </div>
        <Tooltip id="edit" info="Edit submit button" />
      </div>
      <Button
        text={submitLabel}
        style={submitStyle}
        align={submitFullWidth ? 'center' : submitAlign}
        fullWidth={submitFullWidth}
        textColor={buttonTextColor}
        color={buttonColor}
        font={buttonFont || globalFont}
        radius={buttonRadius}
        minWidth={buttonMinWidth}
        fontWeight={buttonWeight}
      />
    </div>
  );
};

export default SubmitButton;
