import { isWalletAddress } from './supportedBlockchains';

export function shortenAddress(address, startLength = 6, endLength = 4) {
  if (
    (isWalletAddress(address) &&
      address.length > startLength + endLength + 3) ||
    address.length > 20
  ) {
    return `${address.substring(0, startLength)}...${address.substring(address.length - endLength)}`;
  }
  if (address.length > 50) {
    return `${address.substring(0, 50)}...`;
  }
  return address;
}
