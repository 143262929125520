export const coverPositions = [
  {
    value: 'center',
    label: 'Center',
  },
  {
    value: 'top',
    label: 'Top',
  },
  {
    value: 'right',
    label: 'Right',
  },
  {
    value: 'bottom',
    label: 'Bottom',
  },
  {
    value: 'left',
    label: 'Left',
  },
];

export const noCoverPositions = [
  {
    value: 'center',
    label: 'Center',
  },
  {
    value: 'top',
    label: 'Top',
  },
  {
    value: 'right',
    label: 'Right',
  },
  {
    value: 'bottom',
    label: 'Bottom',
  },
  {
    value: 'left',
    label: 'Left',
  },
  {
    value: 'top left',
    label: 'Top Left',
  },
  {
    value: 'top right',
    label: 'Top Right',
  },
  {
    value: 'bottom right',
    label: 'Bottom Right',
  },
  {
    value: 'bottom left',
    label: 'Bottom Left',
  },
];

export const getPosition = (pos) =>
  noCoverPositions.find((p) => p.value === pos);

export const uncommonPositions = [
  'top left',
  'top right',
  'bottom right',
  'bottom left',
];
