import {
  searchValues,
  setValues,
  intValues,
  containValues,
  datePickerValues,
  booleanValues,
  filterValues,
} from './constants';

export const proccessDeletion = (data) => {
  if (!data?.options) {
    return false;
  }

  const { options } = data;

  let shouldProcess = false;

  const hasSetValues = options.some((option) =>
    setValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  if (hasSetValues) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasBooleanValues = options.some((option) =>
    booleanValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasCexLabel = data.label === 'Uses CEX';
  if (hasBooleanValues && hasCexLabel) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasCexValues = options.some((option) =>
    Array.isArray(option.value)
      ? option.type === 'search-cex' && option.value[0]?.value
      : false
  );
  if (hasCexValues) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasDappValues = data.options.some((option) =>
    Array.isArray(option.value)
      ? option.type === 'search-dapp' && option.value[0]?.value
      : false
  );
  if (hasDappValues) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasIntValues = options.some((option) =>
    intValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );

  const allIntValues = [...options.slice(0, 1), ...options.slice(3, 5)].every(
    (option) => (Array.isArray(option.value) ? option.value[0]?.value : false)
  );

  if (hasIntValues && allIntValues && data.type) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasDateValues = options.some((option) =>
    datePickerValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );

  if (hasDateValues && ['date', 'timestamp'].includes(data.type)) {
    shouldProcess = true;
  }

  const hasContainValues = options.some((option) =>
    containValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasMultiInput = options.some((option) =>
    option.type === 'multi-input' &&
    option?.value?.length > 0 &&
    Array.isArray(option.value)
      ? option.value[0]?.value
      : false
  );
  const allValues = options
    .slice(0, 3)
    .every((option) =>
      Array.isArray(option.value) ? option.value[0]?.value : false
    );
  if (hasContainValues && hasMultiInput && allValues) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasSearchValues = options.some((option) =>
    searchValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasMultiInputInCustomSearch = options.some((option) =>
    option.type === 'custom-search' &&
    option?.value?.length > 0 &&
    Array.isArray(option.value)
      ? option.value[0]?.value
      : false
  );

  const allValuesSearch = [
    ...options.slice(0, 2),
    ...options.slice(3, 4),
  ].every((option) =>
    Array.isArray(option.value) ? option.value[0]?.value : false
  );

  if (hasSearchValues && hasMultiInputInCustomSearch && allValuesSearch) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasFilterValues = options.some((option) =>
    filterValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasValuesInOptions = options
    .slice(0, 3)
    .every((option) =>
      Array.isArray(option.value) ? option.value[0]?.value?.length > 0 : false
    );
  if (hasFilterValues && hasValuesInOptions) {
    shouldProcess = true;
    return shouldProcess;
  }

  const hasAllValues = options.every((option) =>
    Array.isArray(option.value) ? option.value[0]?.value : false
  );
  if (hasAllValues) {
    shouldProcess = true;
    return shouldProcess;
  }

  return shouldProcess;
};
