import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  prices: [],
  bitcoinPrices: [],
  dailyVolume: [],
};

export const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    historyPrices: (state, action) => {
      state.prices = action.payload;
    },
    removePrices: (state) => {
      state.prices = [];
    },
    bitcoinPrice: (state, action) => {
      state.bitcoinPrices = action.payload;
    },
    dailyVolume: (state, action) => {
      state.dailyVolume = action.payload;
    },
  },
});

export const { historyPrices, removePrices, bitcoinPrice, dailyVolume } =
  pricesSlice.actions;
export const getHistoryPrices = (state) => state.pricesReducer.prices?.prices;
export const getBitcoinPrices = (state) =>
  state.pricesReducer.bitcoinPrices?.prices;
export const getHistoryVolume = (state) =>
  state.pricesReducer.prices?.total_volumes;
export const getDailyVolume = (state) => state.pricesReducer.dailyVolume;

export default pricesSlice.reducer;
