export const getCoinData = (teamData, searchCoin) => {
  const dataCoinFromDashboard = teamData.tokens
    .slice()
    .sort((a, b) => (a.market_cap > b.market_cap ? -1 : 1))
    .map((coin) => ({
      value: coin.platform,
      label: coin.name,
      additionalData: coin.logo,
      market_cap: coin.market_cap,
      network: coin.network,
      type: 'coin',
      id: coin.coin_id,
    }));
  const dataCoinFromSearch = searchCoin
    .slice(0, searchCoin.length > 5 ? 5 : searchCoin.length)
    .map((coin) => ({
      value: coin.contract_address,
      label: coin.name,
      additionalData: coin.image_url,
      market_cap: coin.usd_market_cap,
      network: coin.blockchain,
      type: 'coin',
      id: coin.coin_id,
    }));

  return [dataCoinFromDashboard, dataCoinFromSearch];
};

export const getFormattedCoinData = (teamData, searchCoin) => {
  const formattedTeamDataCoins = [];
  const searchCoinOptions = [];
  if (teamData && teamData.length > 0) {
    const teamDataOptions = [];
    teamData
      .filter((coin) => coin.asset_type === 'coin' && coin.platforms !== null)
      .sort((a, b) => (a.market_cap > b.market_cap ? -1 : 1))
      .forEach((coin) => {
        // If there's more than one blockchain then add different coins, or maybe allow user to select
        teamDataOptions.push({
          label: coin.name,
          platform: null,
          platforms: coin.platforms,
          image_url: coin.logo,
          market_cap: coin.market_cap,
          symbol: coin.symbol || null,
          current_price: coin.current_price || 0,
        });
      });

    teamDataOptions.forEach((coin) => {
      if (coin.platforms) {
        if (coin.platforms.ethereum && coin.platforms.polygon) {
          formattedTeamDataCoins.push({
            ...coin,
            value: coin.platforms.ethereum,
            platform: coin.platforms.ethereum,
            blockchain: 'ethereum',
          });
          formattedTeamDataCoins.push({
            ...coin,
            value: coin.platforms.polygon,
            platform: coin.platforms.polygon,
            blockchain: 'polygon',
          });
        } else if (coin.platforms.ethereum) {
          formattedTeamDataCoins.push({
            ...coin,
            value: coin.platforms.ethereum,
            platform: coin.platforms.ethereum,
            blockchain: 'ethereum',
          });
        } else if (coin.platforms.polygon) {
          formattedTeamDataCoins.push({
            ...coin,
            value: coin.platforms.polygon,
            platform: coin.platforms.polygon,
            blockchain: 'polygon',
          });
        }
      } else {
        formattedTeamDataCoins.push({ ...coin });
      }
    });
  }
  if (searchCoin) {
    searchCoin.forEach((coin) => {
      searchCoinOptions.push({
        value: coin.contract_address,
        label: coin.name,
        platform: coin.blockchain,
        image_url: coin.image_url,
        blockchain: coin.blockchain,
        market_cap: coin.usd_market_cap,
        symbol: coin.symbol,
      });
    });
  }

  return [formattedTeamDataCoins, searchCoinOptions];
};

export const getNftData = (teamData, searchNFT, createdAssets = []) => {
  const nfts = teamData.nfts ? teamData.nfts : teamData;
  const dataNFTFromDashboard = nfts
    .slice()
    .sort((a, b) => (a.market_cap > b.market_cap ? -1 : 1))
    .map((nft) => ({
      value: nft.address || nft.contract_address,
      label:
        nft.opensea_slug_contract_count > 1
          ? nft.contract_name || nft.name
          : nft.name,
      additionalData: nft.logo || nft.image_url,
      image_url: nft.logo || nft.image_url,
      market_cap: nft.market_cap,
      holder_count: nft.holder_count,
      network: nft.network || nft.blockchain,
      blockchain: nft.network || nft.blockchain,
      type: 'nft',
      verified: nft.is_verified_on_opensea,
      testnet: !!nft?.testnet,
      created_assets: !!nft?.created_assets,
    }));

  if (createdAssets.length > 0) {
    const addedCreatedAssets = createdAssets
      .sort((a, b) => {
        const dateA = new Date(a.deployed_at);
        const dateB = new Date(b.deployed_at);
        return dateB - dateA;
      })
      .map((nft) => ({
        value: nft.address || nft.contract_address,
        label:
          nft.opensea_slug_contract_count > 1
            ? nft.contract_name || nft.name
            : nft.name,
        additionalData: nft.logo || nft.image_url,
        image_url: nft.logo || nft.image_url,
        market_cap: nft.market_cap,
        holder_count: nft.holder_count,
        network: nft.network || nft.blockchain,
        blockchain: nft.network || nft.blockchain,
        type: 'nft',
        verified: nft.is_verified_on_opensea,
        testnet: nft?.testnet,
        created_assets: !!nft?.created_assets,
      }));
    dataNFTFromDashboard.unshift(...addedCreatedAssets);
  }

  const dataNFTFromSearch = searchNFT
    .slice(0, searchNFT.length > 5 ? 5 : searchNFT.length)
    .map((nft) => ({
      value: nft.contract_address,
      label:
        nft.opensea_slug_contract_count > 1
          ? nft.contract_name || nft.name
          : nft.name,
      additionalData: nft.image_url,
      image_url: nft.image_url,
      market_cap: nft.market_cap,
      holder_count: nft.holder_count,
      network: nft.blockchain,
      blockchain: nft.blockchain,
      type: 'nft',
      verified: nft.is_verified_on_opensea,
      testnet: !!nft?.testnet,
      created_assets: !!nft?.created_assets,
    }));

  return [dataNFTFromDashboard, dataNFTFromSearch];
};

export const getDashboardData = (teamData, searchCoin, searchNFT, data) => {
  if (!teamData || !data) {
    return;
  }

  const returnData = { coin: null, nft: null };
  if (teamData && searchCoin && data?.label && data?.label.includes('token')) {
    returnData.coin = getCoinData(teamData, searchCoin);
  }
  if (teamData && searchNFT && data?.label && data?.label.includes('NFT')) {
    returnData.nft = getNftData(teamData, searchNFT);
  }

  return returnData;
};
