export const getPeriodInUnit = (ms) => {
  const minute = 60 * 1000;
  const hour = 60 * minute;
  const day = 24 * hour;

  if (ms % day === 0) {
    return { value: ms / day, unit: 'days' };
  }
  if (ms % hour === 0) {
    return { value: ms / hour, unit: 'hours' };
  }
  if (ms % minute === 0) {
    return { value: ms / minute, unit: 'minutes' };
  }
  return undefined;
};
