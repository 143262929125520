import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { profilesApi } from '../../../api/profiles';
import { isWalletAddress } from '../../../utils/supportedBlockchains';
import DappUsageTable from './DappUsageTable';

const DappUsage = ({ address }) => {
  const { name } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('number_of_transactions_last_30d');
  const [limit, setLimit] = useState(25);

  const {
    data: dappData,
    isLoading: isDappDataLoading,
    isFetching: isDappDataFetching,
  } = profilesApi.useGetWalletDappUsageDataQuery(
    {
      address: isWalletAddress(name)
        ? `?address=${name}`
        : `?alid=${name}&address=${address}`,
      limit,
      offset: (page - 1) * limit,
      order: desc ? 'desc' : 'asc',
      orderBy,
    },
    { skip: !isWalletAddress(name) && !address }
  );

  return (
    <DappUsageTable
      data={dappData}
      isLoading={isDappDataLoading || isDappDataFetching}
      setPage={setPage}
      setDesc={setDesc}
      setOrder={setOrder}
      setLimit={setLimit}
      page={page}
      limit={limit}
      desc={desc}
      orderBy={orderBy}
    />
  );
};

export default DappUsage;
