import { useEffect, useRef } from 'react';

const useKeydown = (key, func) => {
  const funcRef = useRef(func);

  useEffect(() => {
    funcRef.current = func;
  }, [func]);

  useEffect(() => {
    const downHandler = (event) => {
      if (event.key === key) {
        funcRef.current();
      }
    };

    window.addEventListener('keydown', downHandler);
    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [key]);
};

export default useKeydown;
