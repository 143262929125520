import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentFolder: {
    audiences: {
      id: 'all',
      name: 'All Audiences',
      sub_folders: [],
    },
    flows: {
      id: 'all',
      name: 'All Flows',
      sub_folders: [],
    },
    forms: {
      id: 'all',
      name: 'All Forms',
      sub_folders: [],
    },
  },
  selectedItems: {
    audiences: [],
    flows: [],
    forms: [],
  },
  selectedAudiences: [],
  selectedFlows: [],
  selectedForms: [],
  searchParams: {
    audiences: {},
    flows: {},
    forms: {},
  },
};

export const foldersSlice = createSlice({
  name: 'folders',
  initialState,
  reducers: {
    setCurrentFolder: (state, action) => {
      state.currentFolder = {
        ...state.currentFolder,
        [action.payload.type]: action.payload.value,
      };
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = {
        ...state.selectedItems,
        [action.payload.type]: action.payload.value,
      };
    },
    setSearchParams: (state, action) => {
      state.searchParams = {
        ...state.searchParams,
        [action.payload.type]: action.payload.value,
      };
    },
  },
});

export const { setCurrentFolder, setSelectedItems, setSearchParams } =
  foldersSlice.actions;

export const getCurrentFolder = (state, type) =>
  state.foldersReducer.currentFolder[type];
export const getSelectedItems = (state, type) =>
  state.foldersReducer.selectedItems[type];
export const hasSelectedItems = (state, type) =>
  state.foldersReducer.selectedItems[type]?.length || -1 > 0;
export const getSearchParams = (state, type) =>
  state.foldersReducer.searchParams[type];

export default foldersSlice.reducer;
