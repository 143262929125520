import { createSlice } from '@reduxjs/toolkit';
import {
  getAssetIdAfterFetch,
  getNetworkAfterFetch,
} from '../../../utils/assetsData/formatAssetData';

const initialState = {
  coins: [],
  selectCoin: [],
  assets: [],
  watchList: [],
  assetsFromDashboard: [],
  watchlistFromDashboard: [],
  tabPages: [],
  place: '',
  sales: null,
  filterOption: null,
};

export const coinSlice = createSlice({
  name: 'coin',
  initialState,
  reducers: {
    setFilterOption: (state, action) => {
      state.filterOption = action.payload;
    },
    setUserAssets(state, action) {
      state.assets = state.assets
        ? [
            ...action.payload.filter((elem) => elem.item_type === 'asset'),
            ...state.assets.filter((elem) => elem.website_name),
          ]
        : action.payload.filter((elem) => elem.item_type === 'asset');
      state.watchList = action.payload.filter(
        (elem) => elem.item_type === 'watchlist'
      );
    },
    setUserTrackedWebsite(state, action) {
      state.assets = state.assets.length
        ? [
            ...state.assets.filter((elem) => !elem.website_name),
            ...action.payload,
          ]
        : action.payload;
    },
    deleteTrackedWebsiteFromDashboard(state, action) {
      state.assets = state.assets.filter((elem) => elem.id !== action.payload);
    },
    changeColumn(state, action) {
      const id = getAssetIdAfterFetch(action.payload);
      const network = action.payload?.nft_collection?.network || null;
      if (action.payload.place_type === 'asset') {
        const excessElem = state.assets.filter(
          (elem) =>
            elem.coin_id === id ||
            (elem.address === id && elem.network === network) ||
            elem.id === id
        )[0];
        if (excessElem) {
          state.assets = state.assets.filter((elem) => elem.coin_id !== id);
          state.assets = state.assets.filter(
            (elem) =>
              (elem.address === id && elem.network !== network) ||
              elem.address !== id
          );
          state.assets = state.assets.filter((elem) => elem.id !== id);
          state.watchList.push(excessElem);
        }
      }
      if (action.payload.place_type === 'watchlist') {
        const excessElem = state.watchList.filter(
          (elem) =>
            elem.coin_id === id ||
            (elem.address === id && elem.network === network) ||
            elem.id === id
        )[0];
        if (excessElem) {
          state.watchList = state.watchList.filter(
            (elem) => elem.coin_id !== id
          );
          state.watchList = state.watchList.filter(
            (elem) =>
              (elem.address === id && elem.network !== network) ||
              elem.address !== id
          );
          state.watchList = state.watchList.filter((elem) => elem.id !== id);
          state.assets.push(excessElem);
        }
      }
    },
    deleteAsset: (state, action) => {
      const id = getAssetIdAfterFetch(action.payload);
      const network = getNetworkAfterFetch(action.payload);
      if (action.payload.place_type === 'asset') {
        state.assets = state.assets.filter(
          (elem) => elem?.coin?.coin_id !== id
        );
        state.assets = state.assets.filter(
          (elem) =>
            elem?.nft_collection?.address !== id ||
            (elem?.nft_collection?.address === id &&
              elem?.nft_collection?.network !== network)
        );
        state.assets = state.assets.filter((elem) => elem.coin_id !== id);
        state.assets = state.assets.filter(
          (elem) =>
            elem.address !== id ||
            (elem.address === id && elem.network !== network)
        );
        state.assets = state.assets.filter((elem) => elem.id !== id);
      } else {
        state.watchList = state.watchList.filter(
          (elem) => elem?.coin?.coin_id !== id
        );
        state.watchList = state.watchList.filter(
          (elem) =>
            elem?.nft_collection?.address !== id ||
            (elem?.nft_collection?.address === id &&
              elem?.nft_collection?.network !== network)
        );
        state.watchList = state.watchList.filter((elem) => elem.coin_id !== id);
        state.watchList = state.watchList.filter(
          (elem) =>
            elem.address !== id ||
            (elem.address === id && elem.network !== network)
        );
        state.watchList = state.watchList.filter((elem) => elem.id !== id);
      }
    },
    addAsset: (state, action) => {
      if (action.payload.place_type === 'asset') {
        state.assets.push(action.payload);
      } else {
        state.watchList.push(action.payload);
      }
    },

    assetsFromDashboard: (state, action) => {
      state.assetsFromDashboard = action.payload;
    },
    watchlistFromDashboard: (state, action) => {
      state.watchlistFromDashboard = action.payload;
    },
    removeAssetsFromDashboard: (state) => {
      state.assetsFromDashboard = [];
    },
    removeWatchlistFromDashboard: (state) => {
      state.watchlistFromDashboard = [];
    },
    addAssetToMyAssets: (state, action) => {
      state.assetsFromDashboard.push(action.payload);
    },
    addAssetToWatchlist: (state, action) => {
      state.watchlistFromDashboard.push(action.payload);
    },
    placeAsset: (state, action) => {
      state.place = action.payload;
    },
    salesData: (state, action) => {
      state.sales = action.payload;
    },
    setTabPages: (state, action) => {
      state.tabPages = action.payload;
    },
  },
});

export const {
  assetsFromDashboard,
  watchlistFromDashboard,
  removeAssetsFromDashboard,
  removeWatchlistFromDashboard,
  addAssetToMyAssets,
  addAssetToWatchlist,
  setUserAssets,
  setUserTrackedWebsite,
  changeColumn,
  deleteAsset,
  addAsset,
  placeAsset,
  salesData,
  setTabPages,
  deleteTrackedWebsiteFromDashboard,
  setFilterOption,
} = coinSlice.actions;

export const selectDashboardData = (state) => ({
  assets: state.coinReducer.assets,
  watchList: state.coinReducer.watchList,
});
export const getAssetsFromDashboard = (state) =>
  state.coinReducer.assetsFromDashboard.length
    ? state.coinReducer.assetsFromDashboard.map((asset) => asset.asset_id)
    : state.coinReducer.assetsFromDashboard;
export const getWatchlistFromDashboard = (state) =>
  state.coinReducer.watchlistFromDashboard.length
    ? state.coinReducer.watchlistFromDashboard.map((asset) => asset.asset_id)
    : state.coinReducer.watchlistFromDashboard;
export const getNftAssetsFromDashboard = (state) =>
  state.coinReducer.assetsFromDashboard.map((asset) => asset.address);
export const getNftWatchlistFromDashboard = (state) =>
  state.coinReducer.watchlistFromDashboard.map((asset) => asset.address);
export const selectFilterOption = (state) => state.coinReducer.filterOption;

export const selectTabPages = (state) => state.coinReducer.tabPages;

export default coinSlice.reducer;
