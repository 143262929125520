import { useAppKit } from '@reown/appkit/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { getContract } from 'viem';
import {
  useAccount,
  usePublicClient,
  useSwitchChain,
  useWalletClient,
} from 'wagmi';
import { blockchainApi } from '../../../../../../api/blockchain';
import { coinApi } from '../../../../../../api/coin';
import { userApi } from '../../../../../../api/user';
import Warning from '../../../../../../assets/icons/warning_rounded.svg';
import { blockChainOptionsWithTestnets } from '../../../../../../components/base/ChainLogo/chains';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import RoundSpinner from '../../../../../../components/base/RoundSpinner';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import Spinner from '../../../../../../components/base/Spinner';
import { useDebounce } from '../../../../../../components/hooks/app';
import { selectFlowStatus } from '../../../../../../store/reducers/flows';
import { getUser } from '../../../../../../store/reducers/user';
import {
  clearPendingTxn,
  fetchPendingTxns,
  getPendingTransactions,
  isPendingTxn,
} from '../../../../../../store/reducers/web3';
import { isValidContractAddress } from '../../../../../../tools/EtherTool';
import { dividerCommaFormat } from '../../../../../../tools/NumberConverterTool';
import { submitNftAirdrop } from '../../../../../../utils/airdrop/submitUtils';
import { debounce } from '../../../../../../utils/debounce';
import { getNftData } from '../../../../../../utils/segments/getCoinNftData';
import { excludedBlockchainsForFlows } from '../../../../../../utils/supportedBlockchains';
import { erc721Abi } from '../../../../../../utils/web3/erc721abi';
import { getGasPrice } from '../../../../../../utils/web3/getGasPrice';
import { loadNFTAirdropContract } from '../../../../../../utils/web3/loadContract';
import { metamaskErrorWrap } from '../../../../../../utils/web3/metamaskErrorWrap';
import { truncateAddress } from '../../../../../../utils/web3/truncateAddress';
import NFTCollectionCreation from '../../../../../NFTCreation/NFTCollectionCreation';
import McapLabel from '../../../Components/McapLabel';
import {
  ControlComponent,
  onChainInputStyles,
  sourceInputStyles,
} from '../SelectComponentStyling';
import styles from './NFTAirdrop.module.scss';

const approveForAllText = 'Approve for all tokens';
const approveForAllType = 'approve_for_all_tokens';

const NFTAirdrop = ({
  airdropSavedData,
  isDisabled,
  onSettingsChange,
  nodeID,
}) => {
  const dispatch = useDispatch();
  const savedNFTAirdropData =
    airdropSavedData?.asset_type === 'nft' ? airdropSavedData : null;
  const { open } = useAppKit();

  const [selectedItem, setSelectedItem] = useState(
    savedNFTAirdropData
      ? {
          value:
            savedNFTAirdropData.assetName || savedNFTAirdropData.nft_address,
          label:
            savedNFTAirdropData.asset_name ||
            `${savedNFTAirdropData.nft_address?.slice(0, 5)}...${savedNFTAirdropData.nft_address?.slice(-5)}`,
          image_url: savedNFTAirdropData.assetImg,
          id: savedNFTAirdropData.asset_id,
          blockchain: savedNFTAirdropData.nft_blockchain,
          testnet: savedNFTAirdropData.testnet || false,
          created_assets: savedNFTAirdropData.created_assets || false,
          verified: savedNFTAirdropData.verified || false,
        }
      : null
  );
  const [selectedBlockchain, setSelectedBlockchain] = useState(
    blockChainOptionsWithTestnets.find(
      (blockchain) => blockchain.value === savedNFTAirdropData?.nft_blockchain
    )?.network
  );
  const [amount, setAmount] = useState(savedNFTAirdropData?.nft_amount);
  const [sourceOfNfts, setSourceOfNfts] = useState(
    savedNFTAirdropData?.source_of_nfts
  );

  const { address, chain } = useAccount();
  const { switchChain } = useSwitchChain();
  const [debouncedText] = useDebounce('');
  const pendingTxns = useSelector(getPendingTransactions);
  const [selectedAddress, setSelectedAddress] = useState(
    savedNFTAirdropData?.nft_address
  );
  const [nftContract, setNftContract] = useState();
  const [airdropContract, setAirdropContract] = useState();
  const [userBalance, setUserBalance] = useState(0);
  const [isApproved, setIsApproved] = useState(false);
  const [nftOptions, setNftOptions] = useState([]);
  const [createdAssets, setCreatedAssets] = useState();
  const [isERC1155, setIsERC1155] = useState();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient({ chainId: chain?.id });
  const user = useSelector(getUser);
  const status = useSelector(selectFlowStatus);
  const blockchainValue = blockChainOptionsWithTestnets.find(
    (elem) => elem.network === selectedBlockchain
  )?.value;

  const openWeb3Modal = async () => {
    await open();
  };

  const {
    data: nfts,
    isLoading: isNftLoading,
    isFetching: isNftFetching,
  } = coinApi.useSearchNftTestnetsQuery(debouncedText, {
    skip: !debouncedText.length,
  });

  const {
    data: top5nfts,
    isLoading: isTop5NFTsLoading,
    isFetching: isTop5NFTsFetching,
  } = coinApi.useSearchNftQuery('', {
    skip: isDisabled,
  });

  const {
    data: teamAssets,
    isLoading: teamAssetsIsLoading,
    isFetching: teamAssetsIsFetching,
  } = userApi.useGetAssetsSearchQuery();

  const {
    data: nftsTestnets,
    isLoading: isNftTestnetsLoading,
    isFetching: isNftTestnetsFetching,
  } = coinApi.useSearchNftTestnetsQuery('', {
    skip: isDisabled,
  });

  const { data: isERC1155Response, isSuccess: isSuccessERC1155 } =
    blockchainApi.useCheckIsERC1155Query(
      {
        blockchain: blockchainValue,
        contractAddress: selectedAddress,
      },
      { skip: !blockchainValue || !selectedAddress }
    );

  const {
    data: userBalanceResponse,
    isLoading: isLoadingUserBalance,
    isSuccess: isSuccessUserBalance,
  } = blockchainApi.useGetNFTBalanceQuery(
    {
      blockchain: blockchainValue,
      walletAddress: address,
      contractAddress: selectedAddress,
    },
    {
      skip:
        !blockchainValue || !address || !selectedAddress || !isSuccessERC1155,
    }
  );

  useEffect(() => {
    if (
      isSuccessUserBalance &&
      isSuccessERC1155 &&
      isERC1155 !== undefined &&
      selectedItem &&
      amount &&
      +amount > 0 &&
      +amount <= userBalance
    ) {
      let newObj = {};
      newObj = submitNftAirdrop(
        {
          asset_name: selectedItem.label,
          created_assets: !!selectedItem.created_assets,
          is_erc1155: isERC1155,
          nft_address: selectedAddress,
          nft_amount: amount,
          nft_blockchain: selectedItem.blockchain,
          source_of_nfts: sourceOfNfts,
          testnet: !!selectedItem.testnet,
          user_address: address,
          verified: selectedItem.verified,
          assetType: 'nft',
        },
        selectedItem
      );
      onSettingsChange(newObj);
    } else {
      onSettingsChange(null);
    }
  }, [
    isSuccessUserBalance,
    isSuccessERC1155,
    isERC1155,
    selectedItem,
    amount,
    userBalance,
    sourceOfNfts,
    address,
    selectedAddress,
    onSettingsChange,
  ]);

  const fetchNftOptions = useCallback(() => {
    if (!isTop5NFTsFetching && !isNftFetching && !isNftTestnetsFetching) {
      const formattedNftData = getNftData(
        teamAssets !== undefined && teamAssets.length
          ? teamAssets.filter(
              (x) =>
                x.asset_type === 'nft' &&
                !excludedBlockchainsForFlows.includes(x.network)
            )
          : top5nfts !== undefined
            ? top5nfts.slice(0, 5)
            : [],
        nfts !== undefined ? nfts : [],
        createdAssets !== undefined ? createdAssets : []
      );
      setNftOptions(
        formattedNftData[0] ? formattedNftData[0] : formattedNftData[1]
      );
    }
  }, [
    teamAssets,
    nfts,
    top5nfts,
    isTop5NFTsFetching,
    isNftFetching,
    isNftTestnetsFetching,
    createdAssets,
  ]);

  useEffect(() => {
    const checkApproval = async () => {
      if (nftContract && address && airdropContract) {
        try {
          const approved = await publicClient.readContract({
            address: nftContract.address,
            abi: nftContract.abi,
            functionName: 'isApprovedForAll',
            args: [address, airdropContract.address],
          });

          setIsApproved(approved);
        } catch (error) {
          console.error('Error checking approval:', error);
        }
      }
    };

    checkApproval();
  }, [nftContract, airdropContract, address, publicClient]);

  useEffect(() => {
    const getAirdropContract = async () => {
      if (selectedAddress && selectedBlockchain && address) {
        try {
          const _airdropContract = loadNFTAirdropContract(
            walletClient || publicClient,
            isERC1155,
            chain?.id
          );
          setAirdropContract(_airdropContract);
        } catch (err) {
          showErrorMessage(
            "We couldn't load the airdrop contract. This blockchain might not be supported."
          );
        }
      }
    };

    getAirdropContract();
  }, [
    chain?.id,
    address,
    walletClient,
    publicClient,
    selectedAddress,
    selectedBlockchain,
    isERC1155,
  ]);

  const approveForAll = async (approve) => {
    let approveTx;
    try {
      const gasPrice = await getGasPrice(publicClient);

      approveTx = await walletClient.writeContract({
        address: nftContract.address,
        abi: nftContract.abi,
        functionName: 'setApprovalForAll',
        args: [airdropContract.address, approve],
        gasPrice,
      });

      dispatch(
        fetchPendingTxns({
          txnHash: approveTx.hash,
          approveForAllText,
          type: approveForAllType,
        })
      );

      const receipt = await publicClient.waitForTransactionReceipt({
        hash: approveTx,
      });

      if (!receipt || receipt.status !== 'success') {
        throw new Error('Transaction failed');
      }
    } catch (err) {
      return metamaskErrorWrap(err, showErrorMessage);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
      setIsApproved(approve);
    }
  };

  useEffect(() => {
    if (isERC1155Response) {
      setIsERC1155(isERC1155Response.is_erc1155);
    }
  }, [isERC1155Response]);

  useEffect(() => {
    if (nftsTestnets) {
      setCreatedAssets(nftsTestnets?.filter((x) => x.created_assets));
    }
  }, [nftsTestnets]);

  useEffect(() => {
    // Check if the user is connected and to the right network
    if (selectedBlockchain && chain) {
      const selectedChainId = blockChainOptionsWithTestnets.find(
        (elem) => elem.network === selectedBlockchain
      )?.networkId;
      if (selectedChainId !== chain.id) {
        switchChain(selectedChainId);
      }
    }
  }, [selectedBlockchain, chain, switchChain]);

  useEffect(() => {
    if (isSuccessUserBalance && userBalanceResponse !== null) {
      setUserBalance(userBalanceResponse.balance);
    }
  }, [userBalanceResponse, isSuccessUserBalance]);

  useEffect(() => {
    const nftAddress = selectedAddress;
    fetchNftOptions();
    // Set source of NFT when it is not created assets but the response from BE shows it is created assets
    if (createdAssets && nftAddress && sourceOfNfts !== 'created_assets') {
      const ifCreatedItem = createdAssets?.find(
        (obj) => obj.contract_address === nftAddress
      );
      if (ifCreatedItem) {
        setSourceOfNfts('created_assets');
      } else {
        setSourceOfNfts('users_wallet');
      }
    }
  }, [fetchNftOptions, isERC1155, createdAssets, selectedAddress]);

  useEffect(() => {
    const nftAddress = selectedAddress;
    // Load NFT contract
    if (
      walletClient &&
      nftAddress &&
      (selectedBlockchain || selectedItem?.blockchain)
    ) {
      // check if NFT contract is ERC721 or ERC1155
      const contract = getContract({
        address: nftAddress,
        abi: erc721Abi,
        client: walletClient,
      });
      setNftContract(contract);
    }
  }, [walletClient, selectedAddress, selectedBlockchain]);

  const getUserInputData = useCallback(
    async (val) => {
      if (isValidContractAddress(val)) {
        return [
          {
            value: val,
            label: val,
            head: true,
          },
        ];
      }
      const result = await dispatch(
        coinApi.endpoints.searchNftTestnets.initiate(val)
      );
      if (result.status === 'fulfilled') {
        const filteredData = result.data.filter(
          (item) => !excludedBlockchainsForFlows.includes(item.blockchain)
        );
        return filteredData.map((item) => ({
          ...item,
          value:
            item.opensea_slug_contract_count > 1
              ? item.contract_name || item.contract_address
              : item.contract_address,
          label:
            item.opensea_slug_contract_count > 1
              ? item.contract_name || item.name
              : item.name,
        }));
      }
      return null;
    },
    [dispatch]
  );

  const loadUserInputData = useCallback(
    debounce((val) => getUserInputData(val), 1000),
    [getUserInputData]
  );

  const getMcapLabel = (val) => (
    <McapLabel val={val} type={val.type === 'nft' ? 'nft' : ''} />
  );
  const getChainLabel = useCallback((val) => <IconNearby val={val} />, []);

  return (
    <div className={styles.wrapper}>
      <AsyncSelect
        className={styles.chain_select}
        styles={onChainInputStyles}
        maxMenuHeight={300}
        isOptionSelected={false}
        loadOptions={loadUserInputData}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components
          Control: (props) => <ControlComponent props={props} onChain />,
        }}
        selectProps={selectedItem}
        getOptionLabel={(val) => getMcapLabel(val)}
        onChange={(e) => {
          if (e) {
            if (!e.blockchain) {
              setSelectedAddress(e.value);
              setSelectedBlockchain(null);
              setSelectedItem({
                ...e,
                label: `${e.label.slice(0, 21)}...${e.label.slice(-5)}`,
              });
            } else {
              setSelectedItem(e);
              setSelectedAddress(
                e.contract_address ? e.contract_address : e.value
              );
              setSelectedBlockchain(
                blockChainOptionsWithTestnets.find(
                  (elem) => elem.value === e.blockchain
                )?.network
              );
            }
          } else {
            setSelectedItem(null);
            setSelectedBlockchain(null);
            setSelectedAddress(null);
            setAmount(null);
          }
        }}
        isLoading={
          isNftLoading ||
          isNftFetching ||
          teamAssetsIsLoading ||
          teamAssetsIsFetching ||
          isTop5NFTsLoading ||
          isTop5NFTsFetching ||
          isNftTestnetsLoading ||
          isNftTestnetsFetching
        }
        placeholder="NFT collection name or contract address"
        value={selectedItem}
        defaultOptions={nftOptions}
        isDisabled={isDisabled}
        isClearable
      />
      {user?.nft_creation &&
      (status !== 'running' ||
        status !== 'scheduled' ||
        status !== 'stopped') &&
      !selectedItem?.value ? (
        <NFTCollectionCreation id={nodeID} flow />
      ) : null}
      {!selectedItem?.image_url && selectedItem?.value && (
        <div>
          <div className={`my-1 ${styles.block_title}`}>Select blockchain</div>
          <Select
            className="w-100"
            styles={sourceInputStyles}
            value={blockChainOptionsWithTestnets.find(
              (elem) => elem.value === selectedItem?.blockchain
            )}
            onChange={(val) => {
              setSelectedBlockchain(val.network);
              setSelectedItem({
                ...selectedItem,
                blockchain: val.value,
                testnet: !!val?.testnet,
              });
            }}
            getOptionLabel={(val) => getChainLabel(val)}
            options={blockChainOptionsWithTestnets}
            isDisabled={isDisabled}
          />
        </div>
      )}
      {selectedItem?.blockchain && selectedItem?.value && !isERC1155 && (
        <div>
          <div className={styles.balance_title}>
            <div className={styles.block_title}>
              Balance available in wallet:
            </div>
            <div
              className={`${styles.balance_count} ${!address ? styles.not_connected : ''}`}
            >
              {address ? (
                userBalance ? (
                  dividerCommaFormat(userBalance)
                ) : (
                  <Spinner />
                )
              ) : (
                'Not connected'
              )}
            </div>
          </div>
          <div className="d-flex gap-3">
            {address ? (
              !isApproved ? (
                isPendingTxn(pendingTxns, approveForAllType) ? (
                  <div>
                    <RoundSpinner position="position-absolute" theme="light" />
                    Approving...
                  </div>
                ) : (
                  <div>
                    <button
                      type="button"
                      className={`${styles.wallet_btn} outline-blue-button`}
                      onClick={() => approveForAll(true)}
                      disabled={isDisabled}
                    >
                      Allow Absolute Labs to use the NFTs
                    </button>
                  </div>
                )
              ) : (
                <div>
                  <button
                    type="button"
                    className={`${styles.wallet_btn} outline-blue-button`}
                    onClick={() => openWeb3Modal()}
                    disabled={isDisabled}
                  >
                    {truncateAddress(address)} linked
                  </button>
                </div>
              )
            ) : window.ethereum ? (
              <button
                type="button"
                className={`${styles.wallet_btn} outline-blue-button`}
                onClick={async () => {
                  openWeb3Modal();
                }}
              >
                Connect Wallet
              </button>
            ) : (
              <div>It seems like you don&apos;t have any wallet available.</div>
            )}
          </div>
        </div>
      )}
      {selectedItem?.blockchain && selectedItem?.value && !isERC1155 && (
        <div className="mb-3">
          <div className={`${styles.block_title} mb-1`}>
            Number of NFTs to send to each wallets entering
          </div>
          <div className="d-flex align-items-center gap-3">
            <input
              type="number"
              className={styles.input_number}
              value={amount}
              onChange={(e) => {
                setAmount(e.target.value);
              }}
              min="0"
              onWheel={(e) => e.target.blur()}
              disabled={isDisabled}
            />
          </div>
        </div>
      )}
      {isERC1155 ? (
        <div className={`${styles.warning} d-flex gap-2 align-items-center`}>
          <Warning />
          ERC1155 NFT airdrop is not yet supported
        </div>
      ) : null}
      {!isLoadingUserBalance && amount && +amount > userBalance ? (
        <div className={`${styles.warning} d-flex gap-2 align-items-center`}>
          <Warning />
          Balance is too low for this amount
        </div>
      ) : null}
    </div>
  );
};

export default NFTAirdrop;
