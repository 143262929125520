import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { flowsApi } from '../../../../api/flows';
import TickCircle from '../../../../assets/icons/tick_circle.svg';
import warning from '../../../../assets/icons/warning_rounded.svg?url';
import XCircle from '../../../../assets/icons/x-circle.svg';
import {
  isCloseSendModal,
  selectIsCloseSendModal,
} from '../../../../store/reducers/flows';
import { getUser } from '../../../../store/reducers/user';
import { isWalletAddress } from '../../../../utils/supportedBlockchains';
import Loader from '../../../base/Loader';
import Modal from '../../../base/Modal';
import ModalFooter from '../../../base/Modal/ModalFooter';
import ModalTitle from '../../../base/Modal/ModalTitle';
import { showErrorMessage } from '../../../base/Notifications';
import styles from './SendToFLowModal.module.scss';

const SendToFlowModal = ({
  result,
  resultSend,
  sendWalletInFlow,
  checkWalletInFlow,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const isCloseModal = useSelector(selectIsCloseSendModal);
  const user = useSelector(getUser);

  const [selectFlows, setSelectFlows] = useState([]);
  const [showAirDropWarning, setShowAirDropWarning] = useState(false);

  const { name } = useParams();
  const navigate = useNavigate();
  const { register, handleSubmit, setValue, watch, reset } = useForm();

  const {
    data: flows,
    isLoading,
    isFetching,
    error,
  } = flowsApi.useGetFlowsQuery(
    {
      limit: 1000000,
    },
    {
      skip: isCloseModal,
    }
  );

  const clearFlow = () => {
    setTimeout(() => {
      reset({
        flow_id: '',
        flow_name: '',
        isAirdropUsed: '',
      });
      dispatch(isCloseSendModal(true));
      setShowAirDropWarning(false);
      onCancel();
    }, 200);
    clearTimeout();
  };

  const onSubmit = (onSubmitData) => {
    if (watch('isAirdropUsed') && !user.air_drop) {
      setShowAirDropWarning(true);
    } else {
      checkWalletInFlow({
        id: onSubmitData.flow_id,
        address: isWalletAddress(name) ? `?address=${name}` : `?alid=${name}`,
      });
    }
  };

  useEffect(() => {
    if (flows && flows?.results && flows?.results?.length) {
      setSelectFlows(
        flows?.results
          .filter((elem) => elem.status === 'running')
          .map((item) => ({
            value: item.id,
            label: item.name,
            isAirdropUsed: item?.has_airdrop_node,
          }))
      );
    } else {
      setSelectFlows([]);
    }
  }, [flows]);

  useEffect(() => {
    if (result.isSuccess) {
      if (!result?.data?.wallet_in_flow) {
        sendWalletInFlow({
          id: watch('flow_id'),
          address: isWalletAddress(name)
            ? {
                address: name,
              }
            : {
                alid: name,
              },
        });
        result.reset();
      }
    }
    if (result.isError) {
      showErrorMessage('Something went wrong');
      result.reset();
    }
  }, [name, result, sendWalletInFlow, watch]);

  useEffect(() => {
    if (
      resultSend.isSuccess &&
      resultSend.data?.ALID &&
      isWalletAddress(name)
    ) {
      navigate(`/profile/${resultSend.data.ALID}`);
    } else if (resultSend.isError) {
      showErrorMessage('Something went wrong');
    }
  }, [resultSend, setValue]);

  const getTitle = useCallback(() => {
    if (error?.status === 403 || resultSend?.error?.status === 403) {
      return 'We are sorry...';
    }
    if (
      result.isLoading ||
      result.isFetching ||
      resultSend.isLoading ||
      resultSend.isFetching ||
      resultSend.isSuccess ||
      resultSend.isError
    ) {
      return '';
    }
    if (result?.isSuccess && result?.data?.wallet_in_flow) {
      return 'Warning';
    }
    return 'Send to a Flow';
  }, [
    error?.status,
    result,
    resultSend?.isLoading,
    resultSend?.isFetching,
    resultSend?.isSuccess,
    resultSend?.isError,
  ]);

  const content = useMemo(() => {
    if (error?.status === 403 || resultSend?.error?.status === 403) {
      return (
        <>
          <div className="text-md-regular">
            {resultSend?.error?.data?.detail
              ? resultSend?.error?.data?.detail
              : `The feature you’re trying to use has restricted access. 
                Please reach out to your Customer Success Manager.`}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button
              className="regular-button"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => clearFlow()}
            >
              Okay
            </button>
          </div>
        </>
      );
    }
    if (
      result.isLoading ||
      result.isFetching ||
      resultSend.isLoading ||
      resultSend.isFetching
    ) {
      return (
        <div
          className={`${styles.loading} d-flex justify-content-center w-100 flex-column align-items-center`}
        >
          <span>Please wait...</span>
          <span>Sending can take up to a minute.</span>
          <div className="my-4">
            <Loader />
          </div>
        </div>
      );
    }
    if (result?.isSuccess && result?.data?.wallet_in_flow) {
      return (
        <>
          <div className={`${styles.description} d-flex w-100`}>
            <span>
              This wallet already went through the selected flow. Do you still
              want to proceed?
            </span>
          </div>
          <div
            className={`${styles.footer} d-flex align-self-end flex-row-reverse`}
          >
            <button
              type="button"
              className="regular-button"
              onClick={() => {
                result.reset();
                sendWalletInFlow({
                  id: watch('flow_id'),
                  address: isWalletAddress(name)
                    ? {
                        address: name,
                      }
                    : {
                        alid: name,
                      },
                });
              }}
            >
              Yes
            </button>
            <button
              type="button"
              className="outline-button border border-1"
              data-bs-dismiss="modal"
              onClick={() =>
                reset({
                  flow_id: '',
                  flow_name: '',
                  isAirdropUsed: '',
                })
              }
            >
              Cancel
            </button>
          </div>
        </>
      );
    }
    if (resultSend.isSuccess) {
      return (
        <div
          className={`${styles.loading} d-flex justify-content-center w-100 flex-column px-4 pb-4 pt-2 align-items-center`}
        >
          <TickCircle className="mb-2" />
          <span>The wallet has been sent successfully. </span>
          <span className={`${styles.info}`}>
            {`The wallet has been sent to the “${watch('flow_name')}” flow.`}
          </span>
        </div>
      );
    }
    if (resultSend.isError) {
      return (
        <div
          className={`${styles.loading} d-flex justify-content-center w-100 flex-column px-4 pb-4 pt-2 align-items-center`}
        >
          <XCircle className="mb-2" />
          <span>The wallet wasn’t sent to the Flow.</span>
          <span>Please refresh the page and try again.</span>
        </div>
      );
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${styles.description} d-flex w-100`}>
          <span>Select a flow to send this wallet to.</span>
        </div>
        <div
          className={`${styles.select} d-flex align-items-center w-100 mt-3 mb-4`}
        >
          <Select
            {...register('flow_id')}
            className="w-100"
            placeholder="Select..."
            maxMenuHeight={194}
            onChange={(option) => {
              setValue('flow_id', option.value);
              setValue('flow_name', option.label);
              setValue('isAirdropUsed', option.isAirdropUsed);
              setShowAirDropWarning(false);
            }}
            value={
              watch('flow_id')
                ? [{ value: watch('flow_id'), label: watch('flow_name') }]
                : ''
            }
            isLoading={isLoading || isFetching}
            options={isLoading || isFetching ? [] : selectFlows}
          />
        </div>
        {showAirDropWarning && (
          <div
            className={`d-flex w-100 gap-2 mt-2 mb-4 ${styles.wrap_warning}`}
          >
            <img
              src={warning}
              alt="warning"
              width="24px"
              height="24px"
              className="mt-1 align-self-start"
            />
            <div className="d-flex text-start">
              Your account is not allowed to launch a Flow performing airdrops.
              Please select another flow or communicate with your CSM for
              details.
            </div>
          </div>
        )}
        <ModalFooter>
          <button
            type="button"
            className="outline-button border border-1"
            onClick={() => clearFlow()}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="regular-button"
            disabled={!watch('flow_id')}
          >
            Send
          </button>
        </ModalFooter>
      </form>
    );
  }, [
    error?.status,
    result,
    resultSend?.isLoading,
    resultSend?.isFetching,
    resultSend?.isSuccess,
    resultSend?.isError,
    handleSubmit,
    onSubmit,
    clearFlow,
    register,
    showAirDropWarning,
    watch,
    isLoading,
    isFetching,
    selectFlows,
    sendWalletInFlow,
    name,
    reset,
    setValue,
  ]);

  return (
    <Modal onCancel={() => clearFlow()}>
      <ModalTitle title={getTitle()} />
      {content}
    </Modal>
  );
};

export default SendToFlowModal;
