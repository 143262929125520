import {
  types,
  searchValues,
  setValues,
  intValues,
  containValues,
  datePickerValues,
  booleanValues,
  filterValues,
  dropdownOptions,
  excludeOptionNames,
  anyOfAllOfValues,
} from './constants';

const getValue = (data, optionName) => {
  const option = data.options.find((opt) => opt.name === optionName);
  return option?.value?.length > 0 ? option.value[0].value : undefined;
};

const processDevActivity = (data) => {
  let shouldProcess = false;

  if (data.name === 'has-developer-activity') {
    const value = getValue(data, 'yes-or-no');
    if (value) {
      shouldProcess = true;
    }
  } else if (data.name === 'has-deployed-contracts') {
    const anyNumberValue = getValue(data, 'any-number');

    if (anyNumberValue === 'any-number') {
      const deploymentValue = getValue(data, 'deployment');

      if (deploymentValue === 'anytime') {
        shouldProcess = true;
      } else if (
        deploymentValue === 'and-first-deployment' ||
        deploymentValue === 'and-last-deployment'
      ) {
        const isThanComboValue = getValue(data, 'is-than-combo');

        if (
          isThanComboValue === 'is-before' ||
          isThanComboValue === 'is-after'
        ) {
          const dateValue = getValue(data, 'date');
          if (dateValue) {
            shouldProcess = true;
          }
        } else if (
          isThanComboValue === 'is-more-than' ||
          isThanComboValue === 'is-less-than'
        ) {
          const amountValue = getValue(data, 'is-than-amount');
          const agoValue = getValue(data, 'ago');
          if (amountValue && agoValue) {
            shouldProcess = true;
          }
        }
      }
    } else if (anyNumberValue === 'at-least' || anyNumberValue === 'at-most') {
      const amountValue = getValue(data, 'any-number-amount');
      const durationValue = getValue(data, 'deployed-contracts-duration');
      if (durationValue && amountValue) {
        shouldProcess = true;
      }
    }

    const chainValue = getValue(data, 'any-chain-or-any-of-or-all-of');
    if (!chainValue) {
      shouldProcess = false;
    } else if (chainValue === 'on-any-of' || chainValue === 'on-all-of') {
      const chainsValue = getValue(data, 'chain');
      if (!chainsValue) {
        shouldProcess = false;
      }
    }
  }

  return shouldProcess ? { status: true, type: types[0] } : { status: false };
};

export const processStructure = (data) => {
  if (!data?.options) {
    return { status: false };
  }

  if (data.category === 'Developer Activity') {
    return processDevActivity(data);
  }

  const { options } = data;

  let shouldProcess = { status: false };

  const hasSetValues = options.some((option) =>
    setValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  if (hasSetValues) {
    if (data.category !== 'Web activity') {
      return { status: true, type: types[1] };
    }
    const hasAnyAllValue = options.some((option) =>
      anyOfAllOfValues.includes(
        Array.isArray(option.value) ? option.value[0]?.value : null
      )
    );
    const websitesValue = options.find(
      (option) => option.name === 'web-site'
    )?.value;
    const hasWebsites = websitesValue ? websitesValue.length > 0 : false;
    return { status: hasAnyAllValue && hasWebsites, type: types[10] };
  }

  const hasBooleanValues = options.some((option) =>
    booleanValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasCexLabel = data.label === 'Uses CEX';
  if (hasBooleanValues && hasCexLabel) {
    shouldProcess = { status: true, type: types[8] };
    return shouldProcess;
  }

  const hasCexValues = options.some((option) =>
    Array.isArray(option.value)
      ? option.type === 'search-cex' && option.value[0]?.value
      : false
  );
  if (hasCexValues) {
    shouldProcess = { status: true, type: types[2] };
    return shouldProcess;
  }

  const areAllFieldsSetForDapp = (dappTypeKey) => {
    const excludedOptionNames = excludeOptionNames[dappTypeKey];
    const isThanComboOption = data.options.find(
      (option) => option.name === 'is-than-combo'
    );
    const isThanComboValue = isThanComboOption?.value?.[0]?.value;

    return data.options.every((option) => {
      if (excludedOptionNames.includes(option.name)) {
        return true;
      }
      const result = (() => {
        if (option.name === 'date-picker' || option.name === 'date') {
          return ['is-before', 'is-after'].includes(isThanComboValue)
            ? Array.isArray(option.value) && option.value[0]?.value
            : true;
        }

        if (option.name === 'dapp-amount' || option.name === 'ago') {
          return !['is-before', 'is-after'].includes(isThanComboValue)
            ? Array.isArray(option.value) &&
                option.value[0]?.value !== undefined
            : true;
        }

        return (
          Array.isArray(option.value) && option.value[0]?.value !== undefined
        );
      })();

      return result;
    });
  };

  const hasDappValues = data.options.some((option) =>
    Array.isArray(option.value)
      ? option.type === 'search-dapp' && option.value[0]?.value
      : false
  );

  if (hasDappValues) {
    const dappTypeKey = Object.keys(dropdownOptions).find((key) =>
      dropdownOptions[key].includes(data.options[2]?.value[0]?.value)
    );

    if (dappTypeKey && areAllFieldsSetForDapp(dappTypeKey)) {
      const isThanComboOption = data.options.find(
        (option) => option.name === 'is-than-combo'
      );
      const isThanComboValue = isThanComboOption?.value?.[0]?.value;

      shouldProcess = {
        status: true,
        type: types[9],
        dappTypeKey,
        subFilter: isThanComboValue,
      };
      return shouldProcess;
    }
  }

  const hasIntValues = options.some((option) =>
    intValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );

  const allIntValues = [...options.slice(0, 1), ...options.slice(3, 5)].every(
    (option) => (Array.isArray(option.value) ? option.value[0]?.value : false)
  );

  if (hasIntValues && allIntValues && data.type) {
    shouldProcess = { status: true, type: types[3] };
    return shouldProcess;
  }

  const hasDateValues = options.some((option) =>
    datePickerValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );

  if (hasDateValues && ['date', 'timestamp'].includes(data.type)) {
    shouldProcess = { status: true, type: types[4] };
  }

  const hasContainValues = options.some((option) =>
    containValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasMultiInput = options.some((option) =>
    option.type === 'multi-input' &&
    option?.value?.length > 0 &&
    Array.isArray(option.value)
      ? option.value[0]?.value
      : false
  );
  const allValues = options
    .slice(0, 3)
    .every((option) =>
      Array.isArray(option.value) ? option.value[0]?.value : false
    );
  if (hasContainValues && hasMultiInput && allValues) {
    shouldProcess = { status: true, type: types[5] };
    return shouldProcess;
  }

  const hasSearchValues = options.some((option) =>
    searchValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasMultiInputInCustomSearch = options.some((option) =>
    option.type === 'custom-search' &&
    option?.value?.length > 0 &&
    Array.isArray(option.value)
      ? option.value[0]?.value
      : false
  );

  const allValuesSearch = [
    ...options.slice(0, 2),
    ...options.slice(3, 4),
  ].every((option) =>
    Array.isArray(option.value) ? option.value[0]?.value : false
  );

  if (hasSearchValues && hasMultiInputInCustomSearch && allValuesSearch) {
    shouldProcess = { status: true, type: types[6] };
    return shouldProcess;
  }

  const hasFilterValues = options.some((option) =>
    filterValues.includes(
      Array.isArray(option.value) ? option.value[0]?.value : null
    )
  );
  const hasValuesInOptions = options
    .slice(0, 3)
    .every((option) =>
      Array.isArray(option.value) ? option.value[0]?.value?.length > 0 : false
    );
  if (hasFilterValues && hasValuesInOptions) {
    shouldProcess = { status: true, type: types[7] };
    return shouldProcess;
  }

  const hasAllValues = options.every((option) =>
    Array.isArray(option.value) ? option.value[0]?.value : false
  );
  if (hasAllValues) {
    shouldProcess = { status: true, type: types[0] };
    return shouldProcess;
  }

  return shouldProcess;
};
