const supportedDataTypes = ['address', 'address[]', 'uint256', 'uint256[]'];
const mandatoryDataTypes = ['address', 'address[]'];

function convertStringAbiToArray(obj) {
  try {
    return JSON.parse(obj);
  } catch (err) {
    return { error: 'Incorrect json', data: null };
  }
}

export const checkAbi = (abi) =>
  Array.isArray(abi) &&
  typeof abi[0] === 'object' &&
  Object.prototype.hasOwnProperty.call(abi[0], 'inputs') &&
  Object.prototype.hasOwnProperty.call(abi[0], 'type');

export const formatAbi = (contractAbi, type = 'string') => {
  const abi =
    type === 'string' ? convertStringAbiToArray(contractAbi) : contractAbi;

  if (abi.error) {
    return abi;
  }

  if (!checkAbi(abi)) {
    return { error: 'Incorrect abi', data: null };
  }

  const supportedFunctionsArray = [];

  abi.forEach((obj) => {
    if (!obj.inputs || obj.inputs.length === 0) {
      return;
    }

    if (['view', 'pure'].includes(obj.stateMutability)) {
      return;
    }

    if (obj.type === 'function') {
      if (
        obj.inputs.every((input) => supportedDataTypes.includes(input.type))
      ) {
        if (
          obj.inputs.some((input) => mandatoryDataTypes.includes(input.type))
        ) {
          supportedFunctionsArray.push(obj);
        }
      }
    }
  });

  return { error: null, data: supportedFunctionsArray };
};
