import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useBlocker } from 'react-router-dom';

export function useCallbackPrompt(when) {
  const navigate = useNavigate();
  const [showPrompt, setShowPrompt] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (
      !confirmedNavigation &&
      when &&
      currentLocation.pathname !== nextLocation.pathname
    ) {
      setShowPrompt(true);
      setLastLocation(nextLocation);
      return true;
    }
  });

  const cancelNavigation = useCallback(() => {
    setShowPrompt(false);
    if (blocker && blocker.reset) {
      blocker.reset();
    }
  }, [blocker]);

  const confirmNavigation = useCallback(() => {
    setShowPrompt(false);
    setConfirmedNavigation(true);
    if (blocker && blocker.proceed) {
      blocker.proceed();
    }
  }, [blocker]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  const navigateWithoutBlocking = useCallback(
    (path) => {
      setConfirmedNavigation(true);
      navigate(path);

      setTimeout(() => {
        setConfirmedNavigation(false);
      }, 100);
    },
    [navigate]
  );

  return [
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    navigateWithoutBlocking,
  ];
}
