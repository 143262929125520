import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
  pills: [],
  isSearchModalOpen: false,
  contractDeployer: null,
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchData(state, action) {
      state.data = action.payload;
    },
    addPill(state, action) {
      state.pills.push(action.payload);
    },
    deletePill(state, action) {
      state.pills = state.pills.filter(
        (elem) =>
          elem.asset_id !== action.payload.id ||
          (elem.asset_id === action.payload.id &&
            elem.network !== action.payload.network)
      );
    },
    clearPills(state) {
      state.pills = [];
    },
    setContractDeployer(state, action) {
      state.contractDeployer = action.payload;
    },
    getIsSearchModalOpen(state, action) {
      state.isSearchModalOpen = action.payload;
      state.contractDeployer = action.payload ? state.contractDeployer : null;
    },
  },
});

export const {
  setSearchData,
  addPill,
  deletePill,
  clearPills,
  getIsSearchModalOpen,
  setContractDeployer,
} = searchSlice.actions;

export const selectDataToRender = (state) => state.searchReducer.data;
export const selectPills = (state) => state.searchReducer.pills;
export const selectIsSearchModalOpen = (state) =>
  state.searchReducer.isSearchModalOpen;
export const selectContractDeployer = (state) =>
  state.searchReducer.contractDeployer;

export default searchSlice.reducer;
