export const getGroup = (label) => {
  switch (label) {
    case 'Activity':
    case 'Interests':
    case 'Exchanges and Dapps':
    case 'Developer Activity':
      return 'On-Chain Profile';
    case 'Portfolio values':
    case 'Investments':
    case 'History':
      return 'Holdings';
    case 'Custom lists':
    case 'Segments':
    case 'Profile channels':
    case 'Profile attributes':
      return 'CRM';
    case 'Web activity':
    case 'Forms':
    case 'Social activity':
      return 'Behavior Tracking';
    default:
      throw new Error(`Missing group for label ${label}`);
  }
};
