import { format } from 'date-fns';
import donut from '../../assets/icons/flows/donut.svg?url';
import plus from '../../assets/icons/flows/plus.svg?url';

export const handle = {
  width: '16px',
  height: '16px',
  borderRadius: '50%',
  backgroundColor: '#90A0B7',
  backgroundImage: `url(${plus})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  border: 'none',
};

export const handleInput = {
  width: '10px',
  height: '10px',
  backgroundColor: '#FFFFFF',
  backgroundImage: `url(${donut})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  border: 'none',
};

export const right = {
  right: '-16px',
};

export const left = {
  left: '-16px',
};

export const bottom = {
  bottom: '-16px',
};

export const formatDate = (date, pattern) => {
  const dt = new Date(date);
  const dtDateOnly = new Date(
    dt.valueOf() + dt.getTimezoneOffset() * 60 * -1000
  );
  return format(dtDateOnly, pattern);
};
