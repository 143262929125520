import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import {
  RouterProvider, 
  Route, 
  createBrowserRouter, 
  createRoutesFromElements, 
  useLocation, 
  useNavigationType,
} from 'react-router-dom';
import { createRoutesFromChildren, matchRoutes } from "react-router";
import * as Sentry from '@sentry/react';
import 'bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import mixpanel from 'mixpanel-browser';

import './scss/globals/_globals.scss';
import App from './App';

if (import.meta.env.VITE_APP_MIXPANEL_TOKEN) {
  mixpanel.init(
    import.meta.env.VITE_APP_MIXPANEL_TOKEN,
    {
      track_pageview: false,
      api_host: import.meta.env.VITE_APP_MIXPANEL_PROXY
        ? 'https://tracking-proxy.absolutelabs.app' : 'https://api.mixpanel.com',
    },
  );
}

Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: import.meta.env.VITE_SENTY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 0.5,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const router = createBrowserRouter(createRoutesFromElements(<Route path="*" element={<App />} />));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
