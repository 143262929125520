/* eslint-disable import/no-unresolved */
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi';
import { createAppKit } from '@reown/appkit/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { WagmiProvider } from 'wagmi';
import Layout from './components/Layout';
import Auth0ProviderWithHistory from './components/providers/Auth0Provider';
import { setupStore } from './store';
import { ScrollToTop } from './tools/ScrollToTop';
import { getAllNetworks } from './utils/web3/networks';

const store = setupStore();
const queryClient = new QueryClient();

if (!import.meta.env.VITE_APP_PROJECT_ID) {
  throw new Error('You need to provide VITE_APP_PROJECT_ID env variable');
}

export const projectId = import.meta.env.VITE_APP_PROJECT_ID;

const wagmiAdapter = new WagmiAdapter({
  projectId,
  networks: getAllNetworks(),
});

createAppKit({
  projectId,
  adapters: [wagmiAdapter],
  networks: getAllNetworks(),
  features: {
    analytics: false,
    socials: false,
    email: false,
  },
  enableWalletGuide: false,
  themeMode: 'light',
});

const App = () => (
  <Provider store={store}>
    <Auth0ProviderWithHistory>
      <WagmiProvider config={wagmiAdapter.wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <ScrollToTop />
          <Layout />
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
          />
        </QueryClientProvider>
      </WagmiProvider>
    </Auth0ProviderWithHistory>
  </Provider>
);

export default App;
