import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import styles from './SegmentsNode.module.scss';
import Segments from '../../../../../assets/icons/flows/segments.svg';
import Edit from '../../../../../assets/icons/flows/edit.svg';
import Alert from '../../../../../assets/icons/flows/alert.svg';
import Divider from '../../../../../assets/icons/divider.svg';
import Delete from '../../../../../assets/icons/flows/delete.svg';
import { handle, right } from '../../../../../utils/flows';
import {
  clearActiveSettings,
  deleteSettings,
  selectConfigsList,
  selectErrors, selectFlowStatus,
  setActiveSettings,
} from '../../../../../store/reducers/flows';
import { convertNumber } from '../../../../../tools/NumberConverterTool';
import Tooltip from '../../../../../components/ui/Tooltip';

const SegmentsNode = ({ ...props }) => {
  const dispatch = useDispatch();
  // temporary removed ability to connect only 1 output
  // const [handleOutput, setHandleOutput] = useState(true);
  const [data, setData] = useState();
  const [completed, setCompleted] = useState(false);
  const [onNodeHover, setOnNodeHover] = useState(false);

  const configList = useSelector(selectConfigsList);
  const errors = useSelector(selectErrors);
  const status = useSelector(selectFlowStatus);

  const filteredErrors = () => errors.length && errors.filter((elem) => elem.node_id === props.id);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(props.id);
      if (index >= 0) {
        setData(configList[index]);
      }
    }
  }, [configList, props.id]);

  useEffect(() => {
    if (data && data.population && data.name) {
      if (data.frequency) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    } else {
      setCompleted(false);
    }
  }, [data]);

  return (
    <>
      <div
        className={`
        ${styles.wrapper}
        ${data && (data.population || data.population === 0) ? styles.size120 : ''}
        ${status === 'running' || status === 'scheduled' || status === 'stopped' ? 'cursor-pointer' : ''}
        d-flex
        flex-column
        justify-content-center
        `}
        role="presentation"
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
        onClick={() => {
          if (status === 'running' || status === 'scheduled' || status === 'stopped') {
            dispatch(setActiveSettings({ type: 'segmentsNode', node_id: props.id }));
          }
        }}
      >
        <div className="d-flex align-items-center justify-content-between">
          {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
            ? (
              <>
                {!completed || filteredErrors().length
                  ? (
                    <>
                      <div
                        className={styles.alert}
                        data-for={`${props.id}_error`}
                        data-tip
                      >
                        <Alert />
                      </div>
                      {filteredErrors().length
                        ? (
                          <Tooltip
                            id={`${props.id}_error`}
                            info={`${filteredErrors()[0].detail}`}
                            position="right"
                          />
                        )
                        : null}
                    </>
                  )
                  : null}
                <div className={onNodeHover ? styles.delete : styles.delete_hide}>
                  <Delete onClick={() => {
                    props.data.onRemove(props.id);
                    dispatch(deleteSettings(props.id));
                    dispatch(clearActiveSettings());
                  }}
                  />
                </div>
              </>
            )
            : null}
          <div className={`${styles.title} d-flex align-items-center gap-2`}>
            <div width="32px" height="32px">
              <Segments />
            </div>
            <div className={`${styles.description} text-truncate`}>
              {(data && data.name) || 'New segment source'}
            </div>
          </div>
          <div className="ms-2 cursor-pointer">
            <Edit onClick={() => dispatch(setActiveSettings({ type: 'segmentsNode', node_id: props.id }))} />
          </div>
        </div>
        {data && (data.population || data.population === 0)
          ? (
            <>
              <div className={`${styles.divider} d-flex`}>
                <Divider />
              </div>
              <div className={`${styles.info} d-flex justify-content-center gap-3`}>
                <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
                  <div>
                    {convertNumber(data.population)}
                  </div>
                  <div className="text-nowrap">
                    Est. Audience size
                  </div>
                </div>
                <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
                  <div>
                    {data?.frequency || '?'}
                  </div>
                  <div>
                    Frequency
                  </div>
                </div>
                {typeof data?.can_re_enter === 'boolean' ? (
                  <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
                    <div>
                      {data?.can_re_enter ? 'Yes' : 'No'}
                    </div>
                    <div className="text-nowrap">
                      Can re-enter
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )
          : null}
      </div>
      <Handle
        type="source"
        id="segmentsOutput"
        // isConnectable={handleOutput}
        // onConnect={() => setHandleOutput(false)}
        position={Position.Right}
        style={{ ...handle, ...right }}
      />
    </>
  );
};

export default SegmentsNode;
