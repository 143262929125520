import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { flowsApi } from '../../../../api/flows';
import { profilesApi } from '../../../../api/profiles';
import NotSanctioned from '../../../../assets/icons/anaytics/notSanctioned.svg';
import Sanctioned from '../../../../assets/icons/anaytics/Sanctioned.svg';
import Twitter from '../../../../assets/icons/anaytics/wallet_twitter.svg';
import Copy from '../../../../assets/icons/copy_outline.svg';
import Wallet from '../../../../assets/icons/wallet_logo.svg';
import {
  resetSelectedAsset,
  selectSelectedAsset,
} from '../../../../store/reducers/app';
import { isCloseSendModal } from '../../../../store/reducers/flows';
import { ethFormat } from '../../../../utils/singleAssetPage/parseData';
import {
  isWalletAddress,
  supportedBlockchains,
} from '../../../../utils/supportedBlockchains';
import uniqueId from '../../../../utils/uniqueId';
import ChainList from '../../../base/ChainLogo/ChainList';
import { showSuccessMessage } from '../../../base/Notifications';
import {
  LoadingAvatar,
  LoadingLines,
} from '../../modals/SearchModal/LoadingList';
import SendToFlowModal from '../SendToFLowModal';
import styles from '../TitleSection.module.scss';

const SingleWalletTitle = ({ singleWalletData, isWalletLoading, address }) => {
  const dispatch = useDispatch();
  const [logoError, setLogoError] = useState(false);
  const [data, setData] = useState({});
  const [readMoreIndicator, setReadMoreIndicator] = useState(false);
  const [showSendToFlowModal, setShowSendToFlowModal] = useState(false);

  const { name } = useParams();

  const selectedAsset = useSelector(selectSelectedAsset);

  const { data: isWalletSanctioned } = profilesApi.useWalletIsSanctionedQuery(
    isWalletAddress(name)
      ? `?address=${name}`
      : `?alid=${name}&address=${address}`,
    {
      skip: !isWalletAddress(name) && !address,
    }
  );

  const [checkWalletInFlow, result] = flowsApi.useCheckWalletInFlowMutation();
  const [sendWalletInFlow, resultSend] = flowsApi.useSendWalletInFlowMutation();

  useEffect(() => {
    if (isWalletLoading && !Object.keys(selectedAsset).length) {
      setData({});
    }
    if (Object.keys(selectedAsset).length && !singleWalletData) {
      setData(selectedAsset);
    }
    if (singleWalletData) {
      setData(singleWalletData);
    }
  }, [isWalletLoading, selectedAsset, singleWalletData]);

  const checkWallet = useMemo(() => {
    if (isWalletSanctioned) {
      if (isWalletSanctioned?.identifications.length) {
        const sanctionedInfo = (
          <div className={`${styles.contentTooltip} text-center p-2`}>
            This address is included in a sanctions designation.
            <span
              role="presentation"
              onClick={() =>
                window.open(
                  'https://public.chainalysis.com/docs/index.html#definition-of-sanctioned-entities',
                  '_blank'
                )
              }
              className={`${styles.read} cursor-pointer px-1`}
            >
              Read more
            </span>
          </div>
        );
        return (
          <div
            className={`${styles.popover__wrapper} position-relative cursor-pointer`}
          >
            <Sanctioned />
            <div className={`${styles.popover__content} position-absolute`}>
              {sanctionedInfo}
            </div>
          </div>
        );
      }
      if (!isWalletSanctioned?.identifications.length) {
        const notSanctionedInfo = (
          <div className={`${styles.contentTooltip} text-center p-2`}>
            This address has not been included in any sanctions designation.
            Data provided by Chainalysis.
            <span
              role="presentation"
              onClick={() =>
                window.open(
                  'https://public.chainalysis.com/docs/index.html#definition-of-sanctioned-entities',
                  '_blank'
                )
              }
              className={`${styles.read} cursor-pointer px-1`}
            >
              Read more
            </span>
          </div>
        );
        return (
          <div
            className={`${styles.popover__wrapper} position-relative cursor-pointer`}
          >
            <NotSanctioned />
            <div
              className={`${styles.popover__content__not} position-absolute`}
            >
              {notSanctionedInfo}
            </div>
          </div>
        );
      }
    }
    return null;
  }, [isWalletSanctioned]);

  const isInvalidDescription = (description) => {
    const pattern = /^\S+(\s-|-)/;
    return pattern.test(description);
  };

  const descriptionContent = useMemo(() => {
    if (
      singleWalletData?.description &&
      Object.keys(singleWalletData).length > 0 &&
      !isInvalidDescription(singleWalletData.description)
    ) {
      return (
        <div className={`${styles.row} ${styles.desription_length}`}>
          <p className={styles.description}>
            {singleWalletData.description.length > 85 ? (
              !readMoreIndicator ? (
                <>
                  {singleWalletData.description.slice(0, 85)}
                  ...{' '}
                  <span
                    role="presentation"
                    onClick={() => setReadMoreIndicator(true)}
                  >
                    Read More
                  </span>
                </>
              ) : (
                <>
                  {singleWalletData.description}{' '}
                  <span
                    role="presentation"
                    onClick={() => setReadMoreIndicator(false)}
                  >
                    Read Less
                  </span>
                </>
              )
            ) : (
              singleWalletData.description
            )}
          </p>
        </div>
      );
    }
    return null;
  }, [singleWalletData?.description, readMoreIndicator]);

  useEffect(
    () => () => {
      dispatch(resetSelectedAsset());
    },
    [dispatch]
  );

  return (
    <div
      className={`${styles.walletWrapper} ${
        data?.pills && !data?.pills.filter((elem) => !!elem).length
          ? 'mb-0'
          : 'mb-3'
      }`}
    >
      {!data.icon ? (
        <div className="d-flex gap-2">
          <LoadingAvatar size={80} />
        </div>
      ) : data.icon && !logoError ? (
        <div className={styles.icon}>
          <img src={data.icon} alt="" onError={() => setLogoError(true)} />
        </div>
      ) : (
        <Wallet />
      )}
      <div className="d-flex flex-column">
        {showSendToFlowModal && (
          <SendToFlowModal
            result={result}
            resultSend={resultSend}
            checkWalletInFlow={checkWalletInFlow}
            sendWalletInFlow={sendWalletInFlow}
            onCancel={() => setShowSendToFlowModal(false)}
          />
        )}
        <div className={`${styles.row} d-flex gap-2`}>
          <div
            className={`${styles.wallet} d-flex flex-column justify-content-around w-100`}
          >
            {!data.name && isWalletLoading ? (
              <div className="d-flex align-items-center w-25">
                <LoadingLines align="start" />
              </div>
            ) : (
              <div className="mb-3">
                <div className="d-flex gap-2 align-items-center">
                  {data.name &&
                    `${
                      data.name.length > 50
                        ? `${data.name.slice(0, 50)}...`
                        : data.name
                    }`}
                  {checkWallet}
                </div>
                <div
                  className={`${styles.wallet_address} d-flex align-items-center mt-1`}
                >
                  {data.ensName &&
                    `${
                      data.ensName.length > 50
                        ? `${data.ensName.slice(0, 50)}...`
                        : data.ensName
                    } / `}
                  {data.title}
                  <Copy
                    className="cursor-pointer ms-2"
                    onClick={() => {
                      navigator.clipboard.writeText(data.id);
                      showSuccessMessage(
                        'The address was copied to your clipboard'
                      );
                    }}
                  />
                  {supportedBlockchains(data.activeOn).length ? (
                    <div className="d-flex align-items-center">
                      <div className={`${styles.divider} mx-2`} />
                      <ChainList
                        chains={supportedBlockchains(data.activeOn)}
                        small
                      />
                    </div>
                  ) : null}
                  {data.twitterCount ? (
                    <div className="d-flex align-items-center">
                      <div className={`${styles.divider} mx-2`} />
                      <Twitter />
                      <div className="px-1">
                        {`${ethFormat(data.twitterCount)} follower${data.twitterCount === 1 ? '' : 's'}`}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            <div className="d-flex gap-2 mb-3">
              {data?.pills
                ? data.pills.map(
                    (pills) =>
                      pills && (
                        <div
                          className={styles.tagWalletContainer}
                          key={uniqueId('pills-wallet')}
                        >
                          <span
                            className={`${styles.tagTextWallet} w-100 text-center`}
                          >
                            {pills}
                          </span>
                        </div>
                      )
                  )
                : null}
            </div>
            <div className="d-flex">{descriptionContent}</div>
          </div>
          <div data-for="wallet_disabled" data-tip>
            <button
              type="button"
              className="regular-button text-nowrap"
              onClick={() => {
                setShowSendToFlowModal(true);
                dispatch(isCloseSendModal(false));
                result.reset();
                resultSend.reset();
              }}
            >
              Send to a Flow
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleWalletTitle;
