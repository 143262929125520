import Polygon from '../../../assets/icons/chainLogo/chain-polygon.svg';
import Ethereum from '../../../assets/icons/chainLogo/chain-ethereum.svg';
import Linea from '../../../assets/icons/chainLogo/chain-linea.svg';
import Flow from '../../../assets/icons/chainLogo/chain-flow.svg';
import Near from '../../../assets/icons/chainLogo/chain-near.svg';
import Base from '../../../assets/icons/chainLogo/chain-base.svg';
import Ordinals from '../../../assets/icons/chainLogo/chain-ordinals.svg';
import PolygonTestnet from '../../../assets/icons/chainLogo/polygon-test.svg';
import EthereumTestnet from '../../../assets/icons/chainLogo/ethereum-test.svg';
import BaseTestnet from '../../../assets/icons/chainLogo/base-test.svg';
import LineaTestnet from '../../../assets/icons/chainLogo/linea-test.svg';
import NearTestnet from '../../../assets/icons/chainLogo/near-test.svg';
import Chiliz from '../../../assets/icons/chainLogo/chain-chiliz.svg';
import ChilizSpicy from '../../../assets/icons/chainLogo/chiliz-spicy.svg';
import FlowTestnet from '../../../assets/icons/chainLogo/flow-test.svg';
import OrdinalsTestnet from '../../../assets/icons/chainLogo/ordinals-test.svg';
import Solana from '../../../assets/icons/chainLogo/env-solana.svg';
import Cosmos from '../../../assets/icons/chainLogo/env-cosmos.svg';
import Tezos from '../../../assets/icons/chainLogo/env-tezos.svg';
import Arbitrum from '../../../assets/icons/chainLogo/chain-arbitrum.svg';
import Abstract from '../../../assets/icons/chainLogo/chain-abstract.svg';
import Etherlink from '../../../assets/icons/chainLogo/chain-etherlink.svg';
import Rootstock from '../../../assets/icons/chainLogo/chain-rootstock.svg';

export const supportedChains = {
  ethereum: {
    icon: Ethereum,
    tooltip: 'Ethereum',
    sizes: {
      small: 16,
      normal: 20,
    },
  },
  homestead: {
    icon: Ethereum,
    tooltip: 'Ethereum',
    sizes: {
      small: 16,
      normal: 20,
    },
  },
  sepolia: {
    icon: EthereumTestnet,
    tooltip: 'Sepolia (Ethereum Testnet)',
    sizes: {
      small: 16,
      normal: 20,
    },
  },
  'eth-sepolia': {
    icon: EthereumTestnet,
    tooltip: 'Sepolia (Ethereum Testnet)',
    sizes: {
      small: 16,
      normal: 20,
    },
  },
  polygon: {
    icon: Polygon,
    tooltip: 'Polygon',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  matic: {
    icon: Polygon,
    tooltip: 'Polygon',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  'polygon-pos': {
    icon: Polygon,
    tooltip: 'Polygon',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  amoy: {
    icon: PolygonTestnet,
    tooltip: 'Amoy (Polygon Testnet)',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  base: {
    icon: Base,
    tooltip: 'Base',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  'base-sepolia': {
    icon: BaseTestnet,
    tooltip: 'Sepolia (Base Testnet)',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  arbitrum: {
    icon: Arbitrum,
    tooltip: 'Arbitrum',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  abstract: {
    icon: Abstract,
    tooltip: 'Abstract',
    sizes: {
      small: 14,
      normal: 18,
    },
  },
  linea: {
    icon: Linea,
    tooltip: 'Linea',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  'linea-sepolia': {
    icon: LineaTestnet,
    tooltip: 'Sepolia (Linea Testnet)',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  chiliz: {
    icon: Chiliz,
    tooltip: 'Chiliz',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  'chiliz-spicy': {
    icon: ChilizSpicy,
    tooltip: 'Spicy (Chiliz Testnet)',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  etherlink: {
    icon: Etherlink,
    tooltip: 'Etherlink',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  rootstock: {
    icon: Rootstock,
    tooltip: 'Rootstock',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  solana: {
    icon: Solana,
    tooltip: 'Solana',
    sizes: {
      small: 10,
      normal: 13,
    },
  },
  near: {
    icon: Near,
    tooltip: 'Near',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  'near-protocol': {
    icon: Near,
    tooltip: 'Near',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  testnet: {
    icon: NearTestnet,
    tooltip: 'Near Testnet',
    sizes: {
      small: 12,
      normal: 15,
    },
  },
  ordinals: {
    icon: Ordinals,
    tooltip: 'Ordinals',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  bitcoin: {
    icon: Ordinals,
    tooltip: 'Ordinals',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  'ordinals-test': {
    icon: OrdinalsTestnet,
    tooltip: 'Ordinals Testnet',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  tezos: {
    icon: Tezos,
    tooltip: 'Tezos',
    sizes: {
      small: 15,
      normal: 18,
    },
  },
  flow: {
    icon: Flow,
    tooltip: 'Flow',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  'flow-test': {
    icon: FlowTestnet,
    tooltip: 'Flow Testnet',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
  cosmos: {
    icon: Cosmos,
    tooltip: 'Cosmos',
    sizes: {
      small: 15,
      normal: 20,
    },
  },
};

export const blockChainOptions = [
  {
    label: 'Ethereum',
    value: 'ethereum',
    network: 'homestead',
    networkId: 1,
    chainId: '0x1',
  },
  {
    label: 'Polygon',
    value: 'polygon',
    network: 'matic',
    networkId: 137,
    chainId: '0x89',
  },
  {
    label: 'Base',
    value: 'base',
    network: 'base',
    networkId: 8453,
    chainId: '0x2105',
  },
  {
    label: 'Linea',
    value: 'linea',
    network: 'linea',
    networkId: 59144,
    chainId: '0xe708',
  },
  {
    label: 'Chiliz',
    value: 'chiliz',
    network: 'chiliz',
    networkId: 88888,
    chainId: '0x15b38',
  },
];

export const blockChainOptionsWithTestnets = [
  {
    label: 'Ethereum',
    value: 'ethereum',
    network: 'homestead',
    networkId: 1,
    chainId: '0x1',
    testnet: false,
  },
  {
    label: 'Sepolia',
    value: 'eth-sepolia',
    network: 'sepolia',
    networkId: 11155111,
    chainId: '0xaa36a7',
    testnet: true,
  },
  {
    label: 'Polygon',
    value: 'polygon',
    network: 'matic',
    networkId: 137,
    chainId: '0x89',
    testnet: false,
  },
  {
    label: 'Amoy',
    value: 'amoy',
    network: 'amoy',
    networkId: 80002,
    chainId: '0x13882',
    testnet: true,
  },
  {
    label: 'Base',
    value: 'base',
    network: 'base',
    networkId: 8453,
    chainId: '0x2105',
    testnet: false,
  },
  {
    label: 'Sepolia',
    value: 'base-sepolia',
    network: 'base-sepolia',
    networkId: 84532,
    chainId: '0x14a34',
    testnet: true,
  },
  {
    label: 'Linea',
    value: 'linea',
    network: 'linea',
    networkId: 59144,
    chainId: '0xe708',
    testnet: false,
  },
  {
    label: 'Sepolia',
    value: 'linea-sepolia',
    network: 'linea-sepolia',
    networkId: 59141,
    chainId: '0xe705',
    testnet: true,
  },
  {
    label: 'Chiliz',
    value: 'chiliz',
    network: 'chiliz',
    networkId: 88888,
    chainId: '0x15b38',
    testnet: false,
  },
  {
    label: 'Spicy',
    value: 'chiliz-spicy',
    network: 'chiliz-spicy',
    networkId: 88882,
    chainId: '0x15b32',
    testnet: true,
  },
];
