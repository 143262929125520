export const handleActionAsset = (typeAsset, data, text, placeType) => {
  switch (typeAsset) {
    case 'coin':
      return {
        action: text.includes('Add') ? 'add' : 'delete',
        place_type:
          text === 'Add to Dashboard'
            ? placeType
            : text.includes('Assets')
              ? 'asset'
              : 'watchlist',
        coin: {
          coin_id: data.id,
        },
      };
    case 'nft':
      return {
        action: text.includes('Add') ? 'add' : 'delete',
        place_type: text.includes('Add')
          ? placeType
          : text.includes('Assets')
            ? 'asset'
            : 'watchlist',
        nft_collection: {
          address: data.id,
          network: data.network,
        },
      };
    default:
      return {};
  }
};
