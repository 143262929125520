import React, { useMemo, useCallback } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { formatString, preventInvalidSymbols, validateInput } from '../../../../utils/segments';
import { toISODate } from '../../../../utils/toISODate';
import styles from './TxCountControl.module.scss';
import uniqueId from '../../../../utils/uniqueId';
import { debounce } from '../../../../utils/debounce';
import { placeholderStyle } from '../../Condition/Condition.constants';

const TxCountControl = ({
  txCountData,
  txCountFirstValue,
  setTxCountFirstValue,
  pushTxCountValueOptionToSelectedOptions,
  txNextOption,
  txTrailingPeriod,
  setTxTrailingPeriod,
  txStartDate,
  setTxStartDate,
  txIsMoreLess,
  setTxIsMoreLess,
  txInputValue,
  setTxInputValue,
  txChainOption,
  setTxChainOption,
  edit,
  data,
}) => {
  const debounceValue = useCallback(
    debounce((val, option) => pushTxCountValueOptionToSelectedOptions(val, option), 1000),
    [pushTxCountValueOptionToSelectedOptions],
  );

  return useMemo(
    () => {
      if (txCountData && txCountData.options && txCountData.label === 'Transaction count'
        && txCountData.options.length === 1) {
        const firstSelect = txCountData.options[0].options[0];
        return (
          <>
            <div className="w-100 text-capitalize" key={uniqueId('transaction_count_1')}>
              <Select
                options={firstSelect.options}
                value={Array.isArray(txCountFirstValue) || txCountFirstValue ? {
                  label: txCountFirstValue[0]?.value || txCountFirstValue,
                  value: txCountFirstValue[0]?.value || txCountFirstValue,
                }
                  : undefined}
                onChange={(val) => {
                  if (firstSelect?.value && firstSelect.value[0]?.value !== val.label) {
                    setTxCountFirstValue(val.label);
                  }
                  pushTxCountValueOptionToSelectedOptions(val.value, firstSelect.name);
                }}
                isSearchable={false}
                isDisabled={!edit}
                maxMenuHeight={194}
              />
            </div>
            {txNextOption && txNextOption.type === 'select' && (
              <div className="w-100" key={uniqueId('transaction_count_2')}>
                <Select
                  options={txNextOption.options}
                  value={Array.isArray(txTrailingPeriod) ? {
                    label: txTrailingPeriod[0]?.value || txTrailingPeriod,
                    value: txTrailingPeriod[0]?.value || txTrailingPeriod,
                  }
                    : undefined}
                  onChange={(val) => {
                    pushTxCountValueOptionToSelectedOptions(val.label, txNextOption.name);
                    setTxTrailingPeriod([{ value: val.value }]);
                  }}
                  isSearchable={false}
                  isDisabled={!edit}
                  maxMenuHeight={194}
                />
              </div>
            )}
            {txCountFirstValue && txCountData.options[0].options.slice(1, 5)
              .map((option) => {
                if (Array.isArray(txCountData.options[0].options[0].value)) {
                  if (txCountData.options[0].options[0].value[0].value === 'since-date' && option.name === 'date') {
                    return (
                      <div className="w-100" key={uniqueId('tx_datePicker')}>
                        <DatePicker
                          className={`form-control ${styles.select} w-100`}
                          selected={Array.isArray(txStartDate) ? txStartDate[0].value : txStartDate}
                          onChange={(date) => {
                            setTxStartDate(date);
                            pushTxCountValueOptionToSelectedOptions(toISODate(date), txNextOption?.name);
                          }}
                          value={new Date(data?.options[1]?.value) || ''}
                          disabled={!edit}
                        />
                      </div>
                    );
                  }
                }
                if (option.name === 'is-than') {
                  return (
                    <div className="w-100" key={uniqueId('transaction_count_3')}>
                      <Select
                        key={uniqueId('tx_select')}
                        options={option.options}
                        value={txIsMoreLess
                          ? {
                            value: txIsMoreLess[0]?.value
                              ? formatString(txIsMoreLess[0]?.value)
                              : formatString(txIsMoreLess),
                            label: txIsMoreLess[0]?.value
                              ? formatString(txIsMoreLess[0]?.value)
                              : formatString(txIsMoreLess),
                          }
                          : undefined}
                        onChange={(val) => {
                          setTxIsMoreLess(val.value);
                          pushTxCountValueOptionToSelectedOptions(val.value, option.name);
                        }}
                        isSearchable={false}
                        isDisabled={!edit}
                        maxMenuHeight={194}
                      />
                    </div>
                  );
                }
                if (option.type === 'input') {
                  return (
                    <input
                      key={uniqueId('tx_input')}
                      onWheel={(e) => e.target.blur()}
                      min="0"
                      type="number"
                      placeholder="Input a number"
                      onKeyPress={preventInvalidSymbols}
                      value={Array.isArray(txInputValue) ? txInputValue[0]?.value : undefined}
                      className={`form-control ${styles.select} w-100`}
                      onChange={(e) => {
                        const ifValidValue = validateInput(e.target.value);
                        if (!ifValidValue) {
                          return;
                        }
                        setTxInputValue(e.target.value);
                        debounceValue(e.target.value, option.name);
                      }}
                      disabled={!edit}
                    />
                  );
                }
                if (option.name === 'chain') {
                  return (
                    <div className="w-100" key={uniqueId('transaction_count_4')}>
                      <Select
                        key={uniqueId('tx_select')}
                        options={option.options}
                        styles={placeholderStyle}
                        value={txChainOption
                          ? {
                            value: txChainOption[0]?.value
                              ? formatString(txChainOption[0]?.value)
                              : formatString(txChainOption),
                            label: txChainOption[0]?.value
                              ? formatString(txChainOption[0]?.value)
                              : formatString(txChainOption),
                          }
                          : {
                            value: formatString(option.options[0]),
                            label: formatString(option.options[0]),
                          }}
                        onChange={(val) => {
                          setTxChainOption(val.value);
                          pushTxCountValueOptionToSelectedOptions(val.value, option.name);
                        }}
                        isSearchable={false}
                        isDisabled={!edit}
                        maxMenuHeight={194}
                      />
                    </div>
                  );
                }
                return null;
              })}
          </>
        );
      }
      return null;
    },
    [
      data,
      txCountData,
      edit,
      debounceValue,
      txCountFirstValue,
      txNextOption,
      txTrailingPeriod,
      txStartDate,
      pushTxCountValueOptionToSelectedOptions,
      txIsMoreLess,
      txInputValue,
      txChainOption,
      setTxChainOption,
      setTxCountFirstValue,
      setTxInputValue,
      setTxStartDate,
      setTxIsMoreLess,
      setTxTrailingPeriod,
    ],
  );
};

export default TxCountControl;
