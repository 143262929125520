import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/blockchain-service';

export const blockchainApi = createApi({
  reducerPath: 'blockchainAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData?.body?.id_token) {
        headers.set('Authorization', `Bearer ${authData.body.id_token}`);
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Blockchain'],
  endpoints: (build) => ({
    checkIsERC1155: build.query({
      query: (arg) => ({
        url: `${BASE_API}/is-erc1155/?blockchain=${arg.blockchain}&contract_address=${arg.contractAddress}`,
        method: 'GET',
      }),
      providesTags: () => ['Blockchain'],
    }),
    getNFTBalance: build.query({
      query: (arg) => ({
        url:
          `${BASE_API}/get-nft-balance/?blockchain=${arg.blockchain}` +
          `&wallet_address=${arg.walletAddress}` +
          `&contract_address=${arg.contractAddress}`,
        method: 'GET',
      }),
      providesTags: () => ['Blockchain'],
    }),
    getTeamTokenBalance: build.query({
      query: (arg) => ({
        url:
          `${BASE_API}/get-team-token-balance/?blockchain=${arg.blockchain}` +
          `&user_contract_address=${arg.userContractAddress}` +
          `&team_contract_address=${arg.teamContractAddress}`,
        method: 'GET',
      }),
      providesTags: () => ['Blockchain'],
    }),
  }),
});
