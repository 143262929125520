import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Handle, Position } from 'reactflow';
import { handle, handleInput, right } from '../../../../../utils/flows';
import Tooltip from '../../../../../components/ui/Tooltip';
import Wait from '../../../../../assets/icons/flows/wait.svg';
import Alert from '../../../../../assets/icons/flows/alert.svg';
import Delete from '../../../../../assets/icons/flows/delete.svg';
import {
  clearActiveSettings,
  deleteSettings, selectConfigsList,
  selectErrors, selectFlowStatus,
  setActiveSettings,
} from '../../../../../store/reducers/flows';
import Edit from '../../../../../assets/icons/flows/edit_setting.svg';
import styles from './WaitForNode.module.scss';

const WaitForNode = ({ ...props }) => {
  const [data, setData] = useState();
  const [onNodeHover, setOnNodeHover] = useState(false);
  const [completed, setCompleted] = useState(false);
  const dispatch = useDispatch();

  const errors = useSelector(selectErrors);
  const configList = useSelector(selectConfigsList);
  const status = useSelector(selectFlowStatus);

  const filteredErrors = () => errors.length && errors.filter((elem) => elem.node_id === props.id);

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(props.id);
      if (index >= 0) {
        setData(configList[index]);
      }
    }
  }, [configList, props.id]);

  useEffect(() => {
    if (data && data?.time && data?.value && data.name) {
      setCompleted(true);
    } else {
      setCompleted(false);
    }
  }, [data]);

  return (
    <>
      <div
        className={`
        ${styles.wrapper}
        ${status === 'running' || status === 'scheduled' || status === 'stopped' ? 'cursor-pointer' : ''}
        d-flex justify-content-center align-items-center`}
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
        role="presentation"
        onClick={() => {
          if (status === 'running' || status === 'scheduled' || status === 'stopped') {
            dispatch(setActiveSettings({ type: 'waitForNode', node_id: props.id }));
          }
        }}
      >
        <Wait className={styles.wait} />
        {!completed || filteredErrors().length
          ? (
            <>
              <div
                className={styles.alert}
                data-for={`${props.id}_error`}
                data-tip
              >
                <Alert />
              </div>
              {filteredErrors().length
                ? (
                  <Tooltip
                    id={`${props.id}_error`}
                    info={`${filteredErrors()[0].type}: ${filteredErrors()[0].detail}`}
                  />
                )
                : null}
            </>
          )
          : (
            <div className={`${styles.timer} d-flex justify-content-center align-items-center position-absolute`}>
              {`${data?.value} ${Number(data?.value) === 1 ? data?.time.slice(0, -1) : data?.time}`}
            </div>
          )}
        <div className={onNodeHover ? styles.delete : styles.delete_hide}>
          <div className="d-flex gap-3">
            {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
              ? (
                <Delete onClick={() => {
                  props.data.onRemove(props.id);
                  dispatch(deleteSettings(props.id));
                  dispatch(clearActiveSettings());
                }}
                />
              )
              : null}
            <Edit onClick={() => dispatch(setActiveSettings({ type: 'waitForNode', node_id: props.id }))} />
          </div>
        </div>
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ ...handleInput, left: '-10px' }}
        id="waitForInput"
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ ...handle, ...right }}
        id="waitForOutput"
      />
    </>
  );
};

export default WaitForNode;
