import React, { useCallback } from 'react';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Delete from '../../../../../../assets/icons/close.svg';
import { showErrorMessage } from '../../../../../../components/base/Notifications';
import { IconNearby } from '../../../../../../components/base/SelectLabels';
import { debounce } from '../../../../../../utils/debounce';
import { preventSearch } from '../../../../../../utils/search/preventSearch';
import { onPasteFloat } from '../../../../../../utils/segments';
import {
  excludedBlockchainsForFlows,
  isWalletAddress,
} from '../../../../../../utils/supportedBlockchains';
import McapLabel from '../../../Components/McapLabel';
import styles from '../TransactionNodeSettings.module.scss';

const inputStyles = {
  valueContainer: (style) => ({
    ...style,
    padding: '0 10px',
    minHeight: '46px',
  }),
  singleValue: (style) => ({
    ...style,
    padding: 0,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    opacity: 1,
  }),
  placeholder: (style) => ({
    ...style,
    paddingLeft: 1,
    fontSize: '14px',
  }),
  option: (style) => ({
    ...style,
    padding: '12px',
    boxShadow: '1px',
    border: '1px solid #F1F4F8',
    fontSize: '14px',
  }),
  menuList: (style) => ({
    ...style,
    paddingTop: 0,
    paddingBottom: 0,
  }),
};

const AssetSelect = ({
  assetType,
  disableChangeStatus,
  isLoading,
  blockChainOptions,
  typeOptions,
  top5nfts,
  top5tokens,
  watch,
  setValue,
  getTokensData,
  getNFTsData,
  onDelete,
  index,
}) => {
  const selectedItem = watch(`assets.${index}`);
  const selectedBlockchain = watch(`assets.${index}.blockchain`);
  const blockchainValue = blockChainOptions.find(
    (option) => option.value === selectedBlockchain
  );
  const getCoinLabel = useCallback((val) => <IconNearby val={val} />, []);
  const loadTokensData = useCallback(
    debounce((val) => getTokensData(val), 1000),
    [getTokensData]
  );
  const loadNFTsData = useCallback(
    debounce((val) => getNFTsData(val), 1000),
    [getNFTsData]
  );

  const getMcapLabel = (val) => (
    <McapLabel val={val} type={val.blockchain ? assetType.toLowerCase() : ''} />
  );
  return (
    <div className={`position-relative mb-3 ${styles.selected_asset}`}>
      <div className={`mb-1 ${styles.selected_asset_title}`}>
        <span>
          Select&nbsp;
          {assetType === 'Token' ? 'Token' : 'NFT'}
        </span>
        <Delete
          className="cursor-pointer"
          onClick={() => !disableChangeStatus && onDelete()}
        />
      </div>
      {assetType ? (
        <AsyncSelect
          className="w-100"
          styles={inputStyles}
          maxMenuHeight={300}
          loadOptions={assetType === 'Token' ? loadTokensData : loadNFTsData}
          selectProps={selectedItem}
          getOptionLabel={(val) => getMcapLabel(val)}
          onKeyDown={(e) => {
            preventSearch(e);
          }}
          onChange={(e) => {
            setValue(`assets.${index}`, e);
            setValue(`assets.${index}.min_amount`, null);
            setValue(`assets.${index}.max_amount`, null);
            if (e.value) {
              setValue(`assets.${index}.contract_address`, e.value);
            } else {
              setValue(`assets.${index}.contract_address`, null);
            }
            if (e.contract_type) {
              setValue(
                `assets.${index}.contract_type`,
                e.contract_type.toLowerCase()
              );
            } else {
              setValue(`assets.${index}.contract_type`, null);
            }
            if (e.blockchain) {
              setValue(`assets.${index}.blockchain`, e.blockchain);
            } else {
              setValue(`assets.${index}.blockchain`, null);
            }
          }}
          isLoading={isLoading}
          placeholder={`${assetType} name or contract address`}
          value={selectedItem}
          defaultOptions={
            assetType === 'Token'
              ? top5tokens
                  ?.filter(
                    (elem) =>
                      !excludedBlockchainsForFlows.includes(elem.blockchain)
                  )
                  .map((elem) => ({
                    ...elem,
                    value: elem.contract_address,
                    label: elem.name,
                  })) || []
              : top5nfts
                  ?.slice(0, 5)
                  .filter(
                    (elem) =>
                      !excludedBlockchainsForFlows.includes(elem.blockchain)
                  )
                  .map((elem) => ({
                    ...elem,
                    value: elem.contract_address,
                    label: elem.name,
                  })) || []
          }
          isDisabled={disableChangeStatus}
        />
      ) : null}
      {selectedItem &&
        isWalletAddress(selectedItem.value) &&
        !selectedItem?.name &&
        typeof selectedItem.market_cap !== 'number' &&
        typeof selectedItem.usd_market_cap !== 'number' && (
          <div className="mt-3 mb-1">
            <div className={`${styles.title_input} mb-1`}>
              Select blockchain
            </div>
            <Select
              placeholder="Select a blockchain..."
              className="w-100"
              styles={inputStyles}
              value={blockchainValue || null}
              onKeyDown={(e) => {
                preventSearch(e);
              }}
              onChange={(val) => {
                setValue(`assets.${index}.blockchain`, val.value);
              }}
              getOptionLabel={(val) => getCoinLabel(val)}
              name="blockchains"
              options={blockChainOptions}
              isDisabled={disableChangeStatus}
            />
          </div>
        )}
      {selectedItem &&
        isWalletAddress(selectedItem.value) &&
        !selectedItem?.name &&
        assetType === 'NFT' &&
        typeof selectedItem.market_cap !== 'number' && (
          <div className="mt-3 mb-3">
            <div className={`${styles.title_input}`}>Select type</div>
            <Select
              placeholder="Select a contract type..."
              styles={inputStyles}
              value={typeOptions.find(
                (elem) => elem.value === watch(`assets.${index}.contract_type`)
              )}
              onKeyDown={(e) => {
                preventSearch(e);
              }}
              onChange={(elem) => {
                setValue(`assets.${index}.contract_type`, elem?.value);
              }}
              name="types"
              options={typeOptions}
              isDisabled={disableChangeStatus}
            />
          </div>
        )}
      {selectedItem && assetType === 'Token' ? (
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-6 p-0 pe-1">
              <div
                className={`${styles.title_input} d-flex w-100 justify-content-between mt-3 mb-1`}
              >
                <span>Min</span>
              </div>
              <div
                className={
                  disableChangeStatus
                    ? styles.link_area_disabled
                    : styles.link_area
                }
              >
                <NumericFormat
                  placeholder="Input value"
                  className="w-100 p-2"
                  onPaste={(e) => {
                    onPasteFloat(
                      e,
                      e.clipboardData.getData('text/plain').replace(/[,]/g, '')
                    );
                  }}
                  valueIsNumericString
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  onBlur={(e) => e.target.blur()}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    if (formattedValue.replace(/[,.]/g, '').length >= 18) {
                      showErrorMessage(
                        '18 numbers are already entered. You are not allowed to enter more.'
                      );
                      return;
                    }
                    if (formattedValue.replace(/[,.]/g, '').length <= 18) {
                      setValue(
                        `assets.${index}.min_amount`,
                        value === '' ? null : value
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.target.value.replace(/[,.]/g, '').length >= 18 &&
                      e.keyCode !== 8 &&
                      e.keyCode !== 37 &&
                      e.keyCode !== 39 &&
                      e.keyCode !== 17 &&
                      e.keyCode !== 67
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={watch(`assets.${index}.min_amount`) || null}
                  disabled={disableChangeStatus}
                />
              </div>
            </div>
            <div className="col-6 p-0 ps-1">
              <div
                className={`${styles.title_input} d-flex w-100 justify-content-between mt-3 mb-1`}
              >
                <span>Max</span>
              </div>
              <div
                className={
                  disableChangeStatus
                    ? styles.link_area_disabled
                    : styles.link_area
                }
              >
                <NumericFormat
                  placeholder="Input value"
                  className="w-100 p-2"
                  onPaste={(e) => {
                    onPasteFloat(
                      e,
                      e.clipboardData.getData('text/plain').replace(/[,]/g, '')
                    );
                  }}
                  max={5}
                  valueIsNumericString
                  thousandSeparator=","
                  decimalSeparator="."
                  allowNegative={false}
                  onBlur={(e) => e.target.blur()}
                  onValueChange={(values) => {
                    const { formattedValue, value } = values;
                    if (formattedValue.replace(/[,.]/g, '').length >= 18) {
                      showErrorMessage(
                        '18 numbers are already entered. You are not allowed to enter more.'
                      );
                      return;
                    }
                    if (formattedValue.replace(/[,.]/g, '').length <= 18) {
                      setValue(
                        `assets.${index}.max_amount`,
                        value === '' ? null : value
                      );
                    }
                  }}
                  onKeyDown={(e) => {
                    if (
                      e.target.value.replace(/[,.]/g, '').length >= 18 &&
                      e.keyCode !== 8 &&
                      e.keyCode !== 37 &&
                      e.keyCode !== 39 &&
                      e.keyCode !== 17 &&
                      e.keyCode !== 67
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={watch(`assets.${index}.max_amount`) || null}
                  disabled={disableChangeStatus}
                />
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AssetSelect;
