import React, { useState } from 'react';
import Arrow from '../../../assets/icons/select_arrow_black.svg';
import styles from './Modal.module.scss';

const ModalCollapsable = ({ title, children, opened = false }) => {
  const [showContent, setShowContent] = useState(opened);

  return (
    <>
      <button
        type="button"
        className={styles.collapsable}
        onClick={() => setShowContent(!showContent)}
      >
        {title}
        <div
          className={`${styles.arrow} ${showContent ? styles.rotate : styles.back_rotate}`}
          role="presentation"
        >
          <Arrow />
        </div>
      </button>

      {showContent && children}
    </>
  );
};

export default ModalCollapsable;
