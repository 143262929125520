import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  website: null,
  form: null,
  conditions: {
    data: {},
    selectedValues: {},
  },
  advancedSettings: {
    timesDisplay: 3,
    timeBetweenDisplay: {
      value: 1,
      unit: 'days',
    },
    hideAfterSubmission: true,
    hideIfButtonClicked: null,
    closeIfButtonClicked: null,
    abTesting: {
      eligible: 100,
      othersForm: {
        value: null,
        label: 'Nothing',
      },
    },
  },
};

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState,
  reducers: {
    reset: () => initialState,
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setWebsite: (state, action) => {
      state.website = action.payload;
    },
    setForm: (state, action) => {
      state.form = action.payload;
    },
    addCondition: (state, action) => {
      const { conditionId, label, defaultValue } = action.payload;
      if (!state.conditions.selectedValues[conditionId]) {
        state.conditions.selectedValues[conditionId] = {};
      }
      state.conditions.selectedValues[conditionId][label] = defaultValue;
    },
    removeCondition: (state, action) => {
      const { conditionId } = action.payload;
      delete state.conditions.selectedValues[conditionId];
    },
    setConditionData: (state, action) => {
      const { conditionId, data } = action.payload;
      state.conditions.data[conditionId] = {
        ...state.conditions.data[conditionId],
        ...data,
      };
    },
    setSelectedValue: (state, action) => {
      const { conditionId, label, value } = action.payload;
      if (!state.conditions.selectedValues[conditionId]) {
        state.conditions.selectedValues[conditionId] = {};
      }
      state.conditions.selectedValues[conditionId][label] = value;
    },
    setTimesDisplay: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        timesDisplay: action.payload,
      };
    },
    setTimeBetweenDisplayValue: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        timeBetweenDisplay: {
          ...state.advancedSettings.timeBetweenDisplay,
          value: action.payload,
        },
      };
    },
    setTimeBetweenDisplayUnit: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        timeBetweenDisplay: {
          ...state.advancedSettings.timeBetweenDisplay,
          unit: action.payload,
        },
      };
    },
    setHideAfterSubmission: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        hideAfterSubmission: action.payload,
      };
    },
    setHideIfButtonClicked: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        hideIfButtonClicked: action.payload,
      };
    },
    setCloseIfButtonClicked: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        closeIfButtonClicked: action.payload,
      };
    },
    setAbTestingEligible: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        abTesting: {
          ...state.advancedSettings.abTesting,
          eligible: action.payload,
        },
      };
    },
    setAbTestingOthersForm: (state, action) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        abTesting: {
          ...state.advancedSettings.abTesting,
          othersForm: action.payload,
        },
      };
    },
    resetButtons: (state) => {
      state.advancedSettings = {
        ...state.advancedSettings,
        hideIfButtonClicked: initialState.advancedSettings.hideIfButtonClicked,
        closeIfButtonClicked:
          initialState.advancedSettings.closeIfButtonClicked,
      };
    },
    removeSelectedValue: (state, action) => {
      const { conditionId, label } = action.payload;
      if (
        state.conditions.selectedValues[conditionId] &&
        state.conditions.selectedValues[conditionId][label]
      ) {
        delete state.conditions.selectedValues[conditionId][label];
      }
      if (
        Object.keys(state.conditions.selectedValues[conditionId]).length === 0
      ) {
        delete state.conditions.selectedValues[conditionId];
      }
    },
  },
});

export const {
  reset,
  setTitle,
  setWebsite,
  setForm,
  addCondition,
  removeCondition,
  setConditionData,
  setSelectedValue,
  setTimesDisplay,
  setTimeBetweenDisplayValue,
  setTimeBetweenDisplayUnit,
  setHideAfterSubmission,
  setHideIfButtonClicked,
  setCloseIfButtonClicked,
  setAbTestingEligible,
  setAbTestingOthersForm,
  resetButtons,
  removeSelectedValue,
} = campaignSlice.actions;

export const getCampaign = (state) => state.campaignReducer;
export const getTitle = (state) => state.campaignReducer.title;
export const getWebsite = (state) => state.campaignReducer.website;
export const getForm = (state) => state.campaignReducer.form;
export const getSelectedConditions = (state) =>
  Object.keys(state.campaignReducer.conditions.selectedValues);
export const getConditionsData = (state) =>
  state.campaignReducer.conditions.data;
export const getConditionsSelectedValues = (state) =>
  state.campaignReducer.conditions.selectedValues;
export const getTimesDisplay = (state) =>
  state.campaignReducer.advancedSettings.timesDisplay;
export const getTimeBetweenDisplayValue = (state) =>
  state.campaignReducer.advancedSettings.timeBetweenDisplay.value;
export const getTimeBetweenDisplayUnit = (state) =>
  state.campaignReducer.advancedSettings.timeBetweenDisplay.unit;
export const getHideAfterSubmission = (state) =>
  state.campaignReducer.advancedSettings.hideAfterSubmission;
export const getHideIfButtonClicked = (state) =>
  state.campaignReducer.advancedSettings.hideIfButtonClicked;
export const getCloseIfButtonClicked = (state) =>
  state.campaignReducer.advancedSettings.closeIfButtonClicked;
export const getAbTesting = (state) =>
  state.campaignReducer.advancedSettings.abTesting;

export default campaignSlice.reducer;
