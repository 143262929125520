import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useForm } from 'react-hook-form';
import { pickBy, identity } from 'lodash';
import { useSelector } from 'react-redux';

import { getUser } from '../../store/reducers/user';
import styles from './MyTeam.module.scss';
import AddModal from './Modals/AddModal';
import EditModal from './Modals/EditModal';
import Loader from '../../components/base/Loader';
import { userApi } from '../../api/user';
import TeamTable from './TeamTable';
import { showErrorMessage, showSuccessMessage } from '../../components/base/Notifications';

const MyTeam = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const { logout } = useAuth0();
  const userInfo = useSelector(getUser);
  const navigate = useNavigate();
  const [teamLogoError, setTeamLogoError] = useState(false);
  const [editId, setEditId] = useState(0);

  useEffect(() => {
    if (userInfo.category === 'team_member') {
      navigate('/dashboard');
    }
  }, [userInfo, navigate]);

  const {
    register: registerEdit,
    handleSubmit: handleSumbitEdit,
    setError: setEditError,
    setValue: setEditValue,
    formState: editFormState,
    clearErrors: clearEditErrors,
    reset,
  } = useForm();

  const {
    register: registerAdd,
    handleSubmit: handleSumbitAdd,
    setError: setAddError,
    setValue: setRegisterValue,
    formState: addFormState,
    clearErrors: clearAddErrors,
  } = useForm();

  const {
    data: teamInfo,
    isLoading: isTeamInfoLoading,
    refetch: teamInfoRefetch,
  } = userApi.useGetTeamInfoQuery(null, { skip: userInfo.category === 'team_member' });

  const [changeMember] = userApi.usePatchTeamMemberMutation();
  const [inviteMember, result] = userApi.useInviteNewTeamMemberMutation();

  const closeAddModal = () => {
    setShowAddModal(false);
    setRegisterValue('first_name', '');
    setRegisterValue('last_name', '');
    setRegisterValue('email', '');
    setRegisterValue('category', '');
    clearAddErrors();
  };

  const closeEditModal = () => {
    setShowEditModal(false);
    clearEditErrors();
    setIsTouched(false);
    reset();
  };

  const onInviteMember = async (data) => {
    if (!data.category) {
      setAddError('category', { type: 'custom', message: 'This is a required field' });
      return;
    }
    const formattedData = pickBy(data, identity);
    inviteMember({
      user: { team: teamInfo.id, ...formattedData },
    });
  };

  useEffect(() => {
    if (result.isSuccess) {
      showSuccessMessage('An invitation has been successfully sent');
      closeAddModal();
      teamInfoRefetch();
      result.reset();
    }
    if (result.isError) {
      if (result.error.status === 400) {
        showErrorMessage(result.error.data?.user?.email[0] || 'Something went wrong');
      } else {
        showErrorMessage(Object.keys(result.error.data).length !== 0 && 'Something went wrong');
      }
      result.reset();
    }
  }, [closeAddModal, result, teamInfoRefetch]);

  const onChangeMember = async (data) => {
    const teamAdminsIds = teamInfo.admins.map((teamAdmin) => teamAdmin.id);
    if (data.category === 'team_member' && teamAdminsIds.includes(editId) && teamInfo.admins?.length < 2) {
      setEditError(
        'category',
        { type: 'custom', message: 'You are the only admin of this team. Add another admin and try again.' },
      );
      return;
    }
    try {
      const formattedData = pickBy(data, identity);
      await changeMember({ id: editId, ...formattedData });
      closeEditModal();
      if (userInfo.id === editId && data.category === 'team_member') {
        logout({ returnTo: window.location.origin });
      } else {
        teamInfoRefetch();
      }
    } catch (err) {
      showErrorMessage(err.message);
    }
  };

  return useMemo(() => {
    if (isTeamInfoLoading) {
      return <Loader />;
    }

    if (teamInfo?.admins && !teamInfo.error) {
      return (
        <div className="asset-section">
          <div className="asset-container">
            <div className="no-padding">
              <div className="title-gap">
                <div className={styles.title}>My Team</div>
              </div>
              <div className="divider" />
            </div>
            <div
              className={`d-flex align-items-center justify-content-between ${styles.row}`}
            >
              <div
                className={`d-flex align-items-center ${styles.teamNameContainer}`}
              >
                {
                  teamInfo.logo && !teamLogoError
                    ? (
                      <img
                        src={
                          teamInfo.logo
                        }
                        onError={() => setTeamLogoError(true)}
                        alt="thumb"
                        height="75"
                        width="75"
                      />
                    )
                    : <i style={{ fontSize: '30px' }} className="bi bi-people-fill" />
                }
                <div className={styles.teamName}>{teamInfo.title}</div>
              </div>
              <button
                type="button"
                onClick={() => {
                  setShowAddModal(true);
                }}
                className={`btn btn-primary ${styles.button}`}
              >
                <span
                  className={`d-flex align-items-center justify-content-center ${styles.buttonText}`}
                >
                  Add new member
                </span>
              </button>
            </div>
            <TeamTable
              setEditId={setEditId}
              myTeamData={teamInfo && [...teamInfo.admins, ...teamInfo.members]}
              setShowEditModal={setShowEditModal}
              setEditValue={setEditValue}
              isLoading={isTeamInfoLoading}
            />
          </div>
          {showAddModal && (
            <AddModal
              setShowModal={closeAddModal}
              handleSumbitAdd={handleSumbitAdd}
              registerAdd={registerAdd}
              onInviteMember={onInviteMember}
              addFormState={addFormState}
            />
          )}
          {showEditModal && (
            <EditModal
              userInfo={userInfo}
              handleSumbitEdit={handleSumbitEdit}
              registerEdit={registerEdit}
              setShowModal={closeEditModal}
              onChangeMember={onChangeMember}
              editFormState={editFormState}
              editId={editId}
              setIsTouched={setIsTouched}
              isTouched={isTouched}
            />
          )}
        </div>
      );
    }

    return <Loader />;
  }, [
    teamInfo,
    isTeamInfoLoading,
    showAddModal,
    showEditModal,
    editFormState,
    addFormState,
  ]);
};

export default MyTeam;
