import React, { useState, useEffect, useMemo } from 'react';
import { Handle, Position } from 'reactflow';
import { useDispatch, useSelector } from 'react-redux';
import styles from '../ChannelsNode.module.scss';
import AirDrop from '../../../../../assets/icons/flows/airdrop.svg';
import Delete from '../../../../../assets/icons/flows/delete.svg';
import Edit from '../../../../../assets/icons/flows/edit.svg';
import Divider from '../../../../../assets/icons/divider.svg';
import Alert from '../../../../../assets/icons/flows/alert.svg';
import {
  selectActiveSettings,
  clearActiveSettings,
  deleteSettings,
  selectConfigsList,
  selectErrors, selectFlowStatus,
  setActiveSettings,
} from '../../../../../store/reducers/flows';
import { handle, handleInput } from '../../../../../utils/flows';
import { truncateAddress } from '../../../../../utils/web3/truncateAddress';

import { convertNumber, convertSciTruncSmallCommaBig } from '../../../../../tools/NumberConverterTool';
import Tooltip from '../../../../../components/ui/Tooltip';
import { capitalizeFirstLetter } from '../../../../../utils/capitalizeFirstLetter';

const AirDropNode = ({ ...props }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState();
  const [completed, setCompleted] = useState(false);
  const [onNodeHover, setOnNodeHover] = useState(false);
  const activeSettings = useSelector(selectActiveSettings);
  const configList = useSelector(selectConfigsList);
  const errors = useSelector(selectErrors);
  const status = useSelector(selectFlowStatus);

  const filteredErrors = () => errors.length && errors.filter((elem) => elem.node_id === props.id);

  const checkForValue = (param) => {
    if (param.value) {
      return true;
    }
    if (['address', 'address[]'].includes(param.type) && param.selectValue.value) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (configList.length) {
      const index = configList.map((object) => object.node_id).indexOf(props.id);
      if (index >= 0) {
        setData(configList[index]);
      }
    }
  }, [configList, props.id]);

  useEffect(() => {
    if (data?.asset_type === 'custom') {
      const shouldSetComplete = data?.data?.selected_parameters?.length
      === data?.data?.selected_function?.inputs?.length
    && data?.data?.selected_parameters?.every((param) => checkForValue(param))
    && data?.data?.selected_parameters?.some((param) => param.selectValue.value !== 'custom')
    && data.blockchain
    && data?.data?.contract_address
    && data?.data?.selected_parameters?.filter((param) => (param?.selectValue?.value === 'incoming'
      && ['address', 'address[]'].includes(param?.type))).length < 2;

      if (shouldSetComplete) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    }

    if (data?.asset_type === 'nft') {
      if (data
        && data.nft_amount
        && data.nft_address
        && data.source_of_nfts
        && (data.is_erc1155 !== null && data.is_erc1155 !== undefined)) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    }

    if (data?.asset_type === 'tokens') {
      if (data && data.token_amount && data.token_address) {
        setCompleted(true);
      } else {
        setCompleted(false);
      }
    }
  }, [data]);

  const footerToRender = useMemo(() => {
    if (data?.asset_type === 'tokens' && (data && (data.token_amount || data.token_amount === 0))) {
      return (
        <div className="w-100">
          <div className={`${styles.divider} d-flex`}>
            <Divider />
          </div>
          <div className={`${styles.info} d-flex justify-content-around`}>
            <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
              <div>
                {convertSciTruncSmallCommaBig(data.token_amount)}
              </div>
              <div>
                Amount per wallet
              </div>
            </div>
            <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
              <div className={`${styles.asset} text-truncate`}>
                {data.asset_name || truncateAddress(data.token_address) || '?'}
              </div>
              <div>
                Token
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (data?.asset_type === 'nft' && (data && (data.nft_amount || data.nft_amount === 0))) {
      return (
        <div className="w-100">
          <div className={`${styles.divider} d-flex`}>
            <Divider />
          </div>
          <div className={`${styles.info} d-flex justify-content-around`}>
            <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
              <div>
                {convertNumber(data.nft_amount)}
              </div>
              <div>
                Amount per wallet
              </div>
            </div>
            <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
              <div className={`${styles.asset} text-truncate`}>
                {data.asset_name || truncateAddress(data.nft_address) || '?'}
              </div>
              <div>
                NFT
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (data?.asset_type === 'custom'
    && (data && (data?.blockchain || data?.data?.contract_address || data?.data?.selected_function))) {
      return (
        <div>
          <div className={`${styles.divider} d-flex`}>
            <Divider />
          </div>
          <div className={`${styles.info} d-flex`}>
            <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
              <div>
                { capitalizeFirstLetter(data.blockchain) || '?'}
              </div>
              <div>
                Chain
              </div>
            </div>
            <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
              <div className={`${styles.asset} text-truncate`}>
                {truncateAddress(data.data.contract_address) || '?'}
              </div>
              <div>
                Contract
              </div>
            </div>
            <div className={`${styles.info_col} d-flex flex-column align-items-center justify-content-center`}>
              <div>
                {data.data.selected_function?.name || '?'}
              </div>
              <div>
                Function
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }, [data]);

  const showLargerSize = useMemo(() => {
    if (data?.asset_type === 'tokens' && (data?.token_amount || data?.token_amount === 0)) {
      return true;
    }
    if (data?.asset_type === 'nft' && (data?.nft_amount || data?.nft_amount === 0)) {
      return true;
    }
    if (data?.asset_type === 'custom' && data?.blockchain) {
      return true;
    }
    return false;
  }, [data]);

  return (
    <>
      <div
        className={`
        ${styles.wrapper}
        ${data && showLargerSize ? styles.size120 : ''}
        ${status === 'running' || status === 'scheduled' || status === 'stopped' ? 'cursor-pointer' : ''}
        d-flex
        flex-column
        justify-content-center
        `}
        onMouseEnter={() => setOnNodeHover(true)}
        onMouseLeave={() => setOnNodeHover(false)}
        role="presentation"
        onClick={() => {
          if (status === 'running' || status === 'scheduled' || status === 'stopped') {
            dispatch(setActiveSettings({ type: 'airdropNode', node_id: props.id }));
          }
        }}
      >
        <div className={`${styles.title} d-flex align-items-center justify-content-between gap-2 w-100`}>
          <div className="d-flex align-items-center gap-2">
            {status !== 'running' && status !== 'stopped' && status !== 'scheduled'
              ? (
                <>
                  {!completed || filteredErrors().length
                    ? (
                      <>
                        <div
                          className={styles.alert}
                          data-for={`${props.id}_error`}
                          data-tip
                        >
                          <Alert />
                        </div>
                        {filteredErrors().length
                          ? (
                            <Tooltip
                              id={`${props.id}_error`}
                              info={`${filteredErrors()[0].detail}`}
                              position="right"
                            />
                          )
                          : null}
                      </>
                    )
                    : null}
                  <div className={onNodeHover ? styles.delete : styles.delete_hide}>
                    <Delete onClick={() => {
                      props.data.onRemove(props.id);
                      dispatch(deleteSettings(props.id));
                      dispatch(clearActiveSettings());
                    }}
                    />
                  </div>
                </>
              )
              : null}
            <AirDrop />
            <div className={styles.description}>
              {(data && data.name) || 'Airdrop'}
            </div>
          </div>
          <div className="cursor-pointer">
            <Edit onClick={() => {
              if (!activeSettings || activeSettings.node_id !== props.id) {
                dispatch(setActiveSettings({ type: 'airdropNode', node_id: props.id }));
              }
            }}
            />
          </div>
        </div>
        {footerToRender}
      </div>
      <Handle
        type="target"
        position={Position.Left}
        style={{ ...handleInput, left: '-10px' }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ ...handle, right: '-16px' }}
        id="airdropNode_output"
      />
    </>
  );
};

export default AirDropNode;
