import { debounce as _debounce } from 'lodash';
import { useMemo, useState } from 'react';

const useDebounce = (obj = null, wait = 1000) => {
  const [state, setState] = useState(obj);

  const debouncedFunction = useMemo(
    () => _debounce((_prop) => setState(_prop), wait),
    [wait]
  );

  const debounce = debouncedFunction;

  const setDebouncedState = (_val) => {
    debounce(_val);
  };

  return [state, setDebouncedState];
};

export default useDebounce;
