import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/flows';

export const flowsApi = createApi({
  reducerPath: 'flowsAPI',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData?.body?.id_token) {
        headers.set('Authorization', `Bearer ${authData.body.id_token}`);
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['Flow'],
  endpoints: (build) => ({
    getFlows: build.query({
      query: (arg) => ({
        url: `${BASE_API}/?limit=${arg.limit}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.ordering ? `&ordering=${arg.ordering}` : ''}${
          arg.search ? `&search=${encodeURIComponent(arg.search)}` : ''
        }${arg.status ? `&status=${arg.status}` : ''}${
          arg.folders ? `&folders=${arg.folders}` : ''
        }${arg.my_items ? `&my_items=${arg.my_items}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Flow'],
    }),
    getFlowById: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Flow'],
    }),
    saveFlow: build.mutation({
      query: (payload) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: payload,
      }),
      providesTags: () => ['Flow'],
    }),
    changeFlow: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/`,
        method: 'PATCH',
        body: arg.payload,
      }),
      providesTags: () => ['Flow'],
    }),
    deleteFlow: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['Flow'],
    }),
    deleteMultipleFlows: build.mutation({
      query: (body) => ({
        url: `${BASE_API}/multiple-delete/`,
        method: 'DELETE',
        body: body.map((i) => ({
          object_id: i.id,
          object_type: i.type,
        })),
      }),
      providesTags: () => ['Flow'],
    }),
    copyFlow: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/copy/${id}/`,
        method: 'GET',
      }),
      providesTags: () => ['Flow'],
    }),
    getFlowStats: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/stats/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
    }),
    stopFlow: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/stop/`,
        method: 'PATCH',
      }),
      providesTags: () => ['Flow'],
    }),
    getTemplates: build.query({
      query: () => ({
        url: `${BASE_API}/sendgrid-templates/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['Flow'],
    }),
    getContractABI: build.query({
      query: (body) => ({
        url: `${BASE_API}/airdrop-blockchain-abi/?blockchain=${body.blockchain}&address=${body.address}`,
        method: 'GET',
      }),
      providesTags: () => ['Flow'],
    }),
    getSendgridTemplate: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/sendgrid-template-variables/?template_id=${arg.value}`,
        method: 'GET',
      }),
      providesTags: () => ['Flow'],
    }),
    checkWalletInFlow: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/wallet-in-flow/${arg.address}`,
        method: 'GET',
      }),
      providesTags: () => ['Flow'],
    }),
    sendWalletInFlow: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/wallet-in-flow/`,
        method: 'POST',
        body: arg.address,
      }),
      providesTags: () => ['Flow'],
    }),
    getCustomDatasetFields: build.query({
      query: () => ({
        url: `${BASE_API}/custom-dataset/fields/`,
        method: 'GET',
      }),
    }),
    getFlowFoldersById: build.query({
      query: ({ id }) => ({
        url: `${BASE_API}/${id}/?folder_only=true`,
        method: 'GET',
      }),
      transformResponse: (response) => response.folders,
      providesTags: () => ['Flow'],
    }),
  }),
});
