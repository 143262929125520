import React from 'react';
import { onPaste, preventInvalidSymbols } from '../../../utils/segments';
import styles from './Modal.module.scss';

export const ModalInputText = ({
  value,
  placeholder,
  onChange,
  maxLength = 50,
  formProps = null,
}) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }

    if (formProps.onChange) {
      formProps.onChange(e);
    }
  };

  return (
    <div className={styles.input_wrapper}>
      <input
        value={value}
        type="text"
        placeholder={placeholder}
        onChange={handleChange}
        maxLength={maxLength}
        {...formProps}
      />
    </div>
  );
};

export const ModalInputNumber = ({
  value,
  placeholder,
  onChange,
  min = 0,
  formProps = null,
}) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className={styles.input_wrapper}>
      <input
        value={value}
        type="number"
        min={min}
        placeholder={placeholder}
        onChange={handleChange}
        {...formProps}
        onPaste={onPaste}
        onKeyPress={(e) => preventInvalidSymbols(e)}
        onWheel={(e) => e.target.blur()}
      />
    </div>
  );
};

export const ModalInputTextArea = ({
  value,
  placeholder,
  onChange,
  rows = 3,
  maxLength = 1000,
  formProps = null,
}) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <textarea
      value={value}
      type="text"
      className={styles.text_area}
      placeholder={placeholder}
      onChange={handleChange}
      rows={rows}
      maxLength={maxLength}
      {...formProps}
      onWheel={(e) => e.target.blur()}
    />
  );
};
