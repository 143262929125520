import { useSelector } from 'react-redux';
import { showErrorMessage } from '../../components/base/Notifications';
import { getConditionsSelectedValues } from '../../store/reducers/campaign';

export const useConditionValues = (id) => {
  const values = useSelector(getConditionsSelectedValues);
  const selectedValues = values?.[id] || null;

  if (!selectedValues) {
    showErrorMessage(
      'Something went wrong. Please, refresh the page and try again.'
    );
    return {};
  }

  return {
    wallet: selectedValues.wallet,
    ofType: selectedValues.of_type_any_of_all_of,
    chains: selectedValues.chains,
    withName: selectedValues.with_name_any_of_all_of,
    names: selectedValues.names,
  };
};
