const findOptionByName = (options, name) =>
  options.find((option) => option.name === name);

const isValueInArray = (value, array) => array.includes(value);

const hideFieldIfOptionIsSelected = (
  data,
  optionname,
  isValueSelected,
  optionName
) => {
  if (data.name === 'Uses CEX') {
    const isCexOption = findOptionByName(data.options, 'is-cex');
    const transactionOption = findOptionByName(data.options, 'transaction');
    const currentOption = findOptionByName(data.options, optionname);

    if (isCexOption && transactionOption && currentOption) {
      const isCexValue = Array.isArray(isCexOption.value)
        ? isCexOption.value[0]?.value
        : null;
      const transactionValue = Array.isArray(transactionOption.value)
        ? transactionOption.value[0]?.value
        : null;

      return (
        (isCexValue === isValueSelected ||
          transactionValue === isValueSelected) &&
        currentOption.name === optionName
      );
    }
  }
  return false;
};

const showFieldIfOptionIsNotSelected = (
  data,
  optionname,
  isValueSelected,
  optionName
) => {
  if (data.name === 'Uses CEX') {
    const isCexOption = findOptionByName(data.options, 'is-cex');
    const transactionOption = findOptionByName(data.options, 'transaction');
    const currentOption = findOptionByName(data.options, optionname);

    if (isCexOption && transactionOption && currentOption) {
      const transactionValue = Array.isArray(transactionOption.value)
        ? transactionOption.value[0]?.value
        : null;

      return (
        !(transactionValue !== isValueSelected && transactionValue !== null) &&
        currentOption.name === optionName
      );
    }
  }
  return false;
};

const cexShowInputWhenTransactionIs = (data, index, transactionValues) =>
  (Array.isArray(data?.options[3].value)
    ? isValueInArray(data.options[3]?.value[0]?.value, transactionValues)
    : false) && data?.options[index].name === 'cex-amount';

export const isUsesCEXWithIsThanCombo = (data) => {
  if (data.name === 'Uses CEX') {
    const isThanComboOption = data.options.find(
      (opt) => opt.name === 'is-than-combo'
    );
    const transactionTypeOption = data.options[3];

    if (isThanComboOption && transactionTypeOption) {
      const isThanComboValue = Array.isArray(isThanComboOption.value)
        ? isThanComboOption.value[0]?.value
        : null;
      const transactionTypeValue = Array.isArray(transactionTypeOption.value)
        ? transactionTypeOption.value[0]?.value
        : null;

      return (
        ['and-first-transaction', 'and-last-transaction'].includes(
          transactionTypeValue
        ) &&
        ['is-before', 'is-after'].includes(isThanComboValue) &&
        Array.isArray(data.options[4]?.value) &&
        (data.options[4].value[0].value === 'is-before' ||
          data.options[4].value[0].value === 'is-after')
      );
    }
  }
  return false;
};

export const cexSelectFieldChain = (data, option) => {
  const hideChainIfSearchCEX = hideFieldIfOptionIsSelected(
    data,
    option.name,
    'search-cex',
    'chain'
  );

  const showComboIfNotTransCount = showFieldIfOptionIsNotSelected(
    data,
    option.name,
    'and-transaction-count',
    'is-than-combo'
  );

  const hideIsThanIfAndFirst = hideFieldIfOptionIsSelected(
    data,
    option.name,
    'and-first-transaction',
    'is-than'
  );

  const hideIsThanIfAndLast = hideFieldIfOptionIsSelected(
    data,
    option.name,
    'and-last-transaction',
    'is-than'
  );

  const hideIsThanIfEver = hideFieldIfOptionIsSelected(
    data,
    option.name,
    'ever',
    'is-than'
  );

  const hideTimeIfTransCount = hideFieldIfOptionIsSelected(
    data,
    option.name,
    'and-transaction-count',
    'transaction-time'
  );

  const hideAgoIfTransCount = hideFieldIfOptionIsSelected(
    data,
    option.name,
    'and-transaction-count',
    'ago'
  );

  const hideAgoIfEver = hideFieldIfOptionIsSelected(
    data,
    option.name,
    'ever',
    'ago'
  );

  const cexAllOf =
    data?.name !== 'Uses CEX' ||
    (Array.isArray(data?.options[0].value)
      ? data.options[0].value[0].value === 'search-cex'
      : false) ||
    ['is-cex', 'chain'].includes(option.name);

  if (
    cexAllOf &&
    !hideChainIfSearchCEX &&
    !showComboIfNotTransCount &&
    !hideIsThanIfAndFirst &&
    !hideIsThanIfAndLast &&
    !hideIsThanIfEver &&
    !hideAgoIfEver &&
    !hideTimeIfTransCount &&
    !hideAgoIfTransCount
  ) {
    return true;
  }
  return false;
};

export const cexInputChain = (data, index) => {
  const transactionValuesThatShowInput = [
    'and-first-transaction',
    'and-last-transaction',
    'and-transaction-count',
  ];
  const showInputIfTheseTrans = cexShowInputWhenTransactionIs(
    data,
    index,
    transactionValuesThatShowInput
  );
  const hideInputIfBool =
    data?.name === 'Uses CEX' &&
    (Array.isArray(data?.options[3].value)
      ? data.options[0].value[0].value === 'search-cex'
      : false) &&
    data?.options[index].name === 'cex-amount';
  const cexInputCombo = isUsesCEXWithIsThanCombo(data);

  if (showInputIfTheseTrans && hideInputIfBool && !cexInputCombo) {
    return true;
  }
  return false;
};
