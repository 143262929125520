import { convertCreatedTxCount } from './index';

export const getNextOption = (firstValue, data) => {
  if (!firstValue || !data) {
    return null;
  }
  if (
    !Array.isArray(firstValue) ? false : !firstValue[0].value || !data.options
  ) {
    return null;
  }
  const convertedData = convertCreatedTxCount(data);
  const { options } = convertedData;
  let result;
  const [[, [trailingPeriodOption, sinceDateOption]]] =
    options[0].options[1].options;
  switch (firstValue[0].value) {
    case 'total':
      result = null;
      break;
    case 'trailing-period':
      result = trailingPeriodOption;
      break;
    case 'since-date':
      result = sinceDateOption;
      break;
  }
  return result;
};

export const parseValues = (data) => {
  if (!data || data?.name !== 'transaction-count') {
    return null;
  }
  const convertedData = convertCreatedTxCount(data);
  const { options } = convertedData;

  const result = {
    data: convertedData,
    firstValue: options[0].options[0].value,
  };
  if (!options[0].options[0].value) {
    return result;
  }

  const isTrailingPeriod =
    (Array.isArray(data.options[0]?.value)
      ? data.options[0]?.value[0]?.value === 'trailing-period'
      : false) ||
    (Array.isArray(data.options[0]?.options[0]?.value)
      ? data.options[0]?.options[0]?.value[0]?.value === 'trailing-period'
      : false);

  const isSinceDate =
    (Array.isArray(data.options[0]?.value)
      ? data.options[0]?.value[0]?.value === 'since-date'
      : false) ||
    (Array.isArray(data.options[0]?.options[0]?.value)
      ? data.options[0]?.options[0]?.value[0]?.value === 'since-date'
      : false);

  const isTotal =
    (Array.isArray(data.options[0]?.value)
      ? data.options[0]?.value[0]?.value === 'total'
      : false) ||
    (Array.isArray(data.options[0]?.options[0]?.value)
      ? data.options[0]?.options[0]?.value[0]?.value === 'total'
      : false);

  if (isTrailingPeriod) {
    result.trailingPeriod = options[0].options[1].options[0].value;
    result.isMoreLess = options[0].options[2].value;
    result.inputValue = options[0].options[3].value;
    result.chainOption = options[0]?.options[4]?.value;
  }

  if (isSinceDate) {
    const date = options[0].options[1].options[1].value;
    if (date) {
      const y = date.substring(0, 4);
      const m = date.substring(5, 7);
      const d = date.substring(8, 10);
      const formattedDate = new Date(y, m - 1, d);
      result.startDate = formattedDate;
    }
    result.isMoreLess = options[0].options[2].value;
    result.inputValue = options[0].options[3].value;
    result.chainOption = options[0]?.options[4]?.value;
  }

  if (isTotal) {
    if (Array.isArray(data.options[0].options[2].value)) {
      if (
        Number.isNaN(
          +convertCreatedTxCount(data).options[0].options[2].value[0].value
        )
      ) {
        result.isMoreLess = options[0].options[2].value;
        result.inputValue = options[0].options[3].value;
        result.chainOption = options[0]?.options[4]?.value;
      } else {
        result.isMoreLess = options[0].options[1].value;
        result.inputValue = options[0].options[2].value;
        result.chainOption = options[0]?.options[3]?.value;
      }
    }
  }

  return result;
};
