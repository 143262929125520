import { supportedChains } from '../components/base/ChainLogo/chains';

const isObject = (a) => !!a && a.constructor === Object;

export const excludedBlockchainsForFlows = ['near', 'flow', 'ordinals'];

export const isWalletAddress = (name) =>
  !!name &&
  ((['0x', 'bc1', 'KT', 'tz'].some((prefix) => name.startsWith(prefix)) &&
    !name.includes('-')) ||
    name.endsWith('.near') ||
    name.endsWith('.tg') ||
    name.length === 64);

export const isWalletAddressPath = (name) =>
  isWalletAddress(name.replace(/\/profile\//, ''));

// Returns allowed chains in the order given in supportedChains
export const supportedBlockchains = (platforms) => {
  if (!platforms) {
    return [];
  }
  // Direct handling for object type platforms
  if (isObject(platforms)) {
    return Object.keys(supportedChains).filter((chain) =>
      Object.keys(platforms)
        .filter((key) => !!platforms[key])
        .includes(chain)
    );
  }
  // Convert to array if not already so that we can properly filter using includes
  const platformArray = Array.isArray(platforms) ? platforms : [platforms];
  return Object.keys(supportedChains).filter((chain) =>
    platformArray.includes(chain)
  );
};
