import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  NFTCreationAssets: [],
  newNFTCreationAsset: false,
};

export const contractsSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    setNFTCreationAssets(state, action) {
      state.NFTCreationAssets = action.payload;
    },
    deleteNFTCreationAsset(state, action) {
      state.NFTCreationAssets = state.NFTCreationAssets.filter(
        (elem) => elem?.id !== action.payload
      );
    },
    isNFTCreationAssetAdded(state, action) {
      state.newNFTCreationAsset = action.payload;
    },
  },
});

export const {
  setNFTCreationAssets,
  deleteNFTCreationAsset,
  isNFTCreationAssetAdded,
} = contractsSlice.actions;

export const selectNFTCreationAssets = (state) =>
  state.contractsReducer.NFTCreationAssets;
export const getIsNFTCreationAssetAdded = (state) =>
  state.contractsReducer.newNFTCreationAsset;

export default contractsSlice.reducer;
