import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { createApi } from '@reduxjs/toolkit/query/react';
import { localeStorageCache } from '../../utils/localeStorage';

const BASE_API = 'api/web-sdk';

export const webSDKApi = createApi({
  reducerPath: 'webSDKApi',
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers) => {
      const authKey = localeStorageCache.getAuth0Key();
      const authData = localeStorageCache.get(authKey);

      if (authData?.body?.id_token) {
        headers.set('Authorization', `Bearer ${authData.body.id_token}`);
      }
      return headers;
    },
    baseUrl: import.meta.env.VITE_APP_SERVER_DOMAIN,
  }),
  tagTypes: ['WebSDK'],
  endpoints: (build) => ({
    getWebSDK: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg?.search ? `?search=${encodeURIComponent(arg.search)}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 10,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKDataForTopBlocks: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/top-block/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKStats: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/stats/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKStatsFiltered: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/stats-filtered/?${arg.day ? `period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${
          arg?.urlFilter
            ? `&url_filter=${encodeURIComponent(arg.urlFilter)}`
            : ''
        }`,
        method: 'get',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKForUsersTab: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/visitor-tab/?${arg.day ? `period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKDataForChart: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/daily-graph/`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKById: build.query({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    createWebSDK: build.mutation({
      query: (formData) => ({
        url: `${BASE_API}/`,
        method: 'POST',
        body: formData,
      }),
      providesTags: () => ['WebSDK'],
    }),
    deleteWebSDK: build.mutation({
      query: (id) => ({
        url: `${BASE_API}/${id}/`,
        method: 'DELETE',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKForWalletTab: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/wallet-tab/?${arg.day ? `period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKWalletTabInvestmentPortfolio: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/wallet-tab-investment-portfolio/?desc=${arg.desc}${
          arg.day ? `&period=${arg.day}` : ''
        }${arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''}${
          arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''
        }${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}${
          arg.limit ? `&limit=${arg.limit}` : ''
        }${arg.asset_type ? `&asset_type=${arg.asset_type}` : ''}${
          arg.page ? `&page=${arg.page}` : ''
        }${arg.chain ? `&blockchain=${arg.chain}` : ''}${
          arg.order ? `&order=${arg.order}` : ''
        }`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKIdentifiedProfilesReach: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/identified-profiles-reach/?${arg.day ? `period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),

    getWebSDKIdentifiedProfilesList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/identified-profiles-list/?limit=${arg.limit}${
          arg.orderBy ? `&order_by=${arg.orderBy}` : ''
        }${arg.order ? `&order=${arg.order}` : ''}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.day ? `&period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),

    getWebSDKIdentifiedProfilesCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/identified-profiles-csv/?limit=${arg.limit}${
          arg.orderBy ? `&order_by=${arg.orderBy}` : ''
        }${arg.order ? `&order=${arg.order}` : ''}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.day ? `&period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
    }),
    getWebSDKWallets: build.query({
      query: (arg) => {
        let encodedSearch = '';
        if (arg?.search) {
          encodedSearch = encodeURIComponent(arg.search);
        }
        return {
          url: `${BASE_API}/segment-options/wallets/?limit=50${
            encodedSearch ? `&search=${encodedSearch}` : ''
          }`,
          method: 'GET',
        };
      },
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKSources: build.query({
      query: (arg) => {
        let encodedSearch = '';
        if (arg?.search) {
          encodedSearch = encodeURIComponent(arg.search);
        }
        return {
          url: `${BASE_API}/segment-options/sources/?limit=50${
            encodedSearch ? `&search=${encodedSearch}` : ''
          }`,
          method: 'GET',
        };
      },
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKBlockchains: build.query({
      query: (arg) => {
        let encodedSearch = '';
        if (arg?.search) {
          encodedSearch = encodeURIComponent(arg.search);
        }
        return {
          url: `${BASE_API}/segment-options/blockchains/?limit=50${
            encodedSearch ? `&search=${encodedSearch}` : ''
          }`,
          method: 'GET',
        };
      },
      providesTags: () => ['WebSDK'],
    }),

    getWebSDKAttributionList: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/attribution-tab/?limit=${arg.limit}${
          arg.orderBy ? `&order_by=${arg.orderBy}` : ''
        }${arg.order ? `&order=${arg.order}` : ''}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.day ? `&period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}${
          arg?.metric ? `&metric=${arg.metric}` : ''
        }${
          arg.conversion_event
            ? `&conversion_event=${arg.conversion_event}`
            : ''
        }${
          arg?.custom_conversion_event
            ? `&custom_conversion_event=${encodeURIComponent(arg.custom_conversion_event)}`
            : ''
        }${
          arg?.conversion_window
            ? `&conversion_window=${arg.conversion_window}`
            : ''
        }${
          arg?.new_visitors_only
            ? `&new_visitors_only=${arg.new_visitors_only}`
            : ''
        }${
          arg?.tagged_conversion_event
            ? `&tagged_conversion_event=${arg.tagged_conversion_event}`
            : ''
        }`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKAttributionListCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/attribution-tab-csv/?limit=${arg.limit}${
          arg.orderBy ? `&order_by=${arg.orderBy}` : ''
        }${arg.order ? `&order=${arg.order}` : ''}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.day ? `&period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}${
          arg?.metric ? `&metric=${arg.metric}` : ''
        }${
          arg.conversion_event
            ? `&conversion_event=${arg.conversion_event}`
            : ''
        }${
          arg?.custom_conversion_event
            ? `&custom_conversion_event=${arg.custom_conversion_event}`
            : ''
        }${
          arg?.conversion_window
            ? `&conversion_window=${arg.conversion_window}`
            : ''
        }${
          arg?.new_visitors_only
            ? `&new_visitors_only=${arg.new_visitors_only}`
            : ''
        }${
          arg?.tagged_conversion_event
            ? `&tagged_conversion_event=${arg.tagged_conversion_event}`
            : ''
        }`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKECommerceStats: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/e-commerce-stats/?${
          arg.day ? `&period=${arg.day}` : ''
        }${arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''}${
          arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''
        }${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKECommercePages: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/e-commerce-pages/?limit=${arg.limit}${
          arg.orderBy ? `&order_by=${arg.orderBy}` : ''
        }${arg.order ? `&order=${arg.order}` : ''}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.day ? `&period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebSDKECommercePagesCSV: build.mutation({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/e-commerce-pages-csv/?limit=${arg.limit}${
          arg.orderBy ? `&order_by=${arg.orderBy}` : ''
        }${arg.order ? `&order=${arg.order}` : ''}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.day ? `&period=${arg.day}` : ''}${
          arg?.isLoggedIn ? `&logged_in=${arg?.isLoggedIn}` : ''
        }${arg?.walletFilter ? `&wallet_filter=${arg.walletFilter}` : ''}${
          arg?.countries
            ? arg.countries.length !== 0
              ? `&selected_countries=${arg.countries}`
              : ''
            : ''
        }${arg?.urlFilter ? `&url_filter=${arg.urlFilter}` : ''}`,
        method: 'GET',
      }),
      providesTags: () => ['WebSDK'],
    }),
    getWebsiteCampaigns: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/web-campaigns/?limit=${arg.limit}${
          arg.orderBy ? `&order_by=${arg.orderBy}` : ''
        }${arg.order ? `&order=${arg.order}` : ''}${
          arg.offset ? `&offset=${arg.offset}` : ''
        }${arg.status ? `&status=${arg.status}` : ''}${
          arg.search ? `&search=${encodeURIComponent(arg.search)}` : ''
        }`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
    getTaggedEvents: build.query({
      query: (arg) => ({
        url: `${BASE_API}/${arg.id}/tagged-events/?period=${arg.period}`,
        method: 'GET',
      }),
      keepUnusedDataFor: 1,
      providesTags: () => ['WebSDK'],
    }),
  }),
});
