import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { profilesApi } from '../../../api/profiles';
import { isWalletAddress } from '../../../utils/supportedBlockchains';
import ExchangesTable from './ExchangesTable';

const Exchanges = ({ address }) => {
  const { name } = useParams();
  const [page, setPage] = useState(1);
  const [desc, setDesc] = useState(true);
  const [orderBy, setOrder] = useState('txn_count');
  const [limit, setLimit] = useState(25);

  const {
    data: exchanges,
    isLoading: isExchnagesLoading,
    isFetching: isExchnagesFetching,
  } = profilesApi.useGetWalletExchangeDataQuery(
    {
      address: isWalletAddress(name)
        ? `?address=${name}`
        : `?alid=${name}&address=${address}`,
      limit,
      order: desc ? 'desc' : 'asc',
      orderBy,
      page,
    },
    { skip: !isWalletAddress(name) && !address }
  );

  if (true) {
    return (
      <ExchangesTable
        exchanges={exchanges}
        isLoading={isExchnagesLoading || isExchnagesFetching}
        limit={limit}
        setLimit={setLimit}
        page={page}
        desc={desc}
        setPage={setPage}
        setDesc={setDesc}
        order={orderBy}
        setOrder={setOrder}
      />
    );
  }
};

export default Exchanges;
