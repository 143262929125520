import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  webSDKAssets: [],
  filterContents: null,
  filterParam: null,
  visitors: null,
  attributionParams: {
    metric: 'referrer-grouped',
    conversion_event: null,
    conversion_window: 'anytime',
    new_visitors_only: false,
    custom_conversion_event: null,
  },
};

export const webSDKSlice = createSlice({
  name: 'webSDK',
  initialState,
  reducers: {
    setWebSDKAssets(state, action) {
      state.webSDKAssets = action.payload;
    },
    deleteWebSDKAsset(state, action) {
      state.webSDKAssets = state.webSDKAssets.filter(
        (elem) => elem?.id !== action.payload
      );
    },
    setWebSDKFilterContents(state, action) {
      state.filterContents = action.payload;
    },
    setWebSDKFilterParam(state, action) {
      state.filterParam = action.payload;
    },
    setWebSDKVisitors(state, action) {
      state.visitors = action.payload;
    },
    setWebSDKAttributionParam: (state, action) => {
      state.attributionParams = {
        ...state.attributionParams,
        ...action.payload,
      };
    },
  },
});

export const {
  setWebSDKFilterContents,
  setWebSDKFilterParam,
  setWebSDKVisitors,
  setWebSDKAttributionParam,
} = webSDKSlice.actions;

export const selectWebSDKFilterContents = (state) =>
  state.webSDKReducer.filterContents;
export const selectWebSDKFilterParam = (state) =>
  state.webSDKReducer.filterParam;
export const selectWebSDKVisitors = (state) => state.webSDKReducer.visitors;
export const selectWebSDKAttributionParam = (state) =>
  state.webSDKReducer.attributionParams;

export default webSDKSlice.reducer;
