export const parseDataToFormData = (
  file,
  data,
  customListId,
  uniqueField = 'address'
) => {
  if (typeof file === 'object') {
    const formData = new FormData();
    formData.append('file', file);
    if (customListId) {
      formData.append(
        'body',
        JSON.stringify({
          data,
          pivot: uniqueField,
          custom_list_id: customListId,
        })
      );
    } else {
      formData.append(
        'body',
        JSON.stringify({
          data,
          pivot: uniqueField,
        })
      );
    }
    return formData;
  }
  return null;
};

export const csvDataTypesByAttribute = {
  'Discord handle': 'String',
  'Twitter handle': 'String',
  Email: 'String',
  Telegram: 'String',
  'Third party User ID': 'String',
  'First name': 'String',
  'Last name': 'String',
  Phone: 'String',
  Language: 'String',
  'Wallet address': 'String',
  'AppsFlyer ID': 'String',
  'Email opt out': 'Boolean',
  'Twitter opt out': 'Boolean',
  'SMS opt out': 'Boolean',
  'Telegram opt out': 'Boolean',
  'Discord opt out': 'Boolean',
  'Wallet opt out': 'Boolean',
};
