import { ConnectWalletButton } from '@absolutelabs/react-component-library';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFont from '../../../../../components/hooks/useFont';
import {
  selectButtonColor,
  selectButtonMinWidth,
  selectButtonRadius,
  selectButtonTextColor,
  selectButtonWeight,
  selectCurrPage,
  selectFieldSettings,
  selectFormTextColor,
} from '../../../../../store/reducers/forms';

const ConnectWallet = ({ ...props }) => {
  const currPage = useSelector(selectCurrPage);
  const fieldSettings = useSelector(selectFieldSettings);
  const globalTextColor = useSelector(selectFormTextColor);
  const globalButtonColor = useSelector(selectButtonColor);
  const globalButtonTextColor = useSelector(selectButtonTextColor);
  const globalButtonRadius = useSelector(selectButtonRadius);
  const globalButtonMinWidth = useSelector(selectButtonMinWidth);
  const globalButtonWeight = useSelector(selectButtonWeight);
  const [fieldConfig, setFieldConfig] = useState({});
  const { font } = useFont('button');

  useEffect(() => {
    const currPageSettings = fieldSettings.find((o) => o.pageName === currPage);
    const config = currPageSettings?.fields?.find(
      (x) => x.field_id === props?.field?.id
    );
    if (config) {
      setFieldConfig(config);
    }
  }, [fieldSettings, currPage, props?.field?.id]);

  return (
    <ConnectWalletButton
      text={fieldConfig.text || ''}
      subLabel={fieldConfig.subLabel || ''}
      style={fieldConfig.buttonStyle || 'filled'}
      align={fieldConfig.align || 'center'}
      fullWidth={fieldConfig.fullWidth || false}
      requiresSignature={fieldConfig.requiresSignature || false}
      signatureMessage={
        fieldConfig.signatureMessage ||
        'Please sign the message to confirm your identity'
      }
      required={fieldConfig.required || false}
      textColor={globalTextColor || '#000000'}
      buttonColor={globalButtonColor || '#109cf1'}
      buttonTextColor={globalButtonTextColor || '#ffffff'}
      buttonDisconnectColor={fieldConfig.disconnectColor || ''}
      buttonDisconnectTextColor={fieldConfig.disconnectTextColor || ''}
      editMode={false}
      font={font}
      radius={globalButtonRadius}
      minWidth={globalButtonMinWidth}
      fontWeight={globalButtonWeight}
    />
  );
};

export default ConnectWallet;
